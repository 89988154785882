import {
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiMuiSelectProps,
} from '@mui/material';
import cn from 'classnames';

import './Select.scss';

export type SelectItem = {
    value: string | number;
    displayName: string;
}

export interface SelectProps extends Omit<MuiMuiSelectProps, 'onChange'>{
    items: SelectItem[];
    onChange: (value: string) => void;
}

export function Select({
    native,
    items,
    className,
    onChange,
    ...restProps
}: SelectProps) {
    return (
        <MuiSelect
            native
            onChange={event => {
                const value = event?.target.value as string;
                onChange(value);
            }}
            className={cn('mmc-select-control', className)}
            {...restProps}
        >
            {native
                ? items.map((item, index) => <option key={index} value={item.value}>{item.displayName}</option>)
                : items.map((item, index) => <MenuItem key={index} value={item.value}>{item.displayName}</MenuItem>)}
        </MuiSelect>
    );
}
