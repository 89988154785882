import { forward, sample } from 'effector';

import { EmployeeRoleType } from 'models/Employee';
import { InfiniteScrollEmployees } from 'models/Employee/PagedEmployees';
import { $params } from 'store/params';

import {
    $employee,
    $pagedEmployees,
    $employeesCurrentPage,
    $allEmployees,
    $currentFetchedEmployees,
    $currentRole,
    $infiniteScrollPhysicians,
    $infiniteScrollAdvancedProviders,
    $employeeId,
} from './store';
import {
    wrappedFetchAllEmployees,
    wrappedFetchEmployee,
    wrappedFetchPagedEmployees,
    wrappedCreateEmployee,
    wrappedUpdateEmployee,
} from './effects';
import {
    getAllEmployees,
    getEmployee,
    getPagedEmployees,
    resetCurrentFetchedEmployees,
    resetCurrentRole,
    resetEmployee,
    resetInfiniteScrollAdvancedProviders,
    resetInfiniteScrollPhysicians,
    resetPagedEmployees,
    setEmployeeId,
} from './events';

sample({
    clock: getAllEmployees,
    source: $params,
    filter: params => !!params.siteId && !!params.locale,
    target: wrappedFetchAllEmployees,
});

forward({
    from: wrappedFetchAllEmployees.doneData,
    to: $allEmployees,
});

sample({
    clock: getPagedEmployees,
    filter: params => !!params.siteId && !!params.locale,
    target: wrappedFetchPagedEmployees,
});

forward({
    from: wrappedFetchPagedEmployees.doneData,
    to: $currentFetchedEmployees,
});

forward({
    from: getPagedEmployees.map(settings => settings.listSettings.roleType),
    to: $currentRole,
});

sample({
    clock: getPagedEmployees,
    filter: ({ siteId, listSettings }) => listSettings.roleType === null && !!siteId,
    fn: ({ listSettings }) => listSettings.pageNumber,
    target: $employeesCurrentPage,
});

sample({
    clock: $currentFetchedEmployees,
    source: {
        currentRole: $currentRole,
        currentFetchedEmployees: $currentFetchedEmployees,
    },
    filter: ({ currentRole }) => currentRole === null,
    fn: (_, currentFetchedEmployees) => currentFetchedEmployees,
    target: $pagedEmployees,
});

sample({
    clock: $currentFetchedEmployees,
    source: {
        infiniteScrollPhysicians: $infiniteScrollPhysicians,
        currentRole: $currentRole,
    },
    filter: ({ currentRole }) => currentRole === EmployeeRoleType.Senior,
    fn: ({ infiniteScrollPhysicians }, currentFetchedEmployees): InfiniteScrollEmployees => {
        if (infiniteScrollPhysicians.lastFetched === currentFetchedEmployees.currentPage) {
            return infiniteScrollPhysicians;
        } else {
            return {
                ...infiniteScrollPhysicians,
                items: [...infiniteScrollPhysicians.items, currentFetchedEmployees.items],
                lastFetched: currentFetchedEmployees.currentPage,
                totalPages: currentFetchedEmployees.totalPages,
                pageSize: currentFetchedEmployees.pageSize,
                hasNextPage: currentFetchedEmployees.hasNextPage,
            };
        }
    },
    target: $infiniteScrollPhysicians,
});

sample({
    clock: $currentFetchedEmployees,
    source: {
        infiniteScrollAdvancedProviders: $infiniteScrollAdvancedProviders,
        currentRole: $currentRole,
    },
    filter: ({ currentRole }) => currentRole === EmployeeRoleType.Junior,
    fn: ({ infiniteScrollAdvancedProviders }, currentFetchedEmployees): InfiniteScrollEmployees => {
        if (infiniteScrollAdvancedProviders.lastFetched === currentFetchedEmployees.currentPage) {
            return infiniteScrollAdvancedProviders;
        } else {
            return {
                ...infiniteScrollAdvancedProviders,
                items: [...infiniteScrollAdvancedProviders.items, currentFetchedEmployees.items],
                lastFetched: currentFetchedEmployees.currentPage,
                totalPages: currentFetchedEmployees.totalPages,
                pageSize: currentFetchedEmployees.pageSize,
                hasNextPage: currentFetchedEmployees.hasNextPage,
            };
        }
    },
    target: $infiniteScrollAdvancedProviders,
});

forward({
    from: getEmployee,
    to: wrappedFetchEmployee,
});

forward({
    from: wrappedFetchEmployee.doneData,
    to: $employee,
});

forward({
    from: setEmployeeId,
    to: $employeeId,
});

$employee.reset(resetEmployee);
$pagedEmployees.reset(resetPagedEmployees);
$currentFetchedEmployees.reset(resetCurrentFetchedEmployees);
$currentRole.reset(resetCurrentRole);

$infiniteScrollPhysicians.reset(resetInfiniteScrollPhysicians);
$infiniteScrollAdvancedProviders.reset(resetInfiniteScrollAdvancedProviders);

forward({
    from: [$params, wrappedCreateEmployee, wrappedUpdateEmployee],
    to: [resetInfiniteScrollPhysicians, resetInfiniteScrollAdvancedProviders],
});

sample({
    clock: [wrappedCreateEmployee.doneData, wrappedUpdateEmployee.doneData],
    source: {
        params: $params,
        employeesCurrentPage: $employeesCurrentPage,
    },
    fn: ({ params, employeesCurrentPage }) => ({
        ...params,
        listSettings: { pageNumber: employeesCurrentPage, pageSize: 10, roleType: null },
    }),
    target: getPagedEmployees,
});
