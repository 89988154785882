import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import cn from 'classnames';

import { TextFieldVariant } from './TextFieldVariant';

import './TextField.scss';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'onChange' | 'variant'> {
    isOutlined?: boolean;
    onChange: (text: string) => void;
}

export const TextField = ({
    isOutlined = true,
    onChange,
    className,
    ...props
}: TextFieldProps) => {
    return (
        <MuiTextField
            variant={isOutlined ? TextFieldVariant.Outlined : TextFieldVariant.Standard}
            onChange={e => {
                const value = e.target.value;

                onChange(value);
            }}
            className={cn('mmc-text-field', className)}
            InputProps={isOutlined ? {
                classes: {
                    root: 'mmc-text-field-input',
                    notchedOutline: 'mmc-text-field-input--notched',
                },
            } : undefined}
            {...props}
        />
    );
};
