/* eslint-disable import/no-unresolved */
import { ElementType, ReactNode, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, HashNavigation } from 'swiper';

import { SlideType } from 'models/Carousel';
import { Context } from 'features/EditPage/context';
import { Employee } from 'models/Employee';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './Carousel.scss';

export interface CarouselProps {
    slides: (SlideType | Employee | string)[];
    slide: ElementType;
    modalSlide?: ElementType;
    slidesPerView?: number | 'auto';
    initialSlide?: number | null;
    mobileClassName?: string;
    onClick?: (index: number) => void;
}

SwiperCore.use([Pagination, Navigation, HashNavigation]);

export const Carousel = ({
    slides,
    slide: Slide,
    slidesPerView,
    initialSlide = 0,
    onClick,
}: CarouselProps) => {
    return (
        <SlideWrapper
            slidesPerView={slidesPerView}
            initialSlide={initialSlide ?? undefined}
        >
            <>
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <Slide
                            slides={slides}
                            slide={slide}
                            index={index}
                            onClick={() => onClick && onClick(index)}
                        />
                    </SwiperSlide>
                ))}
            </>
        </SlideWrapper>
    );
};

const pagination = { clickable: true };

export interface SlideWrapperProps {
    children: ReactNode
    slidesPerView?: number | 'auto',
    initialSlide?: number | null;
}

export const SlideWrapper = ({
    slidesPerView = 1,
    initialSlide = 0,
    children,
}: SlideWrapperProps) => {
    const { isEditing } = useContext(Context);

    return (
        <Swiper
            noSwiping={!!isEditing}
            noSwipingClass={isEditing ? 'swiper-slide' : ''}
            spaceBetween={60}
            slidesPerView={slidesPerView}
            grabCursor={true}
            navigation={true}
            pagination={pagination}
            initialSlide={initialSlide ?? undefined}
        >
            {children}
        </Swiper>
    );
};
