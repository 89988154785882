/* eslint-disable max-len */
import { MenuItemType } from 'models/Menu';
import { ValuesDoctors } from 'models/Values';

const text = `<p>Our Board-Certifed Emergency Medicine Physicians are available 24-hours a day to provide the highest quality of emergency care.</p>
<h4>Our promise to you is that we aim to:</h4>
<ul>
<li>Provide you with highest quality of care</li>
<li>Treat you with utmost courtesy & respect</li>
<li>Keep you informed about your care plan</li>
<li>Explain steps in your care</li>
<li>Care for your comfort</li>
<li>Partner with you in your healing</li>
</ul>
`;


export const emptyValuesDoctorsInfo: ValuesDoctors = {
    id: 0,
    header: '',
    text: '',
    image: '',
    mobileImage: '',
    menuItem: {
        id: 0,
        title: '',
        link: '',
        type: MenuItemType.Block,
        order: null,
    },
};

export const initialValuesDoctorsInfo: ValuesDoctors = {
    id: 0,
    header: 'Meet Our Emergency Medicine Providers',
    image: 'imagejpeg_9b56cd88-a97c-4d57-abea-8f003d03010b',
    mobileImage: 'mobileImagejpeg_0b2a7232-2de6-4b75-9e08-69a5c4253717',
    text,
    menuItem: {
        id: 0,
        title: '',
        link: 'physicians',
        type: MenuItemType.Block,
        order: null,
    },
};

export const initialValuesProvidersInfo: ValuesDoctors = {
    id: 0,
    header: 'Meet Our Emergency Medicine Providers',
    image: 'imagejpeg_9b56cd88-a97c-4d57-abea-8f003d03010b',
    mobileImage: 'mobileImagejpeg_0b2a7232-2de6-4b75-9e08-69a5c4253717',
    text,
    menuItem: {
        id: 0,
        title: '',
        link: 'advanced-providers',
        type: MenuItemType.Block,
        order: null,
    },
};

export const initialValuesAllInfo: ValuesDoctors = {
    id: 0,
    header: 'Meet Our Emergency Medicine Providers',
    image: 'imagejpeg_9b56cd88-a97c-4d57-abea-8f003d03010b',
    mobileImage: 'mobileImagejpeg_0b2a7232-2de6-4b75-9e08-69a5c4253717',
    text,
    menuItem: {
        id: 0,
        title: '',
        link: 'all-doctors',
        type: MenuItemType.Block,
        order: null,
    },
};
