import { createEvent } from 'effector';

import { ValuesPublication } from 'models/Values';

export const getPublicationsSection = createEvent<{ siteId: string; locale: string; }>();

export const updatePublicationsSection = createEvent<ValuesPublication>();

export const hidePublicationsSection = createEvent<boolean>();

export const setPublicationsError = createEvent<string>();
