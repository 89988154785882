import { Step } from 'react-joyride';

export interface TourState {
    key?: string | null;
    run: boolean;
    stepIndex: number;
    continuous: boolean;
    loading: boolean;
    steps: Step[];
    tourActive: boolean;
    action: Actions;
}

export enum Actions {
    //Init = 'init',
    Start = 'start',
    Stop = 'stop',
    Reset = 'reset',
    Restart = 'restart',
    Prev = 'prev',
    Next = 'next',
    //Go = 'go',
    //INDEX = 'index',
    Close = 'close',
    Skip = 'skip',
    //Update = 'update',
    Redirect = 'redirect',
    RunCode = 'runCode',
    NextOrPrev = 'nextOrPrev',
}

export enum TourType {
    Language = 'language',
    Edit = 'edit',
    Links = 'links',
    Menu = 'menu',
}
