import { forwardRef, ReactNode } from 'react';
import cn from 'classnames';

import './Button.scss';

export interface ButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    submit?: boolean;
    children: string | ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
    onClick,
    className,
    submit = false,
    children,
    ...restProps
}, ref) => (
    <button
        {...restProps}
        ref={ref}
        onClick={onClick}
        className={cn(
            'button',
            className,
        )}
        type={submit ? 'submit' : 'button'}
    >
        {children}
    </button>
));
