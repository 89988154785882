import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { IHeaderService } from 'services/landing/header';
import { $headerService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { ValuesStart } from 'models/Values';
import { $params } from 'store/params';

import { getHeaderSection, setHeaderSectionError } from './events';
import { emptyValuesStart } from '../constants';

const hideHeaderSection = createEffect(async (params: {
    service: IHeaderService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideHeader(siteId, locale, isHidden);

    return result.match(
        data => {
            setHeaderSectionError('');

            return data;
        },
        error => {
            setHeaderSectionError(error);

            return emptyValuesStart;
        },
    );
});

export const wrappedHideHeader = attach({
    source: $headerService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideHeaderSection,
});

export const $isHeaderSectionHiding = wrappedHideHeader.pending;

const fetchHeaderSection = createEffect(async (params: {
    service: IHeaderService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getHeader(siteId, locale);

    return result.match(
        data => {
            setHeaderSectionError('');

            return data;
        },
        error => {
            setHeaderSectionError(error);

            return emptyValuesStart;
        },
    );
});

export const wrappedFetchHeaderSection = attach({
    source: $headerService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchHeaderSection,
});

export const $isHeaderSectionLoading = wrappedFetchHeaderSection.pending;

export const updateHeader = createEffect(async (params: {
    service: IHeaderService,
    siteId: string,
    locale: string,
    values: ValuesStart,
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editHeader(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getHeaderSection(params);

            return emptyValuesStart;
        },
    );
});

export const wrappedUpdateHeader = attach({
    source: $headerService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateHeader,
});

export const $isHeaderSectionUpdating = wrappedUpdateHeader.pending;
