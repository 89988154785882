import { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { ActionButtons } from 'features/Form';
import { MenuValues } from 'models/Menu';
import { Actions, TourType } from 'models/Tour';
import { useTourContext } from 'features/Tour/context/TourContext';

import { createYupSchema, fields } from './formValidationSchema';
import { Context } from './context';

import './EditPageWrapper.scss';

interface EditPageWrapperProps<Values>{
    Component: FunctionComponent<{ values: Values }>;
    defaultValues: Values;
    onSubmit: (values: Values) => void;
    initValidationSchema?: Yup.AnyObjectSchema;
}

export const EditPageWrapper = <
    Fields extends MenuValues,
    Field extends string,
>({
    Component,
    defaultValues,
    onSubmit,
    initValidationSchema,
 }: EditPageWrapperProps<Fields>) => {
    const [isEditing, setIsEditing] = useState(false);
    const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema | undefined>(initValidationSchema);

    const { tourState: { [TourType.Edit]: state }, dispatch } = useTourContext();

    useEffect(() => {
        if (
            state.tourActive && state.stepIndex === 1 &&
            state.action === Actions.RunCode &&
            Component.displayName === 'StartSection'
        ) {
            setIsEditing(true);
            setTimeout(() => dispatch({ type: Actions.Next, payload: { stepIndex: 2 }, tourType: TourType.Edit }));
        } else if (
            state.tourActive && state.stepIndex === 2 &&
            state.action === Actions.RunCode &&
            Component.displayName === 'StartSection'
        ) {
            setIsEditing(false);
            setTimeout(() => dispatch({ type: Actions.Prev, payload: { stepIndex: 1 }, tourType: TourType.Edit }));
        } else if (
            state.tourActive && state.stepIndex === 2 &&
            state.action === Actions.Prev &&
            Component.displayName === 'StartSection'
        ) {
            setIsEditing(false);
        } else if (
            state.tourActive && state.stepIndex === 3 &&
            state.action === Actions.NextOrPrev &&
            Component.displayName === 'LocalCareSection'
        ) {
            setIsEditing(true);
        } else if (
            state.tourActive && state.stepIndex === 4 &&
            state.action === Actions.NextOrPrev &&
            Component.displayName === 'LocationSection'
        ) {
            setIsEditing(true);
        }
    }, [Component, Component.displayName, dispatch, isEditing, state]);

    useEffect(() => {
        if (!initValidationSchema && isEditing) {
            setValidationSchema(createYupSchema(fields, defaultValues));
        }
    }, [defaultValues, initValidationSchema, isEditing]);

    return (
        <Context.Provider value={{ isEditing, setIsEditing }}>
            <div
                className={cn(
                    'form-container',
                    { 'form-container__edit-mode': isEditing },
                )}
            >
                <Formik<Fields>
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                    children={({ values, submitForm, resetForm, isValid }: FormikProps<Fields>) => {
                        return (
                            <Form>
                                <ActionButtons
                                    onCancel={() => {
                                        setIsEditing(false);
                                        resetForm();
                                    }}
                                    onSave={() => {
                                        void submitForm();
                                        isValid && setIsEditing(false);
                                    }}
                                    onEdit={() => setIsEditing(true)}
                                />
                                <div className='form-container__content'>
                                    <Component values={values} />
                                </div>
                                <div className='form-container__border'/>
                            </Form>
                        );
                    }}
                />
            </div>
        </Context.Provider>
    );
};
