import { IImageBlockApi } from 'api/landing/imageBlock';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesImageBlock } from 'models/Values';

export interface IImageBlockService {
    hideImageBlock: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesImageBlock>>;
    getImageBlock: (siteId: string, locale: string) => Promise<RequestResult<ValuesImageBlock>>;
    editImageBlock: (siteId: string, locale: string, imageBlockValues: ValuesImageBlock) => Promise<RequestResult<ValuesImageBlock>>;
}

export class ImageBlockService implements IImageBlockService {
    constructor (
        private readonly imageBlockApi: IImageBlockApi,
        private readonly logger: Logger,
    ) { }

    public async hideImageBlock(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesImageBlock>> {
        try {
            const response = await this.imageBlockApi.hideImageBlock(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide ImageBlock Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide ImageBlock');
        }
    }

    public async getImageBlock(siteId: string, locale: string): Promise<RequestResult<ValuesImageBlock>> {
        try {
            const response = await this.imageBlockApi.getImageBlock(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Map Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Map Section');
        }
    }

    public async editImageBlock(siteId: string, locale: string, imageBlockValues: ValuesImageBlock): Promise<RequestResult<ValuesImageBlock>> {
        try {
            const response = await this.imageBlockApi.editImageBlock(siteId, locale, imageBlockValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Map Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Map');
        }
    }
}
