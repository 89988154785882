import { ElementType } from 'react';

import '../FooterComponent.scss';

interface ListNodesProps<T extends { id?: number }> {
    list: T[];
    listElement: ElementType;
}

export const ListNodes = <T extends { id?: number }>({
    list,
    listElement: ListElement,
}: ListNodesProps<T>) => {
    return (
        <div className='footer__links'>
            {list.map((element, index) => (
                <ListElement
                    key={element.id}
                    listElement={element}
                    isLast={list.length - 1 === index}
                />
            ))}
        </div>
    );
};
