import { IExternalLinkApi } from 'api/externalLink';
import { RequestResult } from 'shared/utils/requestResult';
import { ExternalLink } from 'models/Module';

export interface IExternalLinkService {
    getExternalLinks: (siteId: string, locale: string) => Promise<RequestResult<ExternalLink[]>>;
    createExternalLink: (siteId: string, locale: string, externallink: ExternalLink) => Promise<RequestResult<ExternalLink>>;
    editExternalLinks: (siteId: string, locale: string, externallink: ExternalLink[]) => Promise<RequestResult<ExternalLink>>;
    deleteExternalLink: (id: number, siteId: string) => Promise<RequestResult<boolean>>;
}

export class ExternalLinkService implements IExternalLinkService {
    constructor (
        private readonly externalLinkApi: IExternalLinkApi,
        private readonly logger: Logger,
    ) { }

    public async getExternalLinks(
        siteId: string,
        locale: string,
    ): Promise<RequestResult<ExternalLink[]>> {
        try {
            const response = await this.externalLinkApi.getExternalLinks(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch external links');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch external links');
        }
    }

    public async createExternalLink(siteId: string, locale: string, externallink: ExternalLink): Promise<RequestResult<ExternalLink>> {
        try {
            const response = await this.externalLinkApi.createExternalLink(siteId, locale, externallink);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to add external link');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to add external link');
        }
    }

    public async editExternalLinks(siteId: string, locale: string, externalLink: ExternalLink[]): Promise<RequestResult<ExternalLink>> {
        try {
            const response = await this.externalLinkApi.editExternalLinks(siteId, locale, externalLink);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit external links');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit external links');
        }
    }

    public async deleteExternalLink(id: number, siteId: string): Promise<RequestResult<boolean>> {
        try {
            const response = await this.externalLinkApi.deleteExternalLink(id, siteId);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to delete externallink');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to delete externallink');
        }
    }
}
