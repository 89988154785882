import { useEffect, useMemo, memo } from 'react';
import { useStore } from 'effector-react';

import { Select } from 'shared/adminComponents';
import { $siteLanguagesStatus, getAllLanguages } from 'store/language';
import { $localeIdSwitcher, setLocaleIdSwitcher } from 'store/locale';

import './Locale.scss';

export const Locale = memo(() => {
    const localeIdSwitcher = useStore($localeIdSwitcher);
    const { siteLanguages } = useStore($siteLanguagesStatus);

    useEffect(() => {
        getAllLanguages();
    }, []);

    const itemsLocales = useMemo(() => {
        return siteLanguages.filter(element => element.order !== null)
            .map(element => ({ value: element.id, displayName: element.name }));
    }, [siteLanguages]);

    if (!itemsLocales.length) {
        return null;
    }

    return (
        <div className='locale'>
            <Select
                native
                items={itemsLocales}
                value={localeIdSwitcher}
                onChange={setLocaleIdSwitcher}
                className='locale__select'
            />
        </div>
    );
});
