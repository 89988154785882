import { createEvent } from 'effector';

import { TokenInfo } from 'models/Auth';

export const setIsAuthorized = createEvent<boolean>();

export const setSignInErrorMessage = createEvent<string>();

export const signIn = createEvent<{ login: string; password: string }>();

export const updateToken = createEvent<TokenInfo | null>();

export const signInWithOkta = createEvent<{ oktaAccessToken: string; oktaIdToken: string }>();
