import { createEvent } from 'effector';

import { ValuesTeam } from 'models/Values';

export const getTeamSection = createEvent<{ siteId: string; locale: string; }>();

export const updateTeamSection = createEvent<ValuesTeam>();

export const hideTeamSection = createEvent<boolean>();

export const setTeamError = createEvent<string>();
