import { useState, useEffect } from 'react';
import { debounce } from '@mui/material';

import { SizeBreakpoints } from './sizeBreakpoints';

export const useViewport = (customBreakPoint?: number) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleWindowResize = debounce(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }, 200);

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const isMobile = customBreakPoint
        ? width < customBreakPoint
        : width < SizeBreakpoints.mobileSize;

    return { width, height, isMobile };
};
