import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $errorTeamSection, $teamSection } from './store';
import { wrappedFetchTeamSection, wrappedUpdateTeam, wrappedHideTeam } from './effects';
import { getTeamSection, setTeamError, updateTeamSection, hideTeamSection } from './events';

sample({
    clock: hideTeamSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideTeam,
});

forward({
    from: wrappedHideTeam.doneData,
    to: $teamSection,
});

forward({
    from: getTeamSection,
    to: wrappedFetchTeamSection,
});

forward({
    from: wrappedFetchTeamSection.doneData,
    to: $teamSection,
});

sample({
    clock: updateTeamSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateTeam,
});

forward({
    from: wrappedUpdateTeam.doneData,
    to: $teamSection,
});

forward({
    from: setTeamError,
    to: $errorTeamSection,
});
