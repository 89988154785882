import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import cn from 'classnames';

import { $menuStatus, getMenu } from 'store/menu';
import { $params } from 'store/params';
import { MenuItemType } from 'models/Menu';
import { $siteLanguagesStatus } from 'store/language';
import { useToggle } from 'shared/utils/hooks/useToggle';
import { ContactUsButton } from 'features/ContactUs/ContactUsButton/ContactUsButton';

import './Menu.scss';

export const LeftMenu = memo(() => {
    const [isMenuOpened, toggleIsMenuOpened, setToggle] = useToggle(false);

    return (
        <div>
            <div className={cn('overlay', { visible: isMenuOpened })}></div>
            <div className= {cn('overflow', { visible: !isMenuOpened })}>
                <Menu
                    isLeft={true}
                    isMenuOpened={isMenuOpened}
                    toggleIsMenuOpened={toggleIsMenuOpened}
                    setToggle={setToggle}
                />
            </div>
            <div className='hamburger' onClick={toggleIsMenuOpened}>
                <div className={cn('hamburger__icon', { hamburger__icon_opened: isMenuOpened })}></div>
            </div>
        </div>
    );
});

export const TopMenu = memo(() => {
    return <Menu isLeft={false} />;
});

interface MenuProps {
    isLeft: boolean;
    isMenuOpened?: boolean;
    toggleIsMenuOpened?: () => void;
    setToggle?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Menu = memo(({ isLeft, isMenuOpened, toggleIsMenuOpened, setToggle }: MenuProps) => {
    const { menu, successMenu } = useStore($menuStatus);
    const params = useStore($params);
    const { siteLanguages } = useStore($siteLanguagesStatus);

    const currentLocale = siteLanguages.find(e => e.name === params.locale);

    useEffect(() => {
        if (!successMenu) {
            getMenu();
            setToggle?.(false);
        }
    }, [setToggle, successMenu]);

    return (
        <menu
            className={cn('menu', {
                menu_left: isLeft,
                menu_top: !isLeft,
                menu_opened: isLeft && isMenuOpened,
            })}
        >
            <ul className='menu__list'>
                {currentLocale?.isMaintenance && menu.map((item, index) => (
                    item.order !== null &&
                    <li key={index}>
                        {item.type === MenuItemType.Block || item.type === MenuItemType.Page
                            ? <Link
                                to={`/${params.siteId}/${params.locale}/${item.link}`}
                                onClick={toggleIsMenuOpened}
                            >
                                {item.title}
                            </Link>
                            : <a href={item.link} target='_blank' rel='noreferrer'>{item.title}</a>
                        }
                    </li>
                ))}
            </ul>
            {!isLeft && <ContactUsButton />}
        </menu>
    );
});
