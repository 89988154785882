import { HttpMethods } from 'api/constants';
import { ValuesTextBlock } from 'models/Values';

import { ITextBlockApi } from './interfaces';
import { TEXT_BLOCK_URL } from './constants';
import { getFilteredQuery, getNotFilteredQuery } from '../../utils';

export class TextBlockApi implements ITextBlockApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async hideTextBlock(siteId: string, locale: string, isHidden: boolean) {
        const queryList = {
            siteId,
            locale,
            isHidden,
        };

        const response = await this.fetch(`${TEXT_BLOCK_URL}/Hide${getNotFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response;
    }

    public async getTextBlock(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${TEXT_BLOCK_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async editTextBlock(siteId: string, locale: string, TextBlockValues: ValuesTextBlock) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${TEXT_BLOCK_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(TextBlockValues),
            },
        );

        return response;
    }
}
