import { createEffect, attach } from 'effector';

import { ILandingService } from 'services/landing';
import { $landingService } from 'store/services';

import { initialValues } from './constants';
import { setLandingError, setLandingSuccess } from './events';

const fetchLanding = createEffect(async (params: {
    service: ILandingService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getLanding(siteId, locale);

    return result.match(
        data => {
            setLandingSuccess(true);
            setLandingError('');

            return data;
        },
        error => {
            setLandingError(error);

            return initialValues;
        },
    );
});

export const wrappedFetchLanding = attach({
    source: $landingService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchLanding,
});

export const $isLandingLoading = wrappedFetchLanding.pending;
