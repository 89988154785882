import { RequestResult } from 'shared/utils/requestResult';
import { Contact } from 'models/Contact/Contact';
import { IContactApi } from 'api/contactus';

export interface IAdminContactService {
    contactUs: (siteId: string, locale: string, contact: Contact) => Promise<RequestResult<boolean>>;
}

export class AdminContactService implements IAdminContactService {
    constructor (
        private readonly contactApi: IContactApi,
        private readonly logger: Logger,
    ) { }

    public async contactUs(siteId: string, locale: string, contact: Contact): Promise<RequestResult<boolean>> {
        try {
            const response = await this.contactApi.contactUs(siteId, locale, contact);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to send e-mail');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to send e-mail');
        }
    }
}
