import { ElementType, useCallback } from 'react';
import { useFormikContext } from 'formik';

import { AddButton, DeleteButton } from 'features/Form';
import { SlideType } from 'models/Carousel';

import { getInitialListElement } from './utils';

import '../FooterComponent.scss';

interface EditFooterProps<_, Field extends string> {
    name: Field;
    listElement: ElementType;
}

export const EditFooter = <
    Fields extends { [key in Field]: SlideType[] },
    Field extends string,
>({
    name,
    listElement: ListElement,
}: EditFooterProps<Fields, Field>) => {
    const { values, setFieldValue } = useFormikContext<Fields>();

    const onAddButton = useCallback((index: number) => {
        const newValues = [ ...values[name] ];

        newValues[index].button = {
            id: 0,
            text: '',
            link: '',
        };

        setFieldValue(name, newValues);
    }, [name, setFieldValue, values]);

    const onDeleteButton = useCallback((index: number) => {
        const newValues = [ ...values[name] ];
        delete newValues[index].button;

        setFieldValue(name, newValues);
    }, [name, setFieldValue, values]);

    return (
        <div>
            {!values[name].length
                ?   <div className='list-nodes__buttons'>
                        <AddButton
                            name={name}
                            getInitialElement={getInitialListElement}
                        >
                            Add next item
                        </AddButton>
                    </div>
                :   values[name].map((listElement, index) => (
                        <div key={listElement.id}>
                            <div className='list-nodes__buttons'>
                                <DeleteButton
                                    name={name}
                                    elementKey={listElement.id}
                                    className='list-nodes__delete'
                                />
                                <AddButton
                                    name={name}
                                    index={index}
                                    getInitialElement={getInitialListElement}
                                >
                                    Add next item
                                </AddButton>
                            </div>
                            <ListElement
                                listElement={listElement}
                                index={index}
                                onAdd={() => onAddButton(index)}
                                onDelete={() => onDeleteButton(index)}
                            />
                        </div>
                    ))
            }
        </div>
    );
};
