import { Actions, TourState, TourType } from 'models/Tour';

import { EDIT_TOUR_STEPS, LANGUAGE_TOUR_STEPS, LINKS_TOUR_STEPS, MENU_TOUR_STEPS } from './steps';

const initialState = {
    key: new Date().toString(), // for re-render when restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    action: Actions.Start,
    tourActive: false,
};

export const INITIAL_STATE: Record<TourType, TourState> = {
    [TourType.Language]: {
        ...initialState,
        steps: LANGUAGE_TOUR_STEPS,
    },
    [TourType.Edit]: {
        ...initialState,
        steps: EDIT_TOUR_STEPS,
    },
    [TourType.Links]: {
        ...initialState,
        steps: LINKS_TOUR_STEPS,
    },
    [TourType.Menu]: {
        ...initialState,
        steps: MENU_TOUR_STEPS,
    },
};
