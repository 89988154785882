import { createStore, combine } from 'effector';

import { ValuesOther } from 'models/Values';

import { emptyValuesOther } from './constants';
import { $areOtherPagesLoading } from './effects';

export const $otherPages = createStore<ValuesOther[]>([]);

export const $other = createStore<ValuesOther>(emptyValuesOther);

export const $otherPagesGetStatus = combine({
    areOtherPagesLoading: $areOtherPagesLoading,
    otherPages: $otherPages,
});
