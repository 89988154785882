import { Employee, ShortRoleNamePosition } from 'models/Employee';

export const getFullName = (provider: Employee) => {
    if (!provider.firstName && !provider.lastName) return '';

    return `${provider.firstName} ${provider.lastName}`;
};

export const getFullNameWithRole = (provider: Employee) => {
    const providerFullName = getFullName(provider);

    const getNameWithShortRole = (rolePosition: number, shortPosition: string) => {
        return rolePosition === ShortRoleNamePosition.Before ? `${shortPosition} ${providerFullName}` : `${providerFullName}${shortPosition}`;
    }

    if (provider.shortRoleNamePosition && provider.shortRoleName) {
        return getNameWithShortRole(provider.shortRoleNamePosition, provider.shortRoleName);
    }

    if (provider.position?.shortNamePosition && provider.position.shortName) {
        return getNameWithShortRole(provider.position.shortNamePosition, provider.position.shortName);
    }

    return providerFullName;
};
