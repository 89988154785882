import { createEvent } from 'effector';

import { Language } from 'models/Language/Language';

export const saveLanguages = createEvent<{ languages: Language[] }>();

export const addLanguage = createEvent<{ localeId: string }>();

export const getAllLanguages = createEvent();

export const getSiteLanguages = createEvent<string>();
