import { createEvent } from 'effector';

import { Parameters } from 'models/Auth';
import { MenuItem } from 'models/Menu';

interface UpdateMenu extends Parameters {
    values: MenuItem[];
}

export const getMenu = createEvent();

export const updateMenu = createEvent<UpdateMenu>();

export const setMenuError = createEvent<string>();

export const setMenuSuccess = createEvent<boolean>();
