import { createStore, forward, sample } from 'effector';

import { EmployeeRoleType } from 'models/Employee';
import { $params } from 'store/params';

import {
    $physiciansInfo,
    $advancedProvidersInfo,
    $allDoctorsInfo,
    $currentDoctorsInfo,
    $currentDoctorsInfoRole,
    $currentFetchDoctorsInfo,
    $physiciansInfoSuccess,
    $advancedProvidersInfoSuccess,
    $allDoctorsInfoSuccess,
} from './store';
import { wrappedFetchDoctorsInfo, wrappedUpdateDoctorsInfo } from './effects';
import {
    getDoctorsInfo,
    setAdvancedProvidersInfoSuccess,
    setAllDoctorsInfoSuccess,
    setPhysiciansInfoSuccess,
    updateDoctorsInfo,
} from './events';

forward({
    from: getDoctorsInfo,
    to: wrappedFetchDoctorsInfo,
});

forward({
    from: wrappedFetchDoctorsInfo.doneData,
    to: $currentFetchDoctorsInfo,
});

forward({
    from: getDoctorsInfo.map(settings => settings.type),
    to: $currentDoctorsInfoRole,
});

sample({
    clock: wrappedFetchDoctorsInfo.doneData,
    source: $currentDoctorsInfo,
    filter: ({ type }) => type === EmployeeRoleType.All,
    target: $allDoctorsInfo,
});

sample({
    clock: wrappedFetchDoctorsInfo.doneData,
    source: $currentDoctorsInfo,
    filter: ({ type }) => type === EmployeeRoleType.Junior,
    target: $advancedProvidersInfo,
});

sample({
    clock: wrappedFetchDoctorsInfo.doneData,
    source: $currentDoctorsInfo,
    filter: ({ type }) => type === EmployeeRoleType.Senior,
    target: $physiciansInfo,
});

forward({
    from: updateDoctorsInfo,
    to: wrappedUpdateDoctorsInfo,
});

forward({
    from: wrappedUpdateDoctorsInfo.doneData,
    to: $currentFetchDoctorsInfo,
});

forward({
    from: updateDoctorsInfo.map(settings => settings.type),
    to: $currentDoctorsInfoRole,
});

sample({
    clock: wrappedUpdateDoctorsInfo.doneData,
    source: $currentDoctorsInfo,
    filter: ({ type }) => type === EmployeeRoleType.All,
    target: $allDoctorsInfo,
});

sample({
    clock: wrappedUpdateDoctorsInfo.doneData,
    source: $currentDoctorsInfo,
    filter: ({ type }) => type === EmployeeRoleType.Junior,
    target: $advancedProvidersInfo,
});

sample({
    clock: wrappedUpdateDoctorsInfo.doneData,
    source: $currentDoctorsInfo,
    filter: ({ type }) => type === EmployeeRoleType.Senior,
    target: $physiciansInfo,
});

forward({
    from: setPhysiciansInfoSuccess,
    to: $physiciansInfoSuccess,
});

forward({
    from: setAdvancedProvidersInfoSuccess,
    to: $advancedProvidersInfoSuccess,
});

forward({
    from: setAllDoctorsInfoSuccess,
    to: $allDoctorsInfoSuccess,
});

sample({
    clock: $params,
    source: createStore(false),
    target: [$physiciansInfoSuccess, $advancedProvidersInfoSuccess, $allDoctorsInfoSuccess],
});
