import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';

interface HideCheckboxComponentProps <_, Field extends string>{
    name: Field;
    value: boolean;
    label: string;
    onChange: (values: boolean) => void;
    chain?: (number | string)[];
    className?: string;
}

export const HideCheckboxComponent = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    value,
    label,
    onChange,
}: HideCheckboxComponentProps<Fields, Field>) => {
    const handleOnChange = () => {
        onChange(!value);
      };

    return (
        <EditQenericComponent
            layout={
                <></>
            }
            quiz={
                <FormControlLabel
                    control={
                        <Checkbox
                            id={name}
                            name={name}
                            checked={value}
                            onChange={handleOnChange}
                        />
                    }
                    label={label}
                />
            }
        />
    );
};
