import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
    palette: {
        background: {
            default: '#fdffff',
        },
        primary: {
            main: '#0277BD',
        },
        secondary: {
            main: '#0292da',
        },
    },
    typography: {
        fontFamily: 'Inter',
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
