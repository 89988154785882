import { memo } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './EditButton.scss';

interface EditButtonProps extends Omit<MuiButtonProps, 'className'> {
    className?: string;
}

export const EditButton = memo(({
    className,
    ...restProps
}: EditButtonProps) => {
    return (
        <IconButton
            {...restProps}
            aria-label='edit'
            size={ButtonSize.Small}
            className={cn('edit-button', className)}
        >
            <EditIcon />
        </IconButton>
    );
});
