import { createStore, combine } from 'effector';

import { Language } from 'models/Language/Language';

import { $areAllLanguagesLoading, $areSiteLanguagesLoading } from './effects';

export const $siteLanguages = createStore<Language[]>([]);

export const $languagesLookup = createStore<Language[]>([]);

export const $siteLanguagesSuccess = createStore<boolean>(false);

export const $siteLanguagesStatus = combine({
    siteLanguages: $siteLanguages,
    areSiteLanguagesLoading: $areSiteLanguagesLoading,
    siteLanguagesSuccess: $siteLanguagesSuccess,
});

export const $languagesLookupStatus = combine({
    languagesLookup : $languagesLookup ,
    areAllLanguagesLoading: $areAllLanguagesLoading,
});
