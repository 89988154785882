import { useMemo, memo } from 'react';
import { useStore } from 'effector-react';
import { Link } from 'react-router-dom';

import { Button } from 'shared/components/Button';
import { ReactComponent as GoBack } from 'shared/components/Icons/go-back.svg';
import { externalLinkRegex } from 'shared/constants/constants';
import { TextField as MuiTextField, Select } from 'features/Form';
import { ButtonKey, ButtonType } from 'models/Button';
import { MenuItemType } from 'models/Menu';
import { $menuStatus } from 'store/menu';
import { $locale } from 'store/locale';
import { $siteId } from 'store/params';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';

import './ButtonComponent.scss';

interface LinkButtonComponentProps {
    initialValue: ButtonType;
    name: string;
    chain?: (number | string)[];
    className?: string;
    arrowIcon?: boolean;
}

export const TextLinkButton = memo(({
    initialValue,
    name,
    chain = [],
    className,
    arrowIcon = false,
}: LinkButtonComponentProps) => {
    const { menu } = useStore($menuStatus);
    const siteId = useStore($siteId);
    const locale = useStore($locale);

    const items = useMemo(() => ([
        { value: '', displayName: '' },
        ...menu.filter(item => item.type === MenuItemType.Page)
            .filter(route => /[\d]+$/.test(route.link))   //filter support Pages
            .map(route => ({ value: route.link, displayName: `page ${route.title}` })),
        ...menu.filter(item => item.type === MenuItemType.Link)
            .map(route => ({ value: route.link, displayName: `link ${route.title}` })),
    ]), [menu]);

    const textChain = useMemo(() => [...chain, ButtonKey.Text], [chain]);
    const selectChain = useMemo(() => [...chain, ButtonKey.Link], [chain]);

    const button = useMemo(() => (
        <Button className='button_view'>
            {initialValue.text}
            {arrowIcon && <GoBack />}
        </Button>
    ), [initialValue.text, arrowIcon]);

    return (
        <EditQenericComponent
            layout={
                externalLinkRegex.test(initialValue.link)
                    ? <a href={initialValue.link} target='_blank' rel='noreferrer' className={className}>
                        {button}
                    </a>
                    : <Link to={`/${siteId}/${locale}/${initialValue.link}`} className={className}>
                        {button}
                    </Link>
            }
            quiz={
                <div className={`buttons ${className}`}>
                    <div className='buttons__title'>Button</div>
                    <MuiTextField
                        id={name}
                        name={name}
                        chain={textChain}
                        multiline
                        fullWidth
                        label='button label'
                    />
                    <Select
                        id={name}
                        name={name}
                        chain={selectChain}
                        fullWidth
                        label='target page/link'
                        items={items}
                    />
                </div>
            }
        />
    );
});
