import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $errorSolutionSection, $solutionSection } from './store';
import { wrappedFetchSolutionSection, wrappedUpdateSolution, wrappedHideSolution } from './effects';
import { getSolutionSection, setSolutionError, updateSolutionSection, hideSolutionSection } from './events';

sample({
    clock: hideSolutionSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideSolution,
});

forward({
    from: wrappedHideSolution.doneData,
    to: $solutionSection,
});

forward({
    from: getSolutionSection,
    to: wrappedFetchSolutionSection,
});

forward({
    from: wrappedFetchSolutionSection.doneData,
    to: $solutionSection,
});

sample({
    clock: updateSolutionSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateSolution,
});

forward({
    from: wrappedUpdateSolution.doneData,
    to: $solutionSection,
});

forward({
    from: setSolutionError,
    to: $errorSolutionSection,
});
