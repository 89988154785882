import { ReactNode } from 'react';
import {
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import cn from 'classnames';

import { Button, ButtonVariant } from '../Button';

import './Dialog.scss';

interface DialogProps {
    title: string;
    isOpen: boolean;
    content: ReactNode | string;
    className?: string;
    onDoneLabel?: string;
    onCancelLabel?: string;
    onProceed: () => void;
    onCancel: () => void;
}

export function Dialog({
    title, isOpen, content, className,
    onDoneLabel = 'Done', onCancelLabel = 'Cancel',
    onProceed, onCancel,
}: DialogProps) {
    return (
        <MuiDialog
            open={isOpen}
            onClose={onCancel}
            className={cn('mmc-dialog', className)}
        >
            <DialogTitle className='mmc-dialog__title'>
                {title}
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button 
                    variant={ButtonVariant.Outlined}
                    onClick={onCancel} className='mmc-dialog__cancel-button'
                >
                    {onCancelLabel}
                </Button>
                <Button
                    variant={ButtonVariant.Contained}
                    onClick={onProceed} className='mmc-dialog__proceed-button'
                >
                    {onDoneLabel}
                </Button>
            </DialogActions>
        </MuiDialog>
    );
}
