import { IMenuApi } from 'api/menu';
import { RequestResult } from 'shared/utils/requestResult';
import { MenuItem } from 'models/Menu';

export interface IMenuService {
    getMenu: (siteId: string, locale: string) => Promise<RequestResult<MenuItem[]>>;
    editMenu: (siteId: string, locale: string, menuValues: MenuItem[]) => Promise<RequestResult<MenuItem[]>>;
}

export class MenuService implements IMenuService {
    constructor (
        private readonly menuApi: IMenuApi,
        private readonly logger: Logger,
    ) { }

    public async getMenu(siteId: string, locale: string): Promise<RequestResult<MenuItem[]>> {
        try {
            const response = await this.menuApi.getMenu(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Menu');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Menu');
        }
    }

    public async editMenu(siteId: string, locale: string, menuValues: MenuItem[]): Promise<RequestResult<MenuItem[]>> {
        try {
            const response = await this.menuApi.editMenu(siteId, locale, menuValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Menu');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Menu');
        }
    }
}
