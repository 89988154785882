import { createEvent } from 'effector';

import { EmployeeListSettings } from 'models/Employee';

export const getPagedEmployees = createEvent<{ siteId: string, locale: string, listSettings: EmployeeListSettings }>();
export const resetPagedEmployees = createEvent(); //check
export const resetCurrentEmployees = createEvent();
export const resetParametersEmployees = createEvent();
export const resetCurrentFetchedEmployees = createEvent();
export const resetCurrentRole = createEvent();

export const resetInfiniteScrollPhysicians = createEvent();
export const resetInfiniteScrollAdvancedProviders = createEvent();

export const getEmployee = createEvent<number>();
export const resetEmployee = createEvent();

export const setEmployeeId = createEvent<number | null>();

export const getAllEmployees = createEvent();
