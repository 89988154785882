import { HttpMethods } from 'api/constants';
import { ValuesDoctors } from 'models/Values';
import { EmployeeRoleType } from 'models/Employee';

import { IDoctorsInfoApi } from './interfaces';
import { DOCTORS_INFO_URL } from './constants';
import { getFilteredQuery } from '../utils';

export class DoctorsInfoApi implements IDoctorsInfoApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getDoctorsInfo(siteId: string, locale: string, type: EmployeeRoleType | null) {
        const queryList = {
            siteId,
            locale,
            type,
        };

        const response = await this.fetch(`${DOCTORS_INFO_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async editDoctorsInfo(siteId: string, locale: string, doctorsInfoValues: ValuesDoctors, type: EmployeeRoleType | null) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${DOCTORS_INFO_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...doctorsInfoValues, type }),
            },
        );

        return response;
    }
}
