import Resizer from 'react-image-file-resizer';

export const resizeFile = (file: File, minWidth: number, minHeight: number, type = 'JPEG'): Promise<File> =>
    new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            minWidth * 1.3,
            minHeight * 1.3,
            type,
            100,
            0,
            uri => {
                resolve(uri as File);
            },
            'file',
            minWidth * 1.05,
            minHeight * 1.05,
        );
    },
);
