import { memo } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './WatchButton.scss';

interface WatchButtonProps extends Omit<MuiButtonProps, 'className'> {
    className?: string;
}

export const WatchButton = memo(({
    className,
    ...restProps
}: WatchButtonProps) => {
    return (
        <IconButton
            {...restProps}
            aria-label='watch'
            size={ButtonSize.Small}
            className={cn('watch-button', className)}
        >
            <VisibilityOutlinedIcon  />
        </IconButton>
    );
});
