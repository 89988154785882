
export enum AuthType {
    Default = 'default',
    Okta = 'okta',
}

export interface TokenInfo {
    Token: string;
    ExpirationUtc: string;
    IsExpired?: boolean;
    SignInType: string;
}
