import { ILanguageApi } from 'api/language';
import { RequestResult } from 'shared/utils/requestResult';
import { Language } from 'models/Language';

export interface ILanguageService {
    getAllLanguages: () => Promise<RequestResult<Language[]>>;
    getSiteLanguages: (siteId: string) => Promise<RequestResult<Language[]>>;
    createLanguage: (siteId: string, localeId: string) => Promise<RequestResult<Language>>;
    editLanguages: (siteId: number, languages: Language[]) => Promise<RequestResult<Language>>;
}

export class LanguageService implements ILanguageService {
    constructor (
        private readonly languageApi: ILanguageApi,
        private readonly logger: Logger,
    ) { }

    public async getAllLanguages(): Promise<RequestResult<Language[]>> {
        try {
            const response = await this.languageApi.getAllLanguages();
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch languages');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch languages');
        }
    }

    public async getSiteLanguages(
        siteId: string,
    ): Promise<RequestResult<Language[]>> {
        try {
            const response = await this.languageApi.getSiteLanguages(siteId);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch languages');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch languages');
        }
    }

    public async createLanguage(siteId: string, localeId: string): Promise<RequestResult<Language>> {
        try {
            const response = await this.languageApi.createLanguage(siteId, localeId);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to add language');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to add language');
        }
    }

    public async editLanguages(siteId: number, languages: Language[]): Promise<RequestResult<Language>> {
        try {
            const response = await this.languageApi.editLanguages(siteId, languages);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit language');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit language');
        }
    }
}
