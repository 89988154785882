import { ITextBlockApi } from 'api/landing/textBlock';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesTextBlock } from 'models/Values';

export interface ITextBlockService {
    hideTextBlock: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesTextBlock>>;
    getTextBlock: (siteId: string, locale: string) => Promise<RequestResult<ValuesTextBlock>>;
    editTextBlock: (siteId: string, locale: string, textBlockValues: ValuesTextBlock) => Promise<RequestResult<ValuesTextBlock>>;
}

export class TextBlockService implements ITextBlockService {
    constructor (
        private readonly textBlockApi: ITextBlockApi,
        private readonly logger: Logger,
    ) { }

    public async hideTextBlock(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesTextBlock>> {
        try {
            const response = await this.textBlockApi.hideTextBlock(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide TextBlock Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide TextBlock');
        }
    }

    public async getTextBlock(siteId: string, locale: string): Promise<RequestResult<ValuesTextBlock>> {
        try {
            const response = await this.textBlockApi.getTextBlock(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get TextBlock Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get TextBlock Section');
        }
    }

    public async editTextBlock(siteId: string, locale: string, TextBlockValues: ValuesTextBlock): Promise<RequestResult<ValuesTextBlock>> {
        try {
            const response = await this.textBlockApi.editTextBlock(siteId, locale, TextBlockValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit TextBlock Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit TextBlock Section');
        }
    }
}
