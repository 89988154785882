import { HttpMethods } from 'api/constants';
import { ValuesStart } from 'models/Values';

import { IHeaderApi } from './interfaces';
import { HEADER_URL } from './constants';
import { getFilteredQuery, getNotFilteredQuery } from '../../utils';

export class HeaderApi implements IHeaderApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async hideHeader(siteId: string, locale: string, isHidden: boolean) {
        const queryList = {
            siteId,
            locale,
            isHidden,
        };

        const response = await this.fetch(`${HEADER_URL}/Hide${getNotFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response;
    }

    public async getHeader(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${HEADER_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async editHeader(siteId: string, locale: string, headerValues: ValuesStart) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${HEADER_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(headerValues),
            },
        );

        return response;
    }
}
