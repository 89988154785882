import { useMemo, memo } from 'react';
import { useStore } from 'effector-react';
import { Link } from 'react-router-dom';

import { externalLinkRegex } from 'shared/constants/constants';
import { TextField as MuiTextField, Select } from 'features/Form';
import { ButtonKey, ButtonType } from 'models/Button';
import { MenuItemType } from 'models/Menu';
import { $menuStatus } from 'store/menu';
import { $locale } from 'store/locale';
import { $siteId } from 'store/params';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';

interface LinkComponentProps {
    initialValue: ButtonType;
    name: string;
    chain?: (number | string)[];
    className?: string;
}

export const LinkComponent = memo(({
    initialValue,
    name,
    chain = [],
    className,
}: LinkComponentProps) => {
    const { menu } = useStore($menuStatus);
    const siteId = useStore($siteId);
    const locale = useStore($locale);

    const items = useMemo(() => ([
        { value: '', displayName: '' },
        ...menu.filter(item => item.type === MenuItemType.Page)
            .filter(route => /[\d]+$/.test(route.link))   //filter support Pages
            .map(route => ({ value: route.link, displayName: `page ${route.title}` })),
        ...menu.filter(item => item.type === MenuItemType.Link)
            .map(route => ({ value: route.link, displayName: `link ${route.title}` })),
    ]), [menu]);

    const textChain = useMemo(() => [...chain, ButtonKey.Text], [chain]);
    const selectChain = useMemo(() => [...chain, ButtonKey.Link], [chain]);

    return (
        <EditQenericComponent
            layout={
                externalLinkRegex.test(initialValue.link)
                    ?   <a href={initialValue.link} target='_blank' rel='noreferrer' className={className}>
                            {initialValue.text}
                        </a>
                    :   <Link to={`/${siteId}/${locale}/${initialValue.link}`} className={className}>
                            {initialValue.text}
                        </Link>
            }
            quiz={
                <div className={`Links ${className}`}>
                    <div className='Links__title'>Link</div>
                    <MuiTextField
                        id={name}
                        name={name}
                        chain={textChain}
                        multiline
                        fullWidth
                        label='link label'
                    />
                    <Select
                        id={name}
                        name={name}
                        chain={selectChain}
                        fullWidth
                        label='target page/link'
                        items={items}
                    />
                </div>
            }
        />
    );
});
