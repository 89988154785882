import { Step } from 'react-joyride';

export const LANGUAGE_TOUR_STEPS: Step[] = [{
    target: '.admin-menu .admin-menu__item:nth-of-type(1)',
    content: 'This is the page, where you can add language and initial data to site',
    disableBeacon: true,
}, {
    target: '.tour-add-language',
    content: 'Click this button to add language and initial data',
}, {
    target: '.draggable-item:nth-of-type(1) .mmc-switch',
    content: 'Switch on the Language',
}, {
    target: '.tour-save-language',
    content: 'Click this button to save changes',
}];

export const EDIT_TOUR_STEPS: Step[] = [{
    target: '.admin-menu .admin-menu__item:nth-of-type(2)',
    content: `Here you can change texts and images for 2 required pages: landing page and all providers page.
    Next step will redirect you to landing page`,
    disableBeacon: true,
}, {
    target: '.admin-content .form-container:nth-of-type(1) .action-buttons__container',
    content: 'Click this button to edit block',
    placement: 'left',
}, {
    target: '.admin-content .form-container:nth-of-type(1) .start__menuTitle',
    content: `Every page, external link or block of landing has this field (linkable name).
    If you want to use required block or page in menu or to make link from button to this block,
    add some text, else leave this field empty`,
}, {
    target: '.admin-content .form-container:nth-of-type(1) .image-quiz .image-quiz__upload-button-block .image-quiz__upload-button',
    content: 'Upload image for desktop view',
    placement: 'top',
}, {
    target: '.admin-content .form-container:nth-of-type(1) .image-quiz__upload-button-block_mobile .image-quiz__upload-button',
    content: 'Upload image for mobile view',
    placement: 'top',
}, {
    target: '.admin-content .form-container:nth-of-type(1) .start__heading-text',
    content: 'Change text',
    placement: 'top',
}, {
    target: '.admin-content .form-container:nth-of-type(1) .action-buttons__container',
    content: 'Save or Cancel changes',
    placement: 'left',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .care__menuTitle',
    content: `Make the same changes in each block.
    If you want to remove block from nav bar list, leave this empty, else add some text`,
    placement: 'top',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .custom-editor',
    content: 'Add styles for text',
    placement: 'left',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .ql-toolbar .ql-formats:nth-of-type(1)',
    content: 'Make text bold or underlined',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .ql-toolbar .ql-formats:nth-of-type(2)',
    content: 'Add external and internal links in format: https://somesite.com',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .ql-toolbar .ql-formats:nth-of-type(3)',
    content: 'Add headings',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .ql-toolbar .ql-formats:nth-of-type(4)',
    content: 'Add lists',
}, {
    target: '.admin-content .form-container:nth-of-type(3) .ql-toolbar .ql-formats:nth-of-type(5)',
    content: 'Change text color',
}, {
    target: '.admin-content .swiper-wrapper .swiper-slide:nth-of-type(1) .slide__buttons',
    content: 'Add slide next to current or delete current slide',
    placement: 'left',
}, {
    target: '.admin-content .swiper-wrapper .swiper-slide:nth-of-type(1) .add-button',
    content: 'Add button under slide to add link to optional page or external site',
}, {
    target: '.admin-content .form-container:nth-of-type(8) .google .mmc-text-field-input',
    content: 'Go to google maps and copy link of map',
    placement: 'left',
}, {
    target: '.admin-content .form-container:nth-of-type(8) .google .info__button',
    content: 'Click this button and Follow instructions',
    placement: 'left',
}, {
    target: '.admin-content .form-container:nth-of-type(8) .contacts__button-wrapper .link .mmc-text-field:nth-of-type(3)',
    content: 'Go to google maps and copy directions link. Follow instructions',
    placement: 'right',
}];

export const LINKS_TOUR_STEPS: Step[] = [{
    target: '.admin-menu .admin-menu__item:nth-of-type(4)',
    content: 'Click here if you want to add additional page and use it in nav bar or as a link for some button',
    disableBeacon: true,
}, {
    target: '.edit-list__buttons',
    content: 'To add page click this button',
}, {
    target: '.edit-other__content .list__element:nth-of-type(1) .element__edit ',
    content: 'To edit page click this button',
}, {
    target: '.list__container .list__element:nth-of-type(1) .element__delete',
    content: 'To delete page click this button',
}, {
    target: '.admin-menu .admin-menu__item:nth-of-type(5)',
    content: 'Click here if you want to add external link and use it in nav bar or as a link for some button',
}, {
    target: '.edit-list__buttons',
    content: 'Click here to add external link',
}];

export const MENU_TOUR_STEPS: Step[] = [{
    target: '.admin-menu .admin-menu__item:nth-of-type(7)',
    content: 'This is the page where you can add or change nav bar',
    disableBeacon: true,
}, {
    target: '.draggable-item__name',
    content: 'Every nav bar item has type: block of landing, separate page and external link',
}, {
    target: '.draggable-item__title',
    content: 'Every nav bar item has name',
}, {
    target: '.draggable-item__buttons',
    content: 'Every nav bar item has switch to turn on or off the item in nav bar',
}, {
    target: '.draggable-list',
    content: 'If you want to change the order of items in the nav bar, move them up and down (drag and drop)',
}, {
    target: '.edit-list__buttons',
    content: 'Click this button to save all changes',
}];
