import { useStore } from 'effector-react';

import { Carousel, EmployeePhoto } from 'shared/components';
import { getFullName } from 'shared/utils/employee/employee';
import { Employee, EmployeeRoleType } from 'models/Employee';
import { $doctorTranslations } from 'store/translations/store';

import { Slide } from './Slide';

import './Doctor.scss';

interface DoctorProps {
    doctor: Employee;
}

export const Doctor = ({ doctor }: DoctorProps) => {
    const { medicalSchool, education, residency, hobbies } = useStore($doctorTranslations);

    return (
        <>
            <div className='doctor__top-block'>
                <div className='doctor__image-block'>
                    {doctor.photo.length > 1
                        ? <div className='doctor__slider-container'>
                            <Carousel
                                slides={doctor.photo}
                                slide={Slide}
                                slidesPerView='auto'
                            />
                        </div>
                        : <EmployeePhoto
                            photo={doctor.photo[0]}
                            width={254}
                            height={254}
                            className='doctor__photo'
                        />
                    }
                    <div className='doctor__title doctor__title_desktop'>{getFullName(doctor)}</div>
                </div>
                <div className='doctor__school-residency'>
                    {doctor.medicalSchool &&
                        <>
                            <h3>{doctor.roleType === EmployeeRoleType.Senior ? `${medicalSchool}:` : `${education}:`}</h3>
                            <p className='doctor-text__item'>{doctor.medicalSchool}</p>
                        </>
                    }
                    {doctor.residency &&
                        <>
                            <h3>{`${residency}:`}</h3>
                            <p className='doctor-text__item'>{doctor.residency}</p>
                        </>
                    }
                </div>
            </div>
            <div className='doctor__text-block doctor-text'>
                <div className='doctor__title doctor__title_mobile'>{getFullName(doctor)}</div>
                {doctor.aboutEmployee &&
                    <div className='doctor-text__about' dangerouslySetInnerHTML={{ __html: doctor.aboutEmployee }}></div>
                }
                <div className='doctor__school-residency_mobile'>
                    {doctor.medicalSchool &&
                        <>
                            <h3>{doctor.roleType === EmployeeRoleType.Senior ? `${medicalSchool}:` : `${education}:`}</h3>
                            <p className='doctor-text__item'>{doctor.medicalSchool}</p>
                        </>
                    }
                    {doctor.residency &&
                        <>
                            <h3>{`${residency}:`}</h3>
                            <p className='doctor-text__item'>{doctor.residency}</p>
                        </>
                    }
                </div>
                {doctor.hobbies &&
                    <>
                        <h3>{`${hobbies}:`}</h3>
                        <p className='doctor-text__item'>{doctor.hobbies}</p>
                    </>
                }
            </div>
        </>
    );
};
