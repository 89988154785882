import { createEvent } from 'effector';

import { MenuPosition } from 'models/Menu';

export const getSite = createEvent();
export const resetSite = createEvent();

export const getAllSites = createEvent();

export const getUserSites = createEvent();

export const updateMenuPosition = createEvent<MenuPosition>();

export const cloneSite = createEvent<number>();
