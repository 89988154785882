import { createStore } from 'effector';

import { StorageProvider } from 'shared/utils/storage';

export const $isAuthorized = createStore<boolean>(false);

export const $storageProvider = createStore<StorageProvider>(new StorageProvider());

export const $token = createStore<string>('');

export const $signInErrorMessage = createStore('');
