import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import { $params, $siteIdSwitcher, setSiteId, setSiteIdSwitcher } from 'store/params';
import { $localeSwitcher, setLocaleIdSwitcher, setLocaleLink } from 'store/locale';
import { $languagesLookup, $siteLanguagesStatus } from 'store/language';
import { LanguageShortName } from 'models/Language';
import { PrivateRoute } from 'models/Module';
import { numberRegex } from 'shared/constants/constants';
import { $localeLink } from 'store/locale/store';
import { $userSites } from 'store/site';

export function useLocaleAndSiteId() {
    const localeSwitcher = useStore($localeSwitcher);
    const localeLink = useStore($localeLink);
    const languagesLookup = useStore($languagesLookup);
    const params = useStore($params);

    const location = useLocation();
    const navigate = useNavigate();

    const path = useMemo(() => location.pathname.split('/'), [location.pathname]);

    const pathFirstElement = useMemo(() => path[1], [path]);

    const publicPathWithParams = useMemo(() => pathFirstElement !== 'login' && pathFirstElement !== 'admin', [pathFirstElement]);

    useEffect(() => {
        if (publicPathWithParams) {
            let siteId = pathFirstElement;
            let localeLink = path[2] as LanguageShortName;

            if (
                localeLink !== LanguageShortName.English &&
                localeLink !== LanguageShortName.Spanish &&
                localeLink !== LanguageShortName.Chinese
            ) {
                localeLink = LanguageShortName.English;
            }

            if (!numberRegex.test(siteId)) {
                siteId = '1';
            }

            setSiteId(siteId);
            setLocaleLink(localeLink);
        }
    }, [pathFirstElement, path[2], publicPathWithParams]);

    useEffect(() => {
        if (localeSwitcher && localeLink !== localeSwitcher && publicPathWithParams) {
            navigate(`/${params.siteId}/${localeSwitcher}`);
        }
    }, [localeSwitcher]);

    useEffect(() => {
        if (publicPathWithParams && localeLink !== localeSwitcher && languagesLookup.length) {
            const localeId = languagesLookup.find(element => element.name === localeLink)?.id.toString() ?? '1';

            setLocaleIdSwitcher(localeId);
        }
    }, [params, languagesLookup]);

    return { pathFirstElement, publicPathWithParams };
}

export function useLocaleAndSiteIdAdmin() {
    const navigate = useNavigate();

    const userSites = useStore($userSites);
    const { siteLanguages } = useStore($siteLanguagesStatus);
    const siteIdSwitcher = useStore($siteIdSwitcher);
    const localeSwitcher = useStore($localeSwitcher);

    useEffect(() => {
        if (userSites.length && !siteIdSwitcher) {
            const siteIdLocalStorage = localStorage.getItem('siteId');
            const site = siteIdLocalStorage && userSites.find(site => site.id === +siteIdLocalStorage);

            setSiteIdSwitcher((site || userSites[0]).id.toString());
        }
    }, [siteIdSwitcher, userSites]);

    useEffect(() => {
        if (siteLanguages.length && !localeSwitcher) {
            const locale = localStorage.getItem('locale');
            const localeId = (
                siteLanguages
                    .filter(language => language.order)
                    .find(element => element.name === locale) ??
                siteLanguages.find(language => language.order !== null)
            )?.id.toString();

            localeId ? setLocaleIdSwitcher(localeId) : navigate(PrivateRoute.Settings);
        }
    }, [localeSwitcher, navigate, siteLanguages]);
}
