import { Language } from 'models/Language';

import { ILanguageApi } from './interfaces';
import { LANGUAGES_URL, LANGUAGE_URL } from './constants';
import { HttpMethods } from '../constants';
import { getFilteredQuery, getNotFilteredQuery } from '../utils';

export class LanguageApi implements ILanguageApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getAllLanguages() {
        const response = await this.fetch(LANGUAGES_URL);

        return response;
    }

    public async getSiteLanguages(siteId: string) {
        const queryList = {
            siteId,
        };

        const response = await this.fetch(`${LANGUAGE_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async createLanguage(siteId: string, localeId: string) {
        const queryList = {
            siteId,
            localeId,
        };

        const response = await this.fetch(`${LANGUAGE_URL}${getFilteredQuery(queryList)}`, {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response;
    }

    public async editLanguages(siteId: number, languages: Language[]) {
        const queryList = {
            siteId,
        };

        const response = await this.fetch(`${LANGUAGE_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(languages),
            },
        );

        return response;
    }
}
