export class RequestResult<TData> {
    public static createSuccessful<TData>(data: TData): RequestResult<TData> {
        return new RequestResult(true, data);
    }

    public static createUnsuccessful<T>(errorMessage: string) {
        return new RequestResult<T>(false, undefined, errorMessage);
    }

    private readonly requestData?: TData;
    private readonly errorMessage?: string;

    private constructor(
        private readonly isSuccessful: boolean,
        requestData?: TData,
        errorMessage?: string,
    ) {
        this.requestData = requestData;
        this.errorMessage = errorMessage;
    }

    public get isSuccess() {
        return this.isSuccessful;
    }

    public get data() {
        if (!this.isSuccessful) {
            throw new Error('Cannot access data while request wasn\'t successful.');
        }

        return this.requestData;
    }

    public get error() {
        if (this.isSuccessful) {
            throw new Error('Cannot access error message while request was successful.');
        }

        return this.errorMessage;
    }

    public match<T>(onSuccess: (data: TData) => T, onError: (errorMessage: string) => T): T {
        return this.isSuccessful
            ? onSuccess(this.data!)
            : onError(this.error!);
    }
}
