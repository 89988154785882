import { ILandingApi } from 'api/landing';
import { RequestResult } from 'shared/utils/requestResult';
import { LandingValues } from 'models/Values';

export interface ILandingService {
    getLanding: (siteId: string, locale: string) => Promise<RequestResult<LandingValues>>;
}

export class LandingService implements ILandingService {
    constructor (
        private readonly LandingApi: ILandingApi,
        private readonly logger: Logger,
    ) { }

    public async getLanding(siteId: string, locale: string): Promise<RequestResult<LandingValues>> {
        try {
            const response = await this.LandingApi.getLanding(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Landing');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Landing');
        }
    }
}
