export const getParagraphs = (layout: string) => {
    const regex = /<p>.+?<\/p>/ig;
    const result = layout.match(regex);

    return result;
};

export const removeAllTags = (html = '') => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    return doc.body.textContent || '';
};
