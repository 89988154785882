import { createEffect, attach } from 'effector';

import { IExternalLinkService } from 'services/externalLink';
import { notify } from 'shared/utils/notification';
import { ExternalLink } from 'models/Module';
import { $externalLinkService } from 'store/services';
import { $params } from 'store/params';

export const fetchExternalLinks = createEffect(async (params: {
    service: IExternalLinkService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getExternalLinks(siteId, locale);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return [];
        },
    );
});

export const wrappedFetchExternalLinks = attach({
    source: $externalLinkService,
    mapParams: (
        { siteId, locale }: { siteId: string, locale: string },
        service,
    ) => ({ service, siteId, locale }),
    effect: fetchExternalLinks,
});

export const $areExternalLinksLoading = wrappedFetchExternalLinks.pending;

const createExternalLink = createEffect(async (params: {
    service: IExternalLinkService,
    externalLink: ExternalLink,
    siteId: string,
    locale: string,
}) => {
    const { service, externalLink, siteId, locale } = params;

    const result = await service.createExternalLink(siteId, locale, externalLink);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'External Link saved successfully.',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedCreateExternalLink = attach({
    source: {
        service: $externalLinkService,
        parameters: $params,
    },
    mapParams: (value: ExternalLink, states) => ({ ...states.parameters, service: states.service, externalLink: value }),
    effect: createExternalLink,
});

const updateExternalLinks = createEffect(async (params: {
    service: IExternalLinkService,
    externalLinks: ExternalLink[],
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale, externalLinks } = params;

    const result = await service.editExternalLinks(siteId, locale, externalLinks);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'External Link updated successfully.',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedUpdateExternalLinks = attach({
    source: {
        service: $externalLinkService,
        parameters: $params,
    },
    mapParams: (externalLinks: ExternalLink[], states) => ({ ...states.parameters, externalLinks, service: states.service }),
    effect: updateExternalLinks,
});

export const deleteExternalLink = attach({
    source: {
        service: $externalLinkService,
        parameters: $params,
    },
    mapParams: (externalLinkId: number, states) => ({ siteId: states.parameters.siteId, externalLinkId, service: states.service }),
    effect: createEffect(async (params: {
        externalLinkId: number,
        siteId: string,
        service: IExternalLinkService,
    }) => {
        const { service, externalLinkId, siteId } = params;

        const result = await service.deleteExternalLink(externalLinkId, siteId);

        return result.match(
            () => {
                notify({
                    type: 'info',
                    message: 'External Link deleted successfully.',
                });

                return true;
            },
            error => {
                notify({
                    type: 'danger',
                    message: error,
                });

                return false;
            },
        );
    }),
});

export const $isDeletingExternalLink = deleteExternalLink.pending;
