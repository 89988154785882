import { LanguageShortName, LanguageShortNameType } from 'models/Language';
import { PersonalPronouns } from 'models/Employee/PersonalPronouns';

export const physiciansTranslations: Record<LanguageShortNameType, string> = {
    [LanguageShortName.English]: 'Physicians',
    [LanguageShortName.Spanish]: 'Médicos',
    [LanguageShortName.Chinese]: '医师',
};

export const advancedProvidersTranslations: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'Advanced Providers',
    [LanguageShortName.Spanish]: 'Proveedores Avanzados',
    [LanguageShortName.Chinese]: '高级供应商',
};

export const yourCateTeamTranslations: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'Your Care Team',
    [LanguageShortName.Spanish]: 'Su equipo de atención',
    [LanguageShortName.Chinese]: '您的護理團隊',
};

export const seeAllTranslations: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'See All',
    [LanguageShortName.Spanish]: 'Ver Todo',
    [LanguageShortName.Chinese]: '看到所有',
};

export const medicalSchool: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'Medical School',
    [LanguageShortName.Spanish]: 'Escuela de Medicina',
    [LanguageShortName.Chinese]: '医学院',
};

export const education: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'Education',
    [LanguageShortName.Spanish]: 'Formazione scolastica',
    [LanguageShortName.Chinese]: '教育',
};

export const residency: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'Residency',
    [LanguageShortName.Spanish]: 'Residencia',
    [LanguageShortName.Chinese]: '居住地',
};

export const hobbies: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'Hobbies',
    [LanguageShortName.Spanish]: 'Aficiones',
    [LanguageShortName.Chinese]: '爱好',
};

export const enum Roles {
    MedicalDirector = 'Medical Director',
    Director = 'Director',
    NursePractitioner = 'Nurse Practitioner',
    Physician = 'Physician',
    PhysicianAssistant = 'Physician Assistant',
    AssistantMedicalDirector = 'Assistant Medical Director',
    CRNA = 'CRNA',
}

export const roles: Record<Roles, Record<PersonalPronouns, Record<LanguageShortName, string>>> = {
    [Roles.MedicalDirector]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'Medical Director',
            [LanguageShortName.Spanish]: 'Director médico practicante',
            [LanguageShortName.Chinese]: '医疗主任',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'Medical Director',
            [LanguageShortName.Spanish]: 'Director médico practicante',
            [LanguageShortName.Chinese]: '医疗主任',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'Medical Director',
            [LanguageShortName.Spanish]: 'Directora médica practicante',
            [LanguageShortName.Chinese]: '医疗主任',
        },
    },
    [Roles.Director]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'Director',
            [LanguageShortName.Spanish]: 'Director practicante',
            [LanguageShortName.Chinese]: '导向器',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'Director',
            [LanguageShortName.Spanish]: 'Director practicante',
            [LanguageShortName.Chinese]: '导向器',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'Director',
            [LanguageShortName.Spanish]: 'Directora practicante',
            [LanguageShortName.Chinese]: '导向器',
        },
    },
    [Roles.NursePractitioner]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'Nurse Practitioner',
            [LanguageShortName.Spanish]: 'Enfermero practicante',
            [LanguageShortName.Chinese]: '护士执业',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'Nurse Practitioner',
            [LanguageShortName.Spanish]: 'Enfermero practicante',
            [LanguageShortName.Chinese]: '护士执业',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'Nurse Practitioner',
            [LanguageShortName.Spanish]: 'Enfermera practicante',
            [LanguageShortName.Chinese]: '护士执业',
        },
    },
    [Roles.Physician]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'Physician',
            [LanguageShortName.Spanish]: 'médico',
            [LanguageShortName.Chinese]: '医师',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'Physician',
            [LanguageShortName.Spanish]: 'médico',
            [LanguageShortName.Chinese]: '医师',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'Physician',
            [LanguageShortName.Spanish]: 'médica',
            [LanguageShortName.Chinese]: '医师',
        },
    },
    [Roles.PhysicianAssistant]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'Physician Assistant',
            [LanguageShortName.Spanish]: 'Asistente médico',
            [LanguageShortName.Chinese]: '助理医师',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'Physician Assistant',
            [LanguageShortName.Spanish]: 'Asistente médico',
            [LanguageShortName.Chinese]: '助理医师',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'Physician Assistant',
            [LanguageShortName.Spanish]: 'Asistente medica',
            [LanguageShortName.Chinese]: '助理医师',
        },
    },
    [Roles.AssistantMedicalDirector]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'Assistant Medical Director',
            [LanguageShortName.Spanish]: 'Director médico asistente',
            [LanguageShortName.Chinese]: 'Assistant Medical Director',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'Assistant Medical Director',
            [LanguageShortName.Spanish]: 'Director médico asistente',
            [LanguageShortName.Chinese]: 'Assistant Medical Director',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'Assistant Medical Director',
            [LanguageShortName.Spanish]: 'Directora medica asistente',
            [LanguageShortName.Chinese]: 'Assistant Medical Director',
        },
    },
    [Roles.CRNA]: {
        [PersonalPronouns.Default]: {
            [LanguageShortName.English]: 'CRNA',
            [LanguageShortName.Spanish]: 'CRNA',
            [LanguageShortName.Chinese]: 'CRNA',
        },
        [PersonalPronouns.He]: {
            [LanguageShortName.English]: 'CRNA',
            [LanguageShortName.Spanish]: 'CRNA',
            [LanguageShortName.Chinese]: 'CRNA',
        },
        [PersonalPronouns.She]: {
            [LanguageShortName.English]: 'CRNA',
            [LanguageShortName.Spanish]: 'CRNA',
            [LanguageShortName.Chinese]: 'CRNA',
        },
    },
};

export const readMore: Record<LanguageShortName, string> = {
    [LanguageShortName.English]: 'read more',
    [LanguageShortName.Spanish]: 'leggi di più',
    [LanguageShortName.Chinese]: '阅读更多',
};
