import { useState, useEffect, useContext } from 'react';
import { useStore } from 'effector-react';

import { EmployeePhoto } from 'shared/components/EmployeePhoto';
import { getFullNameWithRole } from 'shared/utils/employee/employee';
import { Search } from 'features/Form';
import { $allEmployeesGetStatus, getAllEmployees } from 'store/employees';
import { TeamKeys } from 'models/Values';
import { Employee } from 'models/Employee';
import { DoctorModal } from 'widgets/Providers/DoctorModal';
import { Accordion } from 'shared/components';

import { Context } from '../context';

import './EmployeeComponent.scss';

interface EditEmployeeComponentProps {
    initialValue: Employee;
    name: TeamKeys;
    className?: string;
    isDirector?: boolean;
}

export const EditEmployeeComponent = ({
    initialValue,
    name,
    className,
    isDirector = false,
}: EditEmployeeComponentProps) => {
    const [employee, setEmployee] = useState<Employee | undefined>(initialValue);

    const { isEditing } = useContext(Context);

    const { allEmployees, areEmployeesLoading } = useStore($allEmployeesGetStatus);

    useEffect(() => {
        isEditing && getAllEmployees();
    }, [isEditing]);

    return (
        <div className='director__container'>
            {isEditing &&
                <Search
                    label='employee'
                    name={name}
                    className='director__input'
                    initialValue={employee}
                    initialItems={allEmployees}
                    loading={areEmployeesLoading}
                    setEmployee={setEmployee}
                />
            }
            <div className={className}>
                <EmployeePhoto
                    photo={employee?.photo[0]}
                    width={isDirector ? 350 : 250}
                    height={isDirector ? 350 : 250}
                />
                <p className={`${className}__name`}>
                    {employee && getFullNameWithRole(employee)}
                </p>
                {isDirector
                    ? <div className='accordion__content'>
                        {employee?.bio &&
                            <p className={`${className}__bio`}>{employee?.bio}</p>
                        }
                        <div
                            className={`${className}__text`}
                            dangerouslySetInnerHTML={{ __html: employee?.aboutEmployee ?? '' }}
                        />
                    </div>
                    : <Accordion
                        title={employee?.bio ?? ''}
                        content={employee?.aboutEmployee ?? ''}
                    />
                }
            </div>
            <DoctorModal />
        </div>
    );
};
