import { createEvent } from 'effector';

import { ValuesVideo } from 'models/Values';

export const getVideoSection = createEvent<{ siteId: string; locale: string; }>();

export const updateVideoSection = createEvent<ValuesVideo>();

export const hideVideoSection = createEvent<boolean>();

export const setVideoError = createEvent<string>();
