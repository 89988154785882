import { useCallback } from 'react';

import { ButtonProps as MuiButtonProps } from 'shared/adminComponents/Button';
import { Modal, WatchButton } from 'shared/adminComponents';
import { Image } from 'shared/components';
import { useToggle } from 'shared/utils/hooks/useToggle';

export interface WatchButtonIconProps extends MuiButtonProps {
    image: string;
}

export function WatchButtonIcon({
    image,
    ...restProps
}: WatchButtonIconProps) {
    const [open, setToggle] = useToggle(false);

    const onClick = useCallback(() => {
        setToggle();
    }, [setToggle]);

    return (
        <>
            <WatchButton
                {...restProps}
                onClick={onClick}
            />
            <Modal
                isOpen={open}
                onClick={onClick}
            >
                <Image
                    containerClassName='image-gallery__watch-container'
                    image={image}
                    width={254}
                    height={254}
                    alt='zoomed-image'
                />
            </Modal>
        </>
    );
}
