import { IPublicationsApi } from 'api/landing/publications';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesPublication } from 'models/Values';

export interface IPublicationService {
    hidePublication: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesPublication>>;
    getPublication: (siteId: string, locale: string) => Promise<RequestResult<ValuesPublication>>;
    editPublication: (siteId: string, locale: string, PublicationValues: ValuesPublication) => Promise<RequestResult<ValuesPublication>>;
}

export class PublicationService implements IPublicationService {
    constructor (
        private readonly PublicationApi: IPublicationsApi,
        private readonly logger: Logger,
    ) { }

    public async hidePublication(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesPublication>> {
        try {
            const response = await this.PublicationApi.hidePublications(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide Publications Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide Publications');
        }
    }

    public async getPublication(siteId: string, locale: string): Promise<RequestResult<ValuesPublication>> {
        try {
            const response = await this.PublicationApi.getPublications(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Publications Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Publications');
        }
    }

    public async editPublication(siteId: string, locale: string, PublicationValues: ValuesPublication): Promise<RequestResult<ValuesPublication>> {
        try {
            const response = await this.PublicationApi.editPublications(siteId, locale, PublicationValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Publications Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Publications Section');
        }
    }
}
