import { createEvent } from 'effector';

import { ValuesStart } from 'models/Values';

export const getHeaderSection = createEvent<{ siteId: string; locale: string; }>();

export const updateHeaderSection = createEvent<ValuesStart>();

export const hideHeaderSection = createEvent<boolean>();

export const setHeaderSectionError = createEvent<string>();
