import { combine } from 'effector';

import { LanguageShortName } from 'models/Language';
import { isLanguageShortName } from 'shared/utils/checkType';
import { $locale, $localeSwitcher } from 'store/locale';

import {
    advancedProvidersTranslations,
    hobbies,
    medicalSchool,
    education,
    physiciansTranslations,
    residency,
    seeAllTranslations,
    readMore,
    yourCateTeamTranslations,
} from './constants';

const $physiciansLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && physiciansTranslations[locale],
);

const $advancedProvidersLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && advancedProvidersTranslations[locale],
);

const $seeAllLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && seeAllTranslations[locale],
);

const $yourCareTeamLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && yourCateTeamTranslations[locale],
);

const $residencyLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && residency[locale],
);

const $medicalSchoolLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && medicalSchool[locale],
);

const $educationLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && education[locale],
);

const $hobbiesLocale = combine(
    $localeSwitcher,
    (locale: LanguageShortName | string) => isLanguageShortName(locale) && hobbies[locale],
);

export const $doctorTranslations = combine({
    physicians: $physiciansLocale,
    advancedProviders: $advancedProvidersLocale,
    seeAll: $seeAllLocale,
    yourCareTeam: $yourCareTeamLocale,
    residency: $residencyLocale,
    medicalSchool: $medicalSchoolLocale,
    education: $educationLocale,
    hobbies: $hobbiesLocale,
});

export const $readMoreLocale = combine(
    $localeSwitcher,
    $locale,
    (localeSwitcher: LanguageShortName | string, locale: LanguageShortName | string) => {
        const lg = localeSwitcher || locale;

        return isLanguageShortName(lg) && isLanguageShortName(lg) && readMore[lg];
    }
);
