import * as Yup from 'yup';

import { FlexibleKey, FieldValidation } from 'models/Values';
import { ButtonKey } from 'models/Button';
import { SlideKey } from 'models/Carousel/SlideType';
import { removeAllTags } from 'shared/utils/string/tags';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'getLengthFromLayout', function (maxLength) {
    return this.test('getLengthFromLayout', function (value?: string) {
        const { path, createError } = this;
        const str = removeAllTags(value).trim();

        if (!str) {
            return createError({ path, message: 'Text is required' });
        } else if (str.length > maxLength) {
            return createError({ path, message: 'Text is too long' });
        } else {
            return true;
        }
    });
});

export const fields = {
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.LabelText]: Yup.string().trim().required('Heading is required').max(150, 'Heading is too long'),
    [FlexibleKey.Title]: Yup.string().trim().required('Section Name is required').max(150, 'Section Name is too long'),
    [FlexibleKey.Heading]: Yup.string().trim().required('Heading is required').max(3000, 'Text is too long'),
    [FlexibleKey.DirectorId]: Yup.number().nullable().required('Employee is required'),
    [FlexibleKey.Button]: Yup.object().shape({
        [ButtonKey.Text]: Yup.string().trim().required('Text is required').max(90, 'Text is too long'),
        [ButtonKey.Link]: Yup.string().trim().required('Link is required').max(1500, 'Link is too long'),
    }),
    [FlexibleKey.Slides]: Yup.array().of(Yup.object({
        [SlideKey.BodyHeader]: Yup.string().trim().max(150, 'Heading is too long'),
        [SlideKey.ImageFooterText]: Yup.string().trim().max(150, 'Text is too long'),
        [SlideKey.BodyText]: Yup.string().getLengthFromLayout(3600),
    })).required().min(1, 'Min 1 slide is required'),
};

export const createYupSchema = <T extends object>(fields: FieldValidation, values: T) => {
    const validation = Object.entries(values).reduce(
        (prevValues: object, [currentValueKey, _]: [string, unknown]) => {
            for (const key in fields) {
                if (currentValueKey === key) {
                    return {
                        ...prevValues,
                        [currentValueKey]: fields[key],
                    };
                }
            }

            return prevValues;
        }, {});

    return Yup.object().shape(validation);
};

export const validationTextBlock = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Title]: Yup.string().trim().required('Section Name is required').max(150, 'Section Name is too long'),
    [FlexibleKey.Text]: Yup.string().getLengthFromLayout(2040),
});

export const validationImage = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Title]: Yup.string().trim().required('Section Name is required').max(150, 'Section Name is too long'),
});

export const validationVideo = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Title]: Yup.string().trim().required('Section Name is required').max(150, 'Section Name is too long'),
    [FlexibleKey.Link]: Yup.string().trim().required('Text is required'),
});

export const validationPublications = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Title]: Yup.string().trim().required('Section Name is required').max(200, 'Section Name is too long'),
    [FlexibleKey.Slides]: Yup.array().of(Yup.object({
        [SlideKey.ImageFooterText]: Yup.string().trim().required('Text is required').max(90, 'Text is too long'),
        [SlideKey.BodyText]: Yup.string().getLengthFromLayout(2850),
    })).required().min(1, 'Min 1 slide is required'),
});

export const validationSolution = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Slides]: Yup.array().of(Yup.object({
        [SlideKey.ImageFooterText]: Yup.string().trim().required('Text is required').max(90, 'Text is too long'),
        [SlideKey.BodyText]: Yup.string().getLengthFromLayout(2850),
    })).required().min(1, 'Min 1 slide is required'),
});

export const validationPage = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Header]: Yup.string().trim().required('Heading is required').max(150, 'Text is too long'),
    [FlexibleKey.Text]: Yup.string().getLengthFromLayout(30000),
});

export const validationOtherPage = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({
        [FlexibleKey.Title]: Yup.string().trim().required('Linkable Name is required').max(120, 'Linkable Name is too long'),
    }),
    [FlexibleKey.Header]: Yup.string().trim().required('Heading is required').max(150, 'Text is too long'),
    [FlexibleKey.Text]: Yup.string().getLengthFromLayout(30000),
});

export const validationFooter = Yup.object().shape({
    [FlexibleKey.MenuItem]: Yup.object().shape({ [FlexibleKey.Title]: Yup.string().trim().max(120, 'Linkable Name is too long') }),
    [FlexibleKey.Links]: Yup.array().of(Yup.object({
        [ButtonKey.Text]: Yup.string().trim().required('Text is required').max(90, 'Text is too long'),
        [ButtonKey.Link]: Yup.string().trim().required('Link is required').max(1500, 'Link is too long'),
    })).required().min(1, 'Min 1 slide is required'),
});
