export type QueryValue = number | string | boolean | undefined | null;
export type QueryParamsObject = { [key: string]: QueryValue };
export type QueryParamsArray = [string, QueryValue][];

export const createArrayFromObject = (list: QueryParamsObject) => {
    return Object.entries(list);
};

export const filterQuery = (list: QueryParamsArray) => {
    return list.filter(([_, queryValue]) => !!queryValue);
};

export const getNotFilteredQuery = (list: QueryParamsObject) => {
    const queryArr = createArrayFromObject(list);

    const queryString = queryArr.map(([queryKey, queryValue]) => {
        return `${queryKey}=${typeof queryValue === 'string' ? encodeURIComponent(queryValue) : queryValue}`;
    }).join('&');

    return queryString ? `?${queryString}` : '';
};

export const getFilteredQuery = (list: QueryParamsObject) => {
    const queryArr = createArrayFromObject(list);
    const filteredQueryArr = filterQuery(queryArr);

    const queryString = filteredQueryArr.map(([queryKey, queryValue]) => {
        return `${queryKey}=${typeof queryValue === 'string' ? encodeURIComponent(queryValue) : queryValue}`;
    }).join('&');

    return queryString ? `?${queryString}` : '';
};
