import { memo, useState, useCallback, useEffect } from 'react';
import cn from 'classnames';

import { ButtonProps as MuiButtonProps } from 'shared/adminComponents/Button';
import { AddButton, DeleteButton, InfoButton } from 'shared/adminComponents/IconButtons';

import { EditQenericComponent } from '../GenericComponent';
import { InfoComponent } from '../InfoComponent/InfoComponent';

interface InfoButtonProps extends MuiButtonProps {
    layout: string;
    className?: string;
    isLoading: boolean;
}

export const InfoButtonComponent = ({
    className,
    layout,
    isLoading,
    ...restProps
}: InfoButtonProps) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const onClick = useCallback(() => {
        setOpen(prevState => !prevState);
    }, []);

    useEffect(() => {
        setOpen(false);
    }, [isLoading]);

    return (
        <EditQenericComponent
            layout={null}
            quiz={
                <InfoButton
                    render={
                        <InfoComponent
                            className={cn(className, { info__modal_shown : isOpen })}
                            layout={layout}
                        />
                    }
                    onClick={onClick}
                    {...restProps}
                />
            }
        />
    );
};

export const AddButtonComponent = memo(({
    ...restProps
}: MuiButtonProps) => {
    return (
        <EditQenericComponent
            layout={null}
            quiz={<AddButton {...restProps} />}
        />
    );
});

export const DeleteButtonComponent = memo(({
    ...restProps
}: MuiButtonProps) => {
    return (
        <EditQenericComponent
            layout={null}
            quiz={<DeleteButton {...restProps} />}
        />
    );
});
