import { useEffect, MutableRefObject } from 'react';

type LazyLoadProps = {
    callback: () => void;
    triggerRef: MutableRefObject<HTMLElement>;
    wrapperRef: MutableRefObject<HTMLElement | null>;
    threshold?: number;
};

export const useIntersectionObserver = ({
    callback,
    triggerRef,
    wrapperRef,
    threshold = 1.0,
}: LazyLoadProps) => {

    useEffect(() => {
        const wrapperElement = wrapperRef.current;
        const triggerElement = triggerRef.current;

        const options = {
            root: wrapperElement,
            rootMargin: '0px',
            threshold,
        };

        const observer = new IntersectionObserver(([entry]) => {
            entry.isIntersecting && callback();
        }, options);

        observer.observe(triggerElement);

        return () => observer.unobserve(triggerElement);
    }, [callback, threshold, triggerRef, wrapperRef]);
};
