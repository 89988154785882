import { IMediaApi } from 'api/media';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesMedia } from 'models/Values/Blocks';

export interface IMediaService {
    getMedia: (siteId: string, locale: string) => Promise<RequestResult<ValuesMedia[]>>;
    getSharedMedia: (siteId: string, locale: string) => Promise<RequestResult<ValuesMedia[]>>;
    createMedia: (siteId: string, locale: string, media: ValuesMedia) => Promise<RequestResult<ValuesMedia>>;
    editMedia: (siteId: string, locale: string, media: ValuesMedia[]) => Promise<RequestResult<ValuesMedia>>;
    addSharedMediaToSite: (siteId: string, locale: string, mediaId: number) => Promise<RequestResult<ValuesMedia>>;
    deleteMedia: (id: number, siteId: string) => Promise<RequestResult<boolean>>;
    deleteMediaFromSite: (mediaId: number, siteId: string, locale: string) => Promise<RequestResult<boolean>>;
}

export class MediaService implements IMediaService {
    constructor (
        private readonly mediaApi: IMediaApi,
        private readonly logger: Logger,
    ) { }

    public async getMedia(
        siteId: string,
        locale: string,
    ): Promise<RequestResult<ValuesMedia[]>> {
        try {
            const response = await this.mediaApi.getMedia(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch media');
        }
    }

    public async getSharedMedia(
        siteId: string,
        locale: string,
    ): Promise<RequestResult<ValuesMedia[]>> {
        try {
            const response = await this.mediaApi.getSharedMedia(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch media');
        }
    }

    public async createMedia(siteId: string, locale: string, media: ValuesMedia): Promise<RequestResult<ValuesMedia>> {
        try {
            const response = await this.mediaApi.createMedia(siteId, locale, media);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to add media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to add media');
        }
    }

    public async editMedia(siteId: string, locale: string, media: ValuesMedia[]): Promise<RequestResult<ValuesMedia>> {
        try {
            const response = await this.mediaApi.editMedia(siteId, locale, media);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit media');
        }
    }

    public async addSharedMediaToSite(siteId: string, locale: string, mediaId: number): Promise<RequestResult<ValuesMedia>> {
        try {
            const response = await this.mediaApi.addSharedMediaToSite(siteId, locale, mediaId);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to add media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to add media');
        }
    }

    public async deleteMedia(id: number, siteId: string): Promise<RequestResult<boolean>> {
        try {
            const response = await this.mediaApi.deleteMedia(id, siteId);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to delete media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to delete media');
        }
    }

    public async deleteMediaFromSite(mediaId: number, siteId: string, locale: string): Promise<RequestResult<boolean>> {
        try {
            const response = await this.mediaApi.deleteMediaFromSite(mediaId, siteId, locale);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to delete media');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to delete media');
        }
    }
}
