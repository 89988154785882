import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import { authLinks } from 'shared/utils/links';
import { Loader } from 'shared/components';
import { $isAuthorized, $isSignInLoading, $storageProvider, setIsAuthorized, updateToken } from 'store/auth';
import { $authService } from 'store/services';
import { AuthType } from 'models/Auth/TokenInfo';

interface RequireAuthProps {
    children: JSX.Element;
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
    const authService = useStore($authService);
    const storageProvider = useStore($storageProvider);

    const navigate = useNavigate();
    const isAuthorized = useStore($isAuthorized);
    const isSignInLoading = useStore($isSignInLoading);

    useEffect(() => {
        const checkIfAuthenticated = async () => {
            const tokenInfo = storageProvider.getToken();
            let isAnonymous = true;

            if (tokenInfo) {
                if (tokenInfo.IsExpired) {
                    const result = tokenInfo.SignInType == AuthType.Okta
                        ? await authService.refreshOktaToken(tokenInfo)
                        : await authService.refreshToken(tokenInfo);

                    if (result.isSuccess && result.data) {
                        updateToken(result.data);
                        isAnonymous = false;
                    }
                } else {
                    updateToken(tokenInfo);
                    isAnonymous = false;
                }
            }

            setIsAuthorized(!isAnonymous);

            isAnonymous && navigate(authLinks.signIn);
        };

        void checkIfAuthenticated();

    }, [children, navigate, authService, storageProvider]);

    if (isSignInLoading || !isAuthorized) {
        return <Loader />;
    }

    return children;
};
