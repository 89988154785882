import { getNotFilteredQuery } from 'api/utils';
import { HttpMethods } from 'api/constants';
import { MenuPosition } from 'models/Menu';

import { ISiteApi } from './interfaces';
import { SITE_URL } from './constants';

export class SiteApi implements ISiteApi {
    constructor(
        private readonly fetch: Fetch,
    ) { }

    public async getAllSites() {
        const response = await this.fetch(`${SITE_URL}/profile`);

        return response;
    }

    public async getUserSites() {
        const response = await this.fetch(`${SITE_URL}/admin-sites`);

        return response;
    }

    public async getSiteById(id: string) {
        const response = await this.fetch(`${SITE_URL}/profile/${id}`);

        return response;
    }

    public async updateMenuPosition(siteId: string, menuPosition: MenuPosition) {
        const queryList = {
            siteId,
            menuPosition,
        };

        const response = await this.fetch(`${SITE_URL}/update-menu-position${getNotFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
            },
        );

        return response;
    }

    public async cloneSite(siteId: number) {
        const response = await this.fetch(`${SITE_URL}/clone-site/${siteId}`,
            {
                method: HttpMethods.Post,
            },
        );

        return response;
    }
}
