import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import { Loader } from 'shared/components';
import { AdminHeader } from 'shared/adminComponents/Header';
import { Header } from 'widgets/Header';
import { PublicRoute } from 'models/Module';
import { useLocaleAndSiteId, useLocaleAndSiteIdAdmin } from 'shared/utils/hooks/useLocaleAndSiteId';
import { AppTourProvider } from 'features/Tour/context/TourContext';
import { ContactUsButton } from 'features/ContactUs/ContactUsButton/ContactUsButton';
import { OktaSignIn } from 'pages/admin/OktaAuth';
import SingleProvider from 'pages/public/DoctorsPages/SingleProvider';

import { RequireAuth } from './PrivateRoute';
import { privateRoutesConfig, publicRoutesConfig } from './routerConfig';
import { useViewport } from 'shared/utils/hooks/useViewport';
import { FooterSection } from 'widgets/Blocks/FooterSection/FooterSection';
import { $footerSection, getFooterSection, updateFooterSection } from 'store/footer';
import { useStore } from 'effector-react';
import { EditPageWrapper } from 'features/EditPage';
import { FooterKeys, ValuesFooter } from 'models/Values/Blocks/Landing';
import { validationFooter } from 'features/EditPage/formValidationSchema';

const AuthPage = lazy(() => import('pages/admin/AuthPage'));
const AppObserver = lazy(() => import('pages/admin/AppObserver'));

export const AppRouter = () => {
    const { pathFirstElement, publicPathWithParams } = useLocaleAndSiteId();

    useEffect(() => {
        localStorage.setItem('host', window.location.host);
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path=':siteId/:locale' element={<PublicContainer />}>
                    {publicRoutesConfig.map(route => (
                        <Route
                            element={<route.component />}
                            path={route.path}
                            key={route.path}
                        />
                    ))}
                </Route>
                <Route
                    path=':siteId'
                    element={
                        <Navigate
                            replace
                            to={`/${publicPathWithParams && pathFirstElement || '1'}/en${PublicRoute.Main}`}
                        />
                    }
                >
                </Route>
                <Route path='' element={<Navigate replace to={`/1/en${PublicRoute.Main}`} />} />
                <Route path='*' element={<Navigate replace to={`/1/en${PublicRoute.Main}`} />} />
                <Route element={<PrivateContainer />}>
                    {privateRoutesConfig.map(route => (
                        <Route
                            element={<RequireAuth children={<route.component />} />}
                            path={route.path}
                            key={route.path}
                        />
                    ))}
                </Route>
                <Route path='appObserver' element={<RequireAuth children={<AppObserver />} />} />
                <Route path='login' element={<AuthPage />} />
                <Route path='oktaSigninCallback' element={<OktaSignIn />} />
                <Route path=':siteId/:locale/:id/:employee' element={<SingleProvider />} />
            </Routes>
        </Suspense>
    );
};

function PublicContainer() {
    const { isMobile } = useViewport(900);

    const footer = useStore($footerSection);

    useEffect(() => {
        getFooterSection();
    }, []);

    return (
        <div className='mmc'>
            <Header />
            <Outlet />
            <FooterSection values={footer} />
            {isMobile && <ContactUsButton />}
        </div>
    );
}

function PrivateContainer() {
    useLocaleAndSiteIdAdmin();

    const footer = useStore($footerSection);

    useEffect(() => {
        getFooterSection();
    }, []);

    return (
        <AppTourProvider>
            <div className='mmc'>
                <AdminHeader />
                <Outlet />
                <EditPageWrapper<ValuesFooter, FooterKeys>
                    Component={FooterSection}
                    defaultValues={footer}
                    onSubmit={updateFooterSection}
                    initValidationSchema={validationFooter}
                />
            </div>
        </AppTourProvider>
    );
}
