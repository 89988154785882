import { MenuItemType } from 'models/Menu';
import { ValuesOther } from 'models/Values';

export const emptyValuesOther: ValuesOther = {
    id: '0',
    header: '',
    text: '',
    image: '',
    mobileImage: '',
    menuItem: {
        id: 0,
        title: '',
        link: '',
        type: MenuItemType.Block,
        order: null,
    },
};
