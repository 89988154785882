import { memo } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './AddButton.scss';

interface AddButtonProps extends Omit<MuiButtonProps, 'className'> {
    className?: string;
}

export const AddButton = memo(({
    className,
    ...restProps
}: AddButtonProps) => {
    return (
        <IconButton
            {...restProps}
            aria-label='add'
            size={ButtonSize.Small}
            className={cn('add-button', className)}
        >
            <AddIcon  />
        </IconButton>
    );
});
