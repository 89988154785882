import { HttpMethods } from 'api/constants';

import { ILookUpsApi } from './interfaces';
import { LOOKUPS_URL } from './constants';

export class LookUpsApi implements ILookUpsApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getRoles() {
        const response = await this.fetch(`${LOOKUPS_URL}/roles`);

        return response;
    }
}
