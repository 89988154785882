import { HttpMethods } from 'api/constants';
import { getFilteredQuery } from 'api/utils';
import { ExternalLink } from 'models/Module';

import { IExternalLinkApi } from './interfaces';
import { EXTERNAL_LINK_URL } from './constants';


export class ExternalLinkApi implements IExternalLinkApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getExternalLinks(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EXTERNAL_LINK_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async createExternalLink(siteId: string, locale: string, externalLink: ExternalLink) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EXTERNAL_LINK_URL}${getFilteredQuery(queryList)}`, {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(externalLink),
            },
        );

        return response;
    }

    public async editExternalLinks(siteId: string, locale: string, externalLinks: ExternalLink[]) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EXTERNAL_LINK_URL}s${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(externalLinks),
            },
        );

        return response;
    }

    public async deleteExternalLink(id: number, siteId: string) {
        const queryList = {
            siteId,
            id,
        };

        const response = await this.fetch(`${EXTERNAL_LINK_URL}${getFilteredQuery(queryList)}`, {
            method: HttpMethods.Delete,
        });

        return response;
    }
}
