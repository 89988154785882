import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';

import { PublicRoute } from 'models/Module';
import { $params } from 'store/params';

import { useViewport } from 'shared/utils/hooks/useViewport';
import { ValuesFooter } from 'models/Values/Blocks/Landing';
import { EditFooterSection } from 'features/EditPage/FooterComponent/FooterComponent';
import { FlexibleKey } from 'models/Values';

import { ListElement } from './ListElement';
import { EditTextComponent, HideCheckboxComponent } from 'features/EditPage';
import { hideFooterSection } from 'store/footer/events';
import { $isAuthorized } from 'store/auth';

import './FooterSection.scss';

const textChain = [FlexibleKey.Title];

interface FooterSectionProps {
    values: ValuesFooter;
}

export const FooterSection = ({ values }: FooterSectionProps) => {
    const { isMobile } = useViewport(900);

    const params = useStore($params);
    const isAuthorized = useStore($isAuthorized);

    return (
        <div className='footer'>
            <div className='container'>
                <div className='footer__content'>
                    {!isMobile && 
                        <Link to={`/${params.siteId}/${params.locale}/${PublicRoute.Main}`} className='footer__logo'>
                            <img className='logo' src='/images/logo.jpg' alt='logo' />
                        </Link>
                    }
                </div>
                {(!values.isHidden || isAuthorized) &&
                    <>
                        <div className='footer__hide'>
                        <HideCheckboxComponent
                                name={FlexibleKey.isHidden}
                                value={values.isHidden}
                                onChange={hideFooterSection}
                                label='Hide Footer Section'
                            />
                        </div>
                        <EditTextComponent
                            className='list-articles__menuTitle'
                            name={FlexibleKey.MenuItem}
                            chain={textChain}
                            label='linkable name (optional)'
                            layout={null}
                        />
                        <EditFooterSection
                            list={values.links}
                            name={FlexibleKey.Links}
                            listElement={ListElement}
                        />
                    </>
                }
            </div>
        </div>
    );
};
