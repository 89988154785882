import { useRef, useState, useCallback, ReactNode } from 'react';
import { useStore } from 'effector-react';

import { $readMoreLocale } from 'store/translations';

import { ReactComponent as Arrow } from '../Icons/go-back.svg';

import './Accordion.scss';

export interface AccordionProps {
    title: string | ReactNode;
    content: string | ReactNode;
    buttonText?: string;
}

export const Accordion = ({ title, content, buttonText }: AccordionProps) => {
    const [active, setActive] = useState(false);
    const [height, setHeight] = useState('0px');

    const readMore = useStore($readMoreLocale);

    const contentRef = useRef<HTMLDivElement>(null);

    const toggleAccordion = useCallback(() => {
        setActive(!active);
        setHeight(active ? '0px' : `${contentRef.current?.scrollHeight ? contentRef.current?.scrollHeight + 50 : ''}px`);
    }, [active]);

    return (
        <div className='accordion__section'>
            <div
                className={`accordion ${active ? 'active' : ''}`}
                onClick={toggleAccordion}
            >
                {getBlock(title)}
                <div className='accordion__button'>
                    {active ? '' : buttonText ?? readMore}
                    <Arrow />
                </div>
            </div>
            <div
                ref={contentRef}
                style={{ maxHeight: `${height}` }}
                className='accordion__content'
            >
                {getBlock(content)}
            </div>
        </div>
    );
};

const getBlock = (block: string | ReactNode) => {
    return typeof block === 'string' ? <div dangerouslySetInnerHTML={{ __html: block }} /> : block;
};
