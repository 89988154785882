import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $headerSection, $errorHeader } from './store';
import { wrappedFetchHeaderSection, wrappedUpdateHeader, wrappedHideHeader } from './effects';
import { getHeaderSection, setHeaderSectionError, updateHeaderSection, hideHeaderSection } from './events';

sample({
    clock: hideHeaderSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideHeader,
});

forward({
    from: wrappedHideHeader.doneData,
    to: $headerSection,
});

forward({
    from: getHeaderSection,
    to: wrappedFetchHeaderSection,
});

forward({
    from: wrappedFetchHeaderSection.doneData,
    to: $headerSection,
});

sample({
    clock: updateHeaderSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateHeader,
});

forward({
    from: wrappedUpdateHeader.doneData,
    to: $headerSection,
});

forward({
    from: setHeaderSectionError,
    to: $errorHeader,
});
