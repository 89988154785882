import { forward, sample, createWatch } from 'effector';

import { setSiteId, setSiteIdSwitcher } from './events';
import { $siteId, $siteIdSwitcher } from './store';

forward({
    from: setSiteId,
    to: $siteId,
});

forward({
    from: setSiteIdSwitcher,
    to: $siteIdSwitcher,
});

sample({
    clock: $siteIdSwitcher,
    filter: siteIdSwitcher => siteIdSwitcher !== '',
    target: $siteId,
});

createWatch({
    unit: $siteIdSwitcher,
    fn: siteIdSwitcher => localStorage.setItem('siteId', siteIdSwitcher),
});
