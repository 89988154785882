import { createEffect, attach } from 'effector';

import { IMenuService } from 'services/menu';
import { $menuService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { MenuItem } from 'models/Menu';

import { getMenu, setMenuError, setMenuSuccess } from './events';

const fetchMenu = createEffect(async (params: {
    service: IMenuService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getMenu(siteId, locale);

    return result.match(
        data => {
            setMenuSuccess(true);
            setMenuError('');

            return data;
        },
        error => {
            setMenuError(error);

            return [];
        },
    );
});

export const wrappedFetchMenu = attach({
    source: $menuService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchMenu,
});

export const $isMenuLoading = wrappedFetchMenu.pending;

const updateMenu = createEffect(async (params: {
    service: IMenuService,
    siteId: string,
    locale: string,
    values: MenuItem[],
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editMenu(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Nav Bar updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            getMenu();

            return [];
        },
    );
});

export const wrappedUpdateMenu = attach({
    source: $menuService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateMenu,
});

export const $isMenuUpdating = wrappedUpdateMenu.pending;
