import { ReactElement } from 'react';

import {
    Layout,
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from 'features/Form';
import { OneParagraphAccordion } from 'features/OneParagraphAccordion';
import { ReactComponent as GoBack } from 'shared/components/Icons/go-back.svg';
import { ButtonKey, ButtonType } from 'models/Button';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';

import './TextComponent.scss';

interface EditTextComponentProps<Fields, Field extends string>
    extends Omit<MuiTextFieldProps<Fields, Field>, 'name' | 'onChange'> {
    name: Field;
    chain?: (number | string)[];
    layout: ReactElement | null;
}

interface TextComponentProps {
    initialValue?: string;
    name: string;
    chain?: (number | string)[];
    className?: string;
}

export const EditTextComponent = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    chain,
    layout,
    ...restProps
}: EditTextComponentProps<Fields, Field>) => {
    return (
        <EditQenericComponent
            layout={layout}
            quiz={
                <MuiTextField
                    id={name}
                    name={name}
                    chain={chain}
                    multiline
                    fullWidth
                    {...restProps}
                />
            }
        />
    );
};

export const TextH1 = ({ initialValue, name, chain, className, ...restProps }: TextComponentProps) => {
    return (
        <EditTextComponent
            layout={<h1 className={className}>{initialValue}</h1>}
            name={name}
            chain={chain}
            label='main heading'
            className={className}
            {...restProps}
        />
    );
};

export const TextH2 = ({ initialValue, name, chain, className }: TextComponentProps) => {
    return (
        <EditTextComponent
            layout={<h2 className={className}>{initialValue}</h2>}
            name={name}
            chain={chain}
            label='display heading (required)'
            className={className}
        />
    );
};

export const TextH3 = ({ initialValue = '', name, chain, className }: TextComponentProps) => {
    return (
        <EditTextComponent
            layout={<h3 className={className}>{initialValue}</h3>}
            name={name}
            chain={chain}
            label='third heading'
            className={className}
        />
    );
};

export const TextP = ({ initialValue, name, chain, className }: TextComponentProps) => {
    return (
        <EditTextComponent
            layout={<p className={className}>{initialValue}</p>}
            name={name}
            chain={chain}
            label='text block'
            className={className}
        />
    );
};

export const TextDiv = ({ initialValue, name, chain, className }: TextComponentProps) => {
    return (
        <EditQenericComponent
            quiz={<Layout name={name} chain={chain} className={className} />}
            layout={
                <div
                    className={className}
                    dangerouslySetInnerHTML={{ __html: initialValue ?? '' }}
                ></div>
            }
        />
    );
};
interface LinkComponentProps extends Omit<TextComponentProps, 'initialValue'> {
    initialValue: ButtonType;
    arrowIcon?: boolean;
    noteAboutLink?: string;
}

export const TextA = ({ initialValue, name, chain = [], className, noteAboutLink, arrowIcon = false }: LinkComponentProps) => {
    return (
        <EditQenericComponent
            layout={
                <a href={initialValue.link} className={className} target='_blank' rel='noreferrer'>
                    {initialValue.text}
                    {arrowIcon && <GoBack />}
                </a>
            }
            quiz={
                <div className='link'>
                    <div className='link__title'>Link</div>
                    <MuiTextField
                        id={name}
                        name={name}
                        chain={[...chain, ButtonKey.Text]}
                        multiline
                        fullWidth
                        label='link label'
                    />
                    <MuiTextField
                        id={name}
                        name={name}
                        chain={[...chain, ButtonKey.Link]}
                        multiline
                        fullWidth
                        label='target page/link'
                    />
                    {noteAboutLink && <span className='link__note'>{noteAboutLink}</span>}
                </div>
            }
        />
    );
};
