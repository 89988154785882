export const imageNameRegex = /[\w\d-_.]+$/ig;

export const externalLinkRegex = /^https:\/\//;

export const idFromLinkRegex = /[\d]+$/ig;

export const numberRegex = /[\d]+/;

export const googleMapLinkRegex = /^https:\/\/www.google.com\/maps\//;

export const googleMapLinkDirectionsRegex =  /^https:\/\/www.google.com\/maps\/dir\//;
