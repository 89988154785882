import { ITeamApi } from 'api/landing/team';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesTeam } from 'models/Values';

export interface ITeamService {
    hideTeam: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesTeam>>;
    getTeam: (siteId: string, locale: string) => Promise<RequestResult<ValuesTeam>>;
    editTeam: (siteId: string, locale: string, TeamValues: ValuesTeam) => Promise<RequestResult<ValuesTeam>>;
}

export class TeamService implements ITeamService {
    constructor(
        private readonly teamApi: ITeamApi,
        private readonly logger: Logger,
    ) { }

    public async hideTeam(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesTeam>> {
        try {
            const response = await this.teamApi.hideTeam(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide Team Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide Team');
        }
    }

    public async getTeam(siteId: string, locale: string): Promise<RequestResult<ValuesTeam>> {
        try {
            const response = await this.teamApi.getTeam(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Team Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Team Section');
        }
    }

    public async editTeam(siteId: string, locale: string, teamValues: ValuesTeam): Promise<RequestResult<ValuesTeam>> {
        try {
            const response = await this.teamApi.editTeam(siteId, locale, teamValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Team Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Team Section');
        }
    }
}
