import { useState, useEffect, useCallback, ElementType } from 'react';
import cn from 'classnames';

import { PictureProps, ImageProps } from 'shared/components';
import { externalLinkRegex } from 'shared/constants/constants';
import { getImageName } from 'shared/utils/image/getImageName';
import { UploadButton, useNestedField } from 'features/Form';

import { InfoButtonComponent } from '../IconButtonComponent';
import { desktopImageInfo, mobileImageInfo } from '../InfoComponent/constants';

import './ImageComponent.scss';

interface ImageQuizProps<_, Field>{
    name: Field;
    mobileName?: Field;
    chain?: (string | number)[];
    imgWidth: number;
    imgHeight: number;
    imgWidthMobile?: number;
    imgHeightMobile?: number;
    currentImage: ElementType;
    imgProps?: ImageProps | PictureProps;
    className?: string;
    classNameContainer?: string;
}

export function ImageQuiz<
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    mobileName,
    chain = [],
    imgWidth,
    imgHeight,
    imgWidthMobile,
    imgHeightMobile,
    currentImage: CurrentImage,
    imgProps,
    className,
    classNameContainer,
}: ImageQuizProps<Fields, Field>) {

    const { valueLocal, setFieldValueLocal } = useNestedField({ name, chain });

    const [url, setUrl] = useState<string>();
    const [urlMobile, setUrlMobile] = useState<string>(); //TODO make mobile preview

    const onClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }, []);

    useEffect(() => { //TODO avoid logic duplication
        const isValueLink = externalLinkRegex.test(valueLocal);

        if (isValueLink) {
            const imageName = getImageName(valueLocal);

            if (imageName) {
                setUrl(valueLocal);
                setFieldValueLocal(imageName);
            }
        }
    }, [setFieldValueLocal, valueLocal]);

    return (
        <div className={cn('image-quiz', classNameContainer)}>
            <CurrentImage
                { ...imgProps }
                width={imgWidth}
                height={imgHeight}
                imgWidthMobile={imgWidthMobile}
                imgHeightMobile={imgHeightMobile}
                className={className}
                image={url ?? valueLocal}
            />
            <div className='image-quiz__upload-button-block'>
                <div
                    className='image-quiz__upload-button'
                    onClick={onClick}
                >
                    <UploadButton
                        name={name}
                        chain={chain}
                        imgWidth={imgWidth}
                        imgHeight={imgHeight}
                        setUrl={setUrl}
                    />
                </div>
                {mobileName &&
                    <InfoButtonComponent
                        className='info__modal'
                        layout={desktopImageInfo}
                        isLoading={true}
                    />
                }
            </div>
            {imgWidthMobile && imgHeightMobile && mobileName &&
                <div className='image-quiz__upload-button-block image-quiz__upload-button-block_mobile'>
                    <div
                        className='image-quiz__upload-button'
                        onClick={onClick}
                    >
                        <UploadButton
                            name={mobileName}
                            chain={chain}
                            imgWidth={imgWidthMobile}
                            imgHeight={imgHeightMobile}
                            setUrl={setUrlMobile}
                            mobile={true}
                        />
                    </div>
                    <InfoButtonComponent
                        className='info__modal'
                        layout={mobileImageInfo}
                        isLoading={true}
                    />
                </div>
            }
        </div>
    );
}
