import { createEffect, attach } from 'effector';

import { IFooterService } from 'services/footer';
import { $footerService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { ValuesFooter } from 'models/Values/Blocks/Landing';

import { getFooterSection, setFooterSectionError } from './events';
import { emptyValuesFooter } from '../landing/constants';

const hideFooterSection = createEffect(async (params: {
    service: IFooterService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideFooter(siteId, locale, isHidden);

    return result.match(
        data => {
            setFooterSectionError('');

            return data;
        },
        error => {
            setFooterSectionError(error);

            return emptyValuesFooter;
        },
    );
});

export const wrappedHideFooter = attach({
    source: $footerService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideFooterSection,
});

export const $isFooterSectionHiding = wrappedHideFooter.pending;

const fetchFooterSection = createEffect(async (params: {
    service: IFooterService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getFooter(siteId, locale);

    return result.match(
        data => {
            setFooterSectionError('');

            return data;
        },
        error => {
            setFooterSectionError(error);

            return emptyValuesFooter;
        },
    );
});

export const wrappedFetchFooterSection = attach({
    source: $footerService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchFooterSection,
});

export const $isFooterSectionLoading = wrappedFetchFooterSection.pending;

export const updateFooter = createEffect(async (params: {
    service: IFooterService,
    siteId: string,
    locale: string,
    values: ValuesFooter,
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editFooter(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            getFooterSection();

            return emptyValuesFooter;
        },
    );
});

export const wrappedUpdateFooter = attach({
    source: $footerService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateFooter,
});

export const $isFooterSectionUpdating = wrappedUpdateFooter.pending;
