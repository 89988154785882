/* eslint-disable max-len */
export const googleMapInfo = `
    <ol>
        <li> Go to <a href='https://www.google.com/maps' target='_blank' rel='noreferrer'>Google maps</a>;</li>
        <li> Write address of hospital and press search button;</li>
        <li> Press menu button on left top;</li>
        <li> Choose "Share or embeded map";</li>
        <li> Change to "Embed a map";</li>
        <li> Choose a medium size;</li>
        <li> Press button "COPY HTML";</li>
        <li> Insert this link into this field;</li>
    </ol>
`;

export const multipleLocationsGoogleMapInfo = `
    <ol>
        <li> Go to <a href='https://www.google.com/maps' target='_blank' rel='noreferrer'>Google maps</a>;</li>
        <li> Make sure you're signed in. If not, click the Login button in the top-right corner;</li>
        <li> Press menu button on left top;</li>
        <li> Click ‘Your Places’, ‘Maps’ and then click  ‘CREATE MAP’ button;</li>
        <li> Give your map a title and description, then click ‘Save’ button;</li>
        <li> Pinpoint locations manually by clicking the marker icon below the search box and placing it directly onto the map, or search for locations using the search box;</li>
        <li> If you're searching using the search box and adding specific locations, a green marker will appear on the map and you should click the ‘+Add to map’ button;</li>
        <li> If you're adding locations manually by the marker icon, name the location and Save to add it to the map;</li>
        <li> Also, if you're adding locations manually you can add more information about the hospital and add URL to follow this link from the map;</li>
        <li> You can also change marker color;</li>
        <li> Repeat steps 7 and 9 for each location you wish to plot;</li>
        <li> Make sure your map is public on Google Maps. Click the ‘+Share’ button below the map name and make sure that ‘Anyone with this link can view’ is selected;</li>
        <li> Click the menu icon next to the map name and select the ‘Embed on my site’;</li>
        <li> Copy the following HTML;</li>
        <li> Insert this link into this field in format ‘https://www.google.com/maps..’;</li>
    </ol>
`;

export const getDirectionsInfo = `
    <ol>
        <li> Go to <a href='https://www.google.com/maps' target='_blank' rel='noreferrer'>Google maps</a>;</li>
        <li> Write address of hospital and press directions button;</li>
        <li> Copy the link from browser;</li>
        <li> Insert this link into this field;</li>
    </ol>
`;

export const desktopImageInfo = 'Upload image for desktop view';

export const mobileImageInfo = 'Upload image for mobile view';

export const dropdownInfo = 'If dropdown is empty, go to "Support Pages", add page and check if field "linkable name (optional)" is not empty';
