import { useCallback, memo } from 'react';
import {
    FormControlLabel,
    Switch as MuiSwitch,
    SwitchProps as MuiMuiSwitchProps,
} from '@mui/material';
import cn from 'classnames';

import { SwitchColors } from './SwitchColors';

import './Switch.scss';

export interface SwitchProps extends Omit<MuiMuiSwitchProps, 'onChange' | 'color'> {
    color?: SwitchColors;
    label?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
}

const inputProps = { 'aria-label': 'controlled' };

export const Switch = memo(({
    className,
    label,
    checked,
    onChange,
    color = SwitchColors.Blue,
    ...restProps
}: SwitchProps) => {
    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event?.target.checked;

        onChange(isChecked);
    }, [onChange]);

    return (
        <FormControlLabel
            label={label}
            control={
                <MuiSwitch
                    {...restProps}
                    className={cn(
                        'mmc-switch',
                        { 'mmc-switch_green': color === SwitchColors.Green },
                        { 'mmc-switch_orange': color === SwitchColors.Orange },
                        { 'mmc-switch_turquoise': color === SwitchColors.Turquoise },
                        className,
                    )}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={inputProps}
                />
            }
        />
    );
});
