import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { IDoctorsInfoService } from 'services/doctorsInfo';
import { $doctorsInfoService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { ValuesDoctors } from 'models/Values';
import { EmployeeRoleType } from 'models/Employee';
import { $params } from 'store/params';

import { getDoctorsInfo, setAdvancedProvidersInfoSuccess, setAllDoctorsInfoSuccess, setPhysiciansInfoSuccess } from './events';
import { emptyValuesDoctorsInfo } from './constants';

const fetchDoctorsInfo = createEffect(async (params: {
    service: IDoctorsInfoService,
    siteId: string,
    locale: string,
    type: EmployeeRoleType | null,
}) => {
    const { service, siteId, locale, type } = params;

    const result = await service.getDoctorsInfo(siteId, locale, type);

    return result.match(
        data => {
            if (type === EmployeeRoleType.Senior) {
                setPhysiciansInfoSuccess(true);
            } else if (type === EmployeeRoleType.Junior) {
                setAdvancedProvidersInfoSuccess(true);
            } else if (type === EmployeeRoleType.All) {
                setAllDoctorsInfoSuccess(true);
            }

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return emptyValuesDoctorsInfo;
        },
    );
});

export const wrappedFetchDoctorsInfo = attach({
    source: $doctorsInfoService,
    mapParams: (
        { siteId, locale, type }: { siteId: string, locale: string, type: EmployeeRoleType | null },
        service,
    ) => ({ service, siteId, locale, type }),
    effect: fetchDoctorsInfo,
});

export const $isDoctorsInfoLoading = wrappedFetchDoctorsInfo.pending;

const updateDoctorsInfo = createEffect(async (params: {
    service: IDoctorsInfoService,
    siteId: string,
    locale: string,
    values: ValuesDoctors,
    type: EmployeeRoleType | null,
}) => {
    const { service, siteId, locale, type, values } = params;

    const result = await service.editDoctorsInfo(siteId, locale, values, type);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: ' updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getDoctorsInfo({ ...params, type });

            return emptyValuesDoctorsInfo;
        },
    );
});

export const wrappedUpdateDoctorsInfo = attach({
    source: $doctorsInfoService,
    mapParams: ({ siteId, locale, values, type }, service) => ({ siteId, locale, type, values, service }),
    effect: updateDoctorsInfo,
});

export const $isDoctorsInfoUpdating = wrappedUpdateDoctorsInfo.pending;
