import { ElementType } from 'react';

import { ButtonType } from 'models/Button';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';
import { EditFooter } from './EditListNodes/EditListNodes';
import { ListNodes } from './ListNodes/ListNodes';

import './FooterComponent.scss';

interface EditListNodesSectionProps<_, Field extends string> {
    list: ButtonType[];
    name: Field;
    listElement: ElementType;
}

export const EditFooterSection = <
    Fields extends { [key in Field]: ButtonType[] },
    Field extends string,
>({
    list,
    name,
    listElement: ListElement,
}: EditListNodesSectionProps<Fields, Field>) => {

    return (
        <EditQenericComponent
            layout={<ListNodes listElement={ListElement} list={list} />}
            quiz={<EditFooter listElement={ListElement} name={name} />}
        />
    );
};
