import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { ISolutionService } from 'services/landing/solution';
import { notify } from 'shared/utils/notification';

import { $solutionService } from '../../services';
import { $params } from '../../params';
import { getSolutionSection, setSolutionError } from './events';
import { emptyValuesSolution } from '../constants';
import { ValuesSolution } from 'models/Values/Blocks/Landing/ValuesWithSlider';

const hideSolutionSection = createEffect(async (params: {
    service: ISolutionService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideSolution(siteId, locale, isHidden);

    return result.match(
        data => {
            setSolutionError('');

            return data;
        },
        error => {
            setSolutionError(error);

            return emptyValuesSolution;
        },
    );
});

export const wrappedHideSolution = attach({
    source: $solutionService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideSolutionSection,
});

export const $isSolutionSectionHiding = wrappedHideSolution.pending;

const fetchSolutionSection = createEffect(async (params: {
    service: ISolutionService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getSolution(siteId, locale);

    return result.match(
        data => {
            setSolutionError('');

            return data;
        },
        error => {
            setSolutionError(error);

            return emptyValuesSolution;
        },
    );
});

export const wrappedFetchSolutionSection = attach({
    source: $solutionService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchSolutionSection,
});

export const $isSolutionSectionLoading = wrappedFetchSolutionSection.pending;

const updateSolution = createEffect(async (params: {
    service: ISolutionService,
    siteId: string,
    locale: string,
    values: ValuesSolution,
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editSolution(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getSolutionSection(params);

            return emptyValuesSolution;
        },
    );
});

export const wrappedUpdateSolution = attach({
    source: $solutionService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateSolution,
});

export const $isSolutionSectionUpdating = wrappedUpdateSolution.pending;
