import { useState, useCallback, memo, useMemo } from 'react';
import cn from 'classnames';

import { ImageLazyLoading } from 'shared/utils/hoc/ImageLazyLoading';

import './Image.scss';

export interface ImageProps {
    className?: string;
    containerClassName?: string;
    width?: number;
    height?: number;
    onClick?: () => void;
    image?: string;
    alt: string;
}

export const Image = memo(({
    className,
    containerClassName,
    width,
    height,
    onClick,
    image = '',
    alt,
}: ImageProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const style = useMemo(() => ({ opacity: isLoaded ? 1 : 0 }), [isLoaded]);

    const onLoad = useCallback(() => {
        setIsLoaded(true);
    }, []);

    return (
        <ImageLazyLoading
            isLoaded={isLoaded}
            containerClassName={containerClassName}
            skeletonClassName={className}
            width={width}
            height={height}
        >
            <img
                className={cn('image', className)}
                src={image}
                alt={alt}
                onClick={onClick}
                onLoad={onLoad}
                style={style}
            />
        </ImageLazyLoading>
    );
});
