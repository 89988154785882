import { combine } from 'effector';

import { $fetch } from 'store/fetch/store';
import { $logger } from 'store/logger';
import { AuthApi } from 'api/auth';
import { EmployeeApi } from 'api/employee';
import { LookUpsApi } from 'api/lookUps';
import { HeaderApi } from 'api/landing/header';
import { TeamApi } from 'api/landing/team';
import { DoctorsInfoApi } from 'api/doctorsInfo';
import { OtherPageApi } from 'api/other';
import { ImageApi } from 'api/image';
import { MenuApi } from 'api/menu';
import { LandingApi } from 'api/landing';
import { SiteApi } from 'api/site';
import { LanguageApi } from 'api/language';
import { ExternalLinkApi } from 'api/externalLink';
import { MediaApi } from 'api/media';
import { ProjectApi } from 'api/project';
import { AuthService } from 'services/auth';
import { AdminEmployeeService } from 'services/employee';
import { LookUpsService } from 'services/lookUp';
import { HeaderService } from 'services/landing/header';
import { TeamService } from 'services/landing/team';
import { DoctorsInfoService } from 'services/doctorsInfo';
import { OtherService } from 'services/other';
import { ImageService } from 'services/image';
import { MenuService } from 'services/menu';
import { LandingService } from 'services/landing';
import { SiteService } from 'services/site';
import { LanguageService } from 'services/language';
import { ExternalLinkService } from 'services/externalLink';
import { MediaService } from 'services/media';
import { ProjectService } from 'services/project';
import { VideoService } from 'services/landing/video';
import { VideoApi } from 'api/landing/video';
import { SolutionService } from 'services/landing/solution';
import { SolutionApi } from 'api/landing/solution';
import { AdminContactService } from 'services/contact';
import { ContactApi } from 'api/contactus';
import { ImageBlockService } from 'services/landing/imageBlock';
import { ImageBlockApi } from 'api/landing/imageBlock';
import { TextBlockService } from 'services/landing/textBlock';
import { TextBlockApi } from 'api/landing/textBlock';
import { PublicationService } from 'services/landing/publicaions';
import { PublicationsApi } from 'api/landing/publications';
import { FooterService } from 'services/footer';
import { FooterApi } from 'api/footer';

export const $authService = combine($fetch, $logger).map(([fetch, logger]) => new AuthService(
    new AuthApi(fetch),
    logger,
));

export const $employeeService = combine($fetch, $logger).map(([fetch, logger]) => new AdminEmployeeService(
    new EmployeeApi(fetch),
    logger,
));

export const $lookUpsService = combine($fetch, $logger).map(([fetch, logger]) => new LookUpsService(
    new LookUpsApi(fetch),
    logger,
));

export const $headerService = combine($fetch, $logger).map(([fetch, logger]) => new HeaderService(
    new HeaderApi(fetch),
    logger,
));

export const $teamService = combine($fetch, $logger).map(([fetch, logger]) => new TeamService(
    new TeamApi(fetch),
    logger,
));

export const $doctorsInfoService = combine($fetch, $logger).map(([fetch, logger]) => new DoctorsInfoService(
    new DoctorsInfoApi(fetch),
    logger,
));

export const $otherService = combine($fetch, $logger).map(([fetch, logger]) => new OtherService(
    new OtherPageApi(fetch),
    logger,
));

export const $imageService = combine($fetch, $logger).map(([fetch, logger]) => new ImageService(
    new ImageApi(fetch),
    logger,
));

export const $menuService = combine($fetch, $logger).map(([fetch, logger]) => new MenuService(
    new MenuApi(fetch),
    logger,
));

export const $landingService = combine($fetch, $logger).map(([fetch, logger]) => new LandingService(
    new LandingApi(fetch),
    logger,
));

export const $siteService = combine($fetch, $logger).map(([fetch, logger]) => new SiteService(
    new SiteApi(fetch),
    logger,
));

export const $languageService = combine($fetch, $logger).map(([fetch, logger]) => new LanguageService(
    new LanguageApi(fetch),
    logger,
));

export const $externalLinkService = combine($fetch, $logger).map(([fetch, logger]) => new ExternalLinkService(
    new ExternalLinkApi(fetch),
    logger,
));

export const $mediaService = combine($fetch, $logger).map(([fetch, logger]) => new MediaService(
    new MediaApi(fetch),
    logger,
));

export const $projectService = combine($fetch, $logger).map(([fetch, logger]) => new ProjectService(
    new ProjectApi(fetch),
    logger,
));

export const $videoService = combine($fetch, $logger).map(([fetch, logger]) => new VideoService(
    new VideoApi(fetch),
    logger,
));

export const $solutionService = combine($fetch, $logger).map(([fetch, logger]) => new SolutionService(
    new SolutionApi(fetch),
    logger,
));

export const $contactService = combine($fetch, $logger).map(([fetch, logger]) => new AdminContactService(
    new ContactApi(fetch),
    logger,
));

export const $imageBlockService = combine($fetch, $logger).map(([fetch, logger]) => new ImageBlockService(
    new ImageBlockApi(fetch),
    logger,
));

export const $textBlockService = combine($fetch, $logger).map(([fetch, logger]) => new TextBlockService(
    new TextBlockApi(fetch),
    logger,
));

export const $publicationsService = combine($fetch, $logger).map(([fetch, logger]) => new PublicationService(
    new PublicationsApi(fetch),
    logger,
));

export const $footerService = combine($fetch, $logger).map(([fetch, logger]) => new FooterService(
    new FooterApi(fetch),
    logger,
));

