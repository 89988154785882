import { createEvent } from 'effector';

import { Parameters } from 'models/Auth';
import { ValuesOther } from 'models/Values';

export const getOther = createEvent<{ id: string }>();
export const resetOther = createEvent();

export const getOtherPages = createEvent();
export const resetOtherPages = createEvent();

export const updateOther = createEvent<{ siteId: string; locale: string; id: string; values: ValuesOther }>();

export const deleteOtherPage = createEvent<string>();
