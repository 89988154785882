import { PagedEmployees } from 'models/Employee';
import { InfiniteScrollEmployees } from 'models/Employee/PagedEmployees';

export const initialPagedEmployees: PagedEmployees = {
    totalPages: 1,
    items: [],
    currentPage: 0,
    hasPreviousPage: false,
    hasNextPage: false,
};

export const initialInfiniteScrollEmployees: InfiniteScrollEmployees = {
    items: [],
    totalPages: 1,
    pageSize: 5,
    lastFetched: 0,
    hasNextPage: true,
};

export const personalPronounsSelectValues = [
    { value: 0, displayName: '' },
    { value: 1, displayName: 'Male' },
    { value: 2, displayName: 'Female' },
];
