import { FormControl, FormHelperText, InputLabel } from '@mui/material';

import {
    Select as SelectControl,
    SelectProps as SelectControlProps,
} from 'shared/adminComponents';

import { useNestedField } from '../useNestedField';

import './Select.scss';

interface SelectProps<_, Field extends string>
    extends Omit<SelectControlProps, 'onChange' | 'name'> {
    name: Field;
    chain?: (string | number)[];
}

export const Select = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    chain = [],
    items,
    className,
    label,
    variant = 'outlined',
    ...restProps
}: SelectProps<Fields, Field>) => {

    const { valueLocal, setFieldValueLocal, field, helperText, handleBlur } = useNestedField({ name, chain });

    return (
        <FormControl variant={variant} className={`${className} custom-textfield-control`} error={!!helperText}>
            <InputLabel id={name} className='form-select__label'>{label}</InputLabel>
            <SelectControl
                id={name}
                name={field}
                native
                items={items}
                variant={variant}
                value={valueLocal ?? ''}
                onChange={setFieldValueLocal}
                error={!!helperText}
                onBlur={handleBlur}
                label={label}
                size='small'
                {...restProps}
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};
