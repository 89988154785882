import { ElementType, useState } from 'react';

import { SlideType } from 'models/Carousel';
import { Employee } from 'models/Employee';

import { Carousel as DesktopCarousel } from './desktop';
import { ModalSlider } from './mobile';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function isSlideType(slides: (string | SlideType | Employee)[]): slides is SlideType[] {
    return (slides as SlideType[])[0].bodyText !== undefined;
}

export interface CarouselProps {
    slides: (SlideType | Employee | string)[];
    slide: ElementType;
    modalSlide?: ElementType;
    slidesPerView?: number | 'auto';
    initialSlide?: number | null;
    mobileClassName?: string;
}

export const Carousel = ({
    slides,
    slide: Slide,
    modalSlide: ModalSlide,
    mobileClassName = '',
    slidesPerView,
    initialSlide = 0,
}: CarouselProps) => {
    const [indexOpen, setIndexOpen] = useState<number | null>(null);

    const onClick = (index: number) => setIndexOpen(index);

    return (
        <>
            <DesktopCarousel
                slidesPerView={slidesPerView}
                initialSlide={initialSlide}
                slides={slides}
                slide={Slide}
                onClick={onClick}
            />
            {ModalSlide && isSlideType(slides) &&
                <ModalSlider
                    slides={slides}
                    slide={ModalSlide}
                    indexOpen={indexOpen}
                    setIndexOpen={setIndexOpen}
                    className={mobileClassName}
                />
            }
        </>
    );
};
