import { forward, sample } from 'effector';

import { $siteId } from 'store/params';

import {
    $site,
    $allSites,
    $userSites,
} from './store';
import { fetchAllSites, wrappedFetchUserSites, wrappedFetchSite, wrappedUpdateMenuPosition, wrappedCloneSite } from './effects';
import {
    cloneSite,
    getAllSites,
    getUserSites,
    updateMenuPosition,
} from './events';

forward({
    from: getAllSites,
    to: fetchAllSites,
});

forward({
    from: fetchAllSites.doneData,
    to: $allSites,
});

forward({
    from: getUserSites,
    to: wrappedFetchUserSites,
});

forward({
    from: wrappedFetchUserSites.doneData,
    to: $userSites,
});

sample({
    clock: $siteId,
    filter: siteId => !!siteId,
    target: wrappedFetchSite,
});

forward({
    from: wrappedFetchSite.doneData,
    to: $site,
});

sample({
    clock: updateMenuPosition,
    source: $siteId,
    fn: (siteId, menuPosition) => ({ siteId, menuPosition }),
    target: wrappedUpdateMenuPosition,
});

sample({
    clock: cloneSite,
    target: wrappedCloneSite,
});

sample({
    clock: wrappedCloneSite.doneData,
    filter: isSuccessful => isSuccessful,
    target: getUserSites,
});
