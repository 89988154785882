import { ButtonType } from 'models/Button';
import { getKey } from 'shared/utils/generateKey';

export const getInitialListElement = (): ButtonType => {
    return {
        id: getKey(),
        link: '',
        text: '',
    };
};
