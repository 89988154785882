import { SlideType } from 'models/Carousel';
import { getKey } from 'shared/utils/generateKey';

export const getInitialSwiperElement = (): SlideType => {
    return {
        id: getKey(),
        bodyText: '',
        image: '',
        imageFooterText: '',
        bodyHeader: '',
        sign: '',
    };
};
