import { createEffect, attach } from 'effector';

import { IAdminEmployeeService } from 'services/employee';
import { $employeeService } from 'store/services';
import { $params } from 'store/params';
import { notify } from 'shared/utils/notification';
import { Employee, EmployeeListSettings } from 'models/Employee';

import { initialPagedEmployees } from './constants';

export const fetchPagedEmployees = createEffect(async (params: {
    service: IAdminEmployeeService,
    listSettings: EmployeeListSettings,
    siteId: string,
    locale: string,
}) => {
    const { service, listSettings, siteId, locale } = params;

    const result = await service.getPagedEmployees(siteId, locale, listSettings);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return initialPagedEmployees;
        },
    );
});

export const wrappedFetchPagedEmployees = attach({
    source: $employeeService,
    mapParams: (
        { siteId, locale, listSettings }: { siteId: string, locale: string, listSettings: EmployeeListSettings },
        service,
    ) => ({ service, siteId, locale, listSettings }),
    effect: fetchPagedEmployees,
});

export const $arePagedEmployeesLoading = wrappedFetchPagedEmployees.pending;

export const fetchAllEmployees = createEffect(async (params: {
    service: IAdminEmployeeService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getAllEmployees(siteId, locale);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return [];
        },
    );
});

export const wrappedFetchAllEmployees = attach({
    source: $employeeService,
    mapParams: (
        { siteId, locale }: { siteId: string, locale: string },
        service,
    ) => ({ service, siteId, locale }),
    effect: fetchAllEmployees,
});

export const $areAllEmployeesLoading = wrappedFetchAllEmployees.pending;

const fetchEmployeesByRole = createEffect(async (params: { //remove?
    service: IAdminEmployeeService,
    roleType: string,
}) => {
    const { service, roleType } = params;

    const result = await service.getEmployeesByRoleType(roleType);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedFetchEmployeesByRole = attach({
    source: $employeeService,
    mapParams: (roleType: string, service) => ({ service, roleType }),
    effect: fetchEmployeesByRole,
});

export const $areEmployeesLoading = wrappedFetchEmployeesByRole.pending;

const fetchEmployee = createEffect(async (params: {
    service: IAdminEmployeeService,
    employeeId: number,
}) => {
    const { service, employeeId } = params;

    const result = await service.getEmployeeById(employeeId);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedFetchEmployee = attach({
    source: $employeeService,
    mapParams: (employeeId: number, service) => ({ service, employeeId }),
    effect: fetchEmployee,
});

export const $isEmployeeLoading = fetchEmployee.pending;

const createEmployee = createEffect(async (params: {
    service: IAdminEmployeeService,
    employee: Employee,
    siteId: string,
    locale: string,
}) => {
    const { service, employee, siteId, locale } = params;

    const result = await service.createEmployee(siteId, locale, employee);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Employee saved successfully.',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedCreateEmployee = attach({
    source: {
        service: $employeeService,
        parameters: $params,
    },
    mapParams: (params: { employee: Employee }, states) => ({
        ...states.parameters,
        ...params,
        service: states.service,
    }),
    effect: createEmployee,
});

const updateEmployee = createEffect(async (params: {
    service: IAdminEmployeeService,
    employee: Employee,
    id: number;
    siteId: string,
    locale: string,
}) => {
    const { service, id, employee, siteId, locale } = params;

    const result = await service.editEmployeeById(id, employee, siteId, locale);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Employee updated successfully.',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedUpdateEmployee = attach({
    source: {
        service: $employeeService,
        parameters: $params,
    },
    mapParams: (params: { employee: Employee, id: number }, states) => ({
        ...states.parameters,
        ...params,
        service: states.service,
    }),
    effect: updateEmployee,
});

export const deleteEmployee = attach({
    source: $employeeService,
    mapParams: ({ employeeId, siteId, locale }, service) => ({ employeeId, siteId, locale, service }),
    effect: createEffect(async (params: {
        employeeId: number,
        service: IAdminEmployeeService,
        siteId: string,
        locale: string,
    }) => {
        const { service, employeeId, siteId, locale } = params;

        const result = await service.deleteEmployeeById(employeeId, siteId, locale);

        return result.match(
            () => {
                notify({
                    type: 'info',
                    message: 'Employee deleted successfully.',
                });

                return true;
            },
            error => {
                notify({
                    type: 'danger',
                    message: error,
                });

                return false;
            },
        );
    }),
});

export const $isDeletingEmployee = deleteEmployee.pending;
