import './ErrorMessage.scss';

export enum Messages {
    Error = 'Failed to load data. Please, refresh page',
    NoLanguage = 'No languages added. Please, go to Start Page and add language',
}

export const ErrorMessage = ({ text }: { text: string }) => (
    <div className='error-message'>
        {text}
    </div>
);
