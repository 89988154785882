import { createEffect, attach, combine } from 'effector';

import { IAuthService } from 'services/auth/authService';
import { StorageProvider } from 'shared/utils/storage';
import { $authService } from 'store/services/store';
import { TokenInfo } from 'models/Auth';
import { RequestResult } from 'shared/utils/requestResult';
import { AuthType } from 'models/Auth/TokenInfo';

import { setSignInErrorMessage, setIsAuthorized } from './events';
import { $storageProvider } from './store';

const signIn = createEffect(async (params: {
    login: string;
    password: string;
    authService: IAuthService;
    storageProvider: StorageProvider;
}) => {
    const { login, password, authService, storageProvider } = params;

    if (login && password) {
        const result = await authService.signIn(login, password);

        return result.match(
            data => {
                data.SignInType = AuthType.Default;
                storageProvider.saveAuthData(data);
                setIsAuthorized(true);
                setSignInErrorMessage('');

                return data;
            },
            error => {
                error
                    ? setSignInErrorMessage(error)
                    : setSignInErrorMessage('Login or password are not correct');

                return null;
            },
        );
    } else {
        setSignInErrorMessage('Login or password are empty');

        return null;
    }
});

export const wrappedSignIn = attach({
    source: combine($authService, $storageProvider),
    mapParams: ({ login, password }, [authService, storageProvider]) => ({ authService, storageProvider, login, password }),
    effect: signIn,
});

export const $isSignInLoading = wrappedSignIn.pending;

const saveTokenInfo = createEffect((params: {
    tokenInfo: TokenInfo | null;
    storageProvider: StorageProvider;
}) => {
    const { tokenInfo, storageProvider } = params;
    if (tokenInfo) {
        storageProvider.saveAuthData(tokenInfo);
    }
});

export const wrappedSaveTokenInfo = attach({
    source: $storageProvider,
    mapParams: (tokenInfo: TokenInfo | null, storageProvider) => ({ storageProvider, tokenInfo }),
    effect: saveTokenInfo,
});

const signInWithOkta = createEffect(async (params: {
    oktaAccessToken: string;
    oktaIdToken: string;
    authService: IAuthService;
    storageProvider: StorageProvider;
}) => {
    const { oktaAccessToken, oktaIdToken, authService, storageProvider } = params;

    if (oktaAccessToken && oktaIdToken) {
        const result = await authService.signInWithOkta(oktaAccessToken, oktaIdToken);

        return result.match(
            data => {
                data.SignInType = AuthType.Okta;
                storageProvider.saveAuthData(data);
                setIsAuthorized(true);
                setSignInErrorMessage('');

                return data;
            },
            error => {
                error
                    ? setSignInErrorMessage(error)
                    : setSignInErrorMessage('Failed to sign in');

                return null;
            },
        );
    } else {
        setSignInErrorMessage('Failed to sign in');

        return null;
    }
});

export const wrappedSignInWithOkta = attach({
    source: combine($authService, $storageProvider),
    mapParams: ({ oktaAccessToken, oktaIdToken }, [authService, storageProvider]) => (
        { oktaAccessToken, oktaIdToken, authService, storageProvider }
    ),
    effect: signInWithOkta,
});

export const $isSignInWithOktaLoading = wrappedSignInWithOkta.pending;

const oktaLogout = createEffect(async (params: {
    tokenInfo: TokenInfo | null;
    authService: IAuthService;
}) => {
    const { tokenInfo, authService } = params;

    if (tokenInfo) {
        const result = await authService.oktaLogout(tokenInfo);

        return result.data!;
    } else {
        setSignInErrorMessage('Token is not exists');

        return null;
    }
});

export const wrappedLogoutWithOkta = attach({
    source: combine($authService),
    mapParams: (tokenInfo : TokenInfo | null, [authService]) => (
        { tokenInfo, authService }
    ),
    effect: oktaLogout,
});

const logout = createEffect(async (params: {
    tokenInfo: TokenInfo | null;
    authService: IAuthService;
}) => {
    const { tokenInfo, authService } = params;

    if (tokenInfo) {
        const result = await authService.logout(tokenInfo);

        return result.isSuccess
            ? RequestResult.createSuccessful(true)
            : RequestResult.createUnsuccessful('Token is not exists');
    } else {
        setSignInErrorMessage('Token is not exists');

        return null;
    }
});

export const wrappedLogout = attach({
    source: combine($authService),
    mapParams: (tokenInfo : TokenInfo | null, [authService]) => (
        { tokenInfo, authService }
    ),
    effect: logout,
});
