import { HttpMethods } from 'api/constants';
import { TokenInfo } from 'models/Auth';

import { IAuthApi } from './interfaces';
import { AUTH_URL } from './constants';

export class AuthApi implements IAuthApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async signIn(email: string, password: string) {
        const response = await this.fetch(`${AUTH_URL}/signin`, {
            method: HttpMethods.Post,
            headers: {
                'Content-Type': 'application/json',
                'X-IdentityProvider': 'default',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
        });

        return response;
    }

    public async refreshToken(token: TokenInfo) {
        const response = await this.fetch(`${AUTH_URL}/refreshtoken`, {
            method: HttpMethods.Post,
            headers: {
                'Content-Type': 'application/json',
                'X-IdentityProvider': 'default',
                Authorization: `Bearer ${token.Token}`,
            },
            credentials: 'include',
        });

        return response;
    }

    public async signInWithOkta(oktaAccessToken: string, oktaIdToken: string) {
        const response = await this.fetch(`${AUTH_URL}/signin`, {
            method: HttpMethods.Post,
            headers: {
                'Content-Type': 'application/json',
                'X-IdentityProvider': 'okta',
            },
            credentials: 'include',
            body: JSON.stringify({ oktaAccessToken, oktaIdToken }),
        });

        return response;
    }

    public async refreshOktaToken(token: TokenInfo) {
        const response = await this.fetch(`${AUTH_URL}/refreshToken`, {
            method: HttpMethods.Post,
            headers: {
                'Content-Type': 'application/json',
                'X-IdentityProvider': 'okta',
                Authorization: `Bearer ${token.Token}`,
            },
            credentials: 'include',
        });

        return response;
    }

    public async oktaLogout(token: TokenInfo) {
        const response = await this.fetch(`${AUTH_URL}/signout`, {
            method: HttpMethods.Post,
            headers: {
                'Content-Type': 'application/json',
                'X-IdentityProvider': 'okta',
            },
            credentials: 'include',
            body: JSON.stringify(token),
        });

        return response;
    }

    public async logout(token: TokenInfo) {
        const response = await this.fetch(`${AUTH_URL}/signout`, {
            method: HttpMethods.Post,
            headers: {
                'Content-Type': 'application/json',
                'X-IdentityProvider': 'default',
            },
            credentials: 'include',
            body: JSON.stringify(token),
        });

        return response;
    }
}
