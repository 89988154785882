import { IDoctorsInfoApi } from 'api/doctorsInfo';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesDoctors } from 'models/Values';
import { EmployeeRoleType } from 'models/Employee';

export interface IDoctorsInfoService {
    getDoctorsInfo: (siteId: string, locale: string, type: EmployeeRoleType | null) => Promise<RequestResult<ValuesDoctors>>;
    editDoctorsInfo: (
        siteId: string,
        locale: string,
        doctorsInfoValues: ValuesDoctors,
        type: EmployeeRoleType | null,
    ) => Promise<RequestResult<ValuesDoctors>>;
}

export class DoctorsInfoService implements IDoctorsInfoService {
    constructor (
        private readonly DoctorsInfoApi: IDoctorsInfoApi,
        private readonly logger: Logger,
    ) { }

    public async getDoctorsInfo(
        siteId: string,
        locale: string,
        type: EmployeeRoleType | null,
    ): Promise<RequestResult<ValuesDoctors>>  {
        try {
            const response = await this.DoctorsInfoApi.getDoctorsInfo(siteId, locale, type);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get DoctorsInfo Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get DoctorsInfo');
        }
    }

    public async editDoctorsInfo(
        siteId: string,
        locale: string,
        DoctorsInfoValues: ValuesDoctors,
        type: EmployeeRoleType | null,
    ): Promise<RequestResult<ValuesDoctors>> {
        try {
            const response = await this.DoctorsInfoApi.editDoctorsInfo(siteId, locale, DoctorsInfoValues, type);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit DoctorsInfo Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit DoctorsInfo');
        }
    }
}
