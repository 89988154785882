import { createStore, combine } from 'effector';

import { Employee, EmployeeRoleType, PagedEmployees } from 'models/Employee';
import { InfiniteScrollEmployees } from 'models/Employee/PagedEmployees';

import { initialInfiniteScrollEmployees, initialPagedEmployees } from './constants';
import { $areAllEmployeesLoading, $isEmployeeLoading } from './effects';

export const $employee = createStore<Employee | null>(null);
export const $employeeId = createStore<number | null>(null);

export const $allEmployees = createStore<Employee[]>([]);

export const $employeesCurrentPage = createStore<number>(0);
export const $pagedEmployees = createStore<PagedEmployees>({ ...initialPagedEmployees });

export const $physiciansCurrentPage = createStore<number>(0);
export const $infiniteScrollPhysicians = createStore<InfiniteScrollEmployees>({ ...initialInfiniteScrollEmployees });

export const $advancedProvidersCurrentPage = createStore<number>(0);
export const $infiniteScrollAdvancedProviders = createStore<InfiniteScrollEmployees>({ ...initialInfiniteScrollEmployees });

export const $currentFetchedEmployees = createStore<PagedEmployees>({ ...initialPagedEmployees });
export const $currentRole = createStore<EmployeeRoleType | null>(null);

export const $allEmployeesGetStatus = combine({
    areEmployeesLoading: $areAllEmployeesLoading,
    allEmployees: $allEmployees,
});

export const $employeeStatus = combine({
    isEmployeeLoading: $isEmployeeLoading,
    employee: $employee,
});
