import { useState, useRef, useEffect, useCallback, useMemo, SetStateAction, Dispatch, KeyboardEvent } from 'react';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import {
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from 'shared/adminComponents/TextField';
import { Loader } from 'shared/components/Loader';
import { UserSearchItem } from 'models/Select';
import { arrayContainsAnotherArray } from 'shared/utils/array';
import { compareOccurrenceIndexes } from 'shared/utils/string';
import { getFullName } from 'shared/utils/employee/employee';
import { Employee } from 'models/Employee';
import { TeamKeys, ValuesTeam } from 'models/Values';

import './Search.scss';
import { useNestedField } from '../useNestedField';

interface SearchProps extends Omit<MuiTextFieldProps, 'onChange' | 'loading'> {
    name: TeamKeys;
    initialValue: Employee | undefined;
    initialItems: Employee[];
    loading: boolean;
    setEmployee: Dispatch<SetStateAction<Employee | undefined>>;
}

export function Search({
    name,
    initialValue,
    initialItems,
    loading,
    setEmployee,
    ...restProps
}: SearchProps) {
    const { errors, touched, setFieldValue, handleBlur } = useFormikContext<ValuesTeam>();

    const hasError = errors[name] as string ?? '';

    const searchRef = useRef<HTMLDivElement>(null);
    const [cursor, setCursor] = useState(-1);
    const [inputValue, setInputValue] = useState<string>('');
    const [items, setItems] = useState<UserSearchItem[]>([]);
    const [isListVisible, setIsListVisible] = useState<boolean>(false);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (!event.composedPath().includes(searchRef.current!)) {
                setIsListVisible(false);
            }
        };

        if (typeof initialValue?.id === 'number') {
            setInputValue(getFullName(initialValue));
            setFieldValue(name, initialValue?.id);
        }

        document.body.addEventListener('mousedown', handleOutsideClick, { passive: true });

        return () => document.body.removeEventListener('mousedown', handleOutsideClick);
    }, [initialValue, name, setFieldValue]);

    useEffect(() => { //check keyboard navigation, animation onclick
        const element = document.querySelector('.ed-search__list-element_highlighted');
        element && element.scrollIntoView();
    }, [cursor]);

    const elements = useMemo(() => {
        return initialItems.map(initialItem => ({
            ...initialItem,
            value: initialItem.id!,
            displayName: getFullName(initialItem),
        }));
    }, [initialItems]);

    useEffect(() => {
        if (inputValue.length) {
            const inputValueArray = inputValue.toLowerCase().split(' ');

            const filteredElements = elements
                .filter(element => arrayContainsAnotherArray(element.displayName.toLocaleLowerCase().split(' '), inputValueArray))
                .sort((a, b) => compareOccurrenceIndexes(a.displayName, b.displayName, inputValue));

            filteredElements.length === 1 && inputValue === filteredElements[0].displayName
                ? setItems([])
                : setItems(filteredElements);
        } else {
            setItems([]);
        }
    }, [elements, initialItems, inputValue]);

    const handleClick = useCallback((item: UserSearchItem) => {
        setInputValue(item.displayName);
        setFieldValue(name, item.value);
        setFieldValue('director', item);
        setEmployee(item);
        setIsListVisible(false);
    }, [name, setEmployee, setFieldValue]);

    const keyBoardNavigation = useCallback((event: KeyboardEvent) => {
        const clearInput = () => {
            setInputValue('');
            setItems([]);
        };

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setCursor(prevCursor => prevCursor < items.length - 1 ? prevCursor + 1 : prevCursor);
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setCursor(prevCursor => prevCursor > 0 ? prevCursor - 1 : 0);
        } else if (event.key === 'Escape') {
            clearInput();
        } else if (event.key === 'Enter' && cursor >= 0) {
            const item = items[cursor];
            handleClick(item);
        }
    }, [cursor, handleClick, items]);

    const handleFocus = useCallback(() => {
        setIsListVisible(true);
    }, []);

    const handleChange = useCallback((text: string) => {
        setInputValue(text);
        setFieldValue(name, undefined);
    }, [name, setFieldValue]);

    return (
        <div className='mmc-search' ref={searchRef}>
            <MuiTextField
                {...restProps}
                id={name}
                placeholder='search'
                value={inputValue}
                onChange={handleChange}
                onKeyDown={keyBoardNavigation}
                onFocus={handleFocus}
                onBlur={handleBlur}
                error={!!hasError}
                helperText={hasError}
            />
            {isListVisible
                && <ul
                    className={cn(
                        'mmc-search__list',
                        { 'mmc-search__list-shadow': loading || !!items.length })}
                    >
                    {loading
                        ? <Loader containerClassName='mmc-search__spinner' />
                        : items.map((item, index) =>
                            <li
                                key={item.id}
                                className={cn(
                                    'mmc-search__list-element',
                                    { 'ed-search__list-element_highlighted': cursor === index },
                                )}
                                onClick={() => handleClick(item)}
                            >
                                {item.displayName}
                            </li>)
                    }
                </ul>
            }
        </div>
    );
}
