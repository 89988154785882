import { IVideoApi } from 'api/landing/video';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesVideo } from 'models/Values';

export interface IVideoService {
    hideVideo: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesVideo>>;
    getVideo: (siteId: string, locale: string) => Promise<RequestResult<ValuesVideo>>;
    editVideo: (siteId: string, locale: string, VideoValues: ValuesVideo) => Promise<RequestResult<ValuesVideo>>;
}

export class VideoService implements IVideoService {
    constructor (
        private readonly VideoApi: IVideoApi,
        private readonly logger: Logger,
    ) { }

    public async hideVideo(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesVideo>> {
        try {
            const response = await this.VideoApi.hideVideo(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide Video Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide Video');
        }
    }

    public async getVideo(siteId: string, locale: string): Promise<RequestResult<ValuesVideo>> {
        try {
            const response = await this.VideoApi.getVideo(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Video Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Video Section');
        }
    }

    public async editVideo(
        siteId: string,
        locale: string,
        VideoValues: ValuesVideo,
    ): Promise<RequestResult<ValuesVideo>> {
        try {
            const response = await this.VideoApi.editVideo(siteId, locale, VideoValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Video Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Video Section');
        }
    }
}
