import { createStore, combine } from 'effector';

import { $locale } from 'store/locale/store';

export const $siteIdSwitcher = createStore<string>('');

export const $siteId = createStore<string>('');

export const $areParamsValid = createStore(false);

export const $params = combine({
    locale: $locale,
    siteId: $siteId,
});

export const $paramsState = combine({
    params: $params,
    areParamsValid: $areParamsValid,
});
