import { createStore } from 'effector';

import { LanguageShortName } from 'models/Language';

export const $localeIdSwitcher = createStore<string>('');

export const $localeSwitcher = createStore<LanguageShortName | string>('');

export const $localeLink = createStore<LanguageShortName | string>('');

export const $locale = createStore<LanguageShortName | string>('');
