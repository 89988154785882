import { memo, useCallback } from 'react';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import classNames from 'classnames';

import { Button, ButtonVariant, LoadingButton } from 'shared/adminComponents';
import { useViewport } from 'shared/utils/hooks/useViewport';
import { TextField } from 'features/Form';
import { contactUs } from 'store/contact';

import { formValidationSchema } from './formValidationSchema';
import { ContactUsFormFields } from './ContactUsFormFields';

import './ContactUs.scss';

interface ContuctUs {
    firstName: string;
    lastName: string;
    email: string;
    description: string;
    title: string;
}

export const initialFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    description: '',
    title: '',
}

interface ContactUsFormProps {
    open: boolean;
    onClose: () => void;
}

export const ContactUsForm = memo(({ open, onClose }: ContactUsFormProps) => {
    const { isMobile } = useViewport(900);

    const onSubmit = useCallback(async (values: ContuctUs, { resetForm }: FormikHelpers<ContuctUs>) => {

        await contactUs({ contact: values });

        onClose();
        resetForm();
    }, [onClose]);

    return (
        <div className={classNames('contact-us-form', { mobile: isMobile })}>
            {open &&
                <Formik<ContuctUs>
                    initialValues={initialFormValues}
                    validationSchema={formValidationSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                    children={({ isSubmitting, submitForm, values }: FormikProps<ContuctUs>) => (
                        <div className='contact-us-form__container'>
                            <Form className='contact-us-form__content content'>
                                <div className='content__fields'>
                                    <TextField
                                        label='First name*'
                                        name={ContactUsFormFields.firstNameField}
                                        className='content__input'
                                    />
                                    <TextField
                                        label='Last name*'
                                        name={ContactUsFormFields.lastNameField}
                                        className='content__input'
                                    />
                                    <TextField
                                        label='E-mail*'
                                        name={ContactUsFormFields.email}
                                        className='content__input'
                                    />
                                    <TextField
                                        label='Brief Description'
                                        name={ContactUsFormFields.description}
                                        className='content__input'
                                        multiline
                                    />
                                    <TextField
                                        label='Title'
                                        name={ContactUsFormFields.title}
                                        className='content__input'
                                        multiline
                                    />
                                </div>
                                <div className='content__buttons'>
                                    <Button
                                        onClick={onClose}
                                        variant={ButtonVariant.Outlined}
                                        className='content__close'
                                    >
                                        Close
                                    </Button>
                                    <LoadingButton
                                        onClick={() => void submitForm()}
                                        variant={ButtonVariant.Contained}
                                        loading={isSubmitting}
                                        className='content__submit'
                                    >
                                        Submit
                                    </LoadingButton>
                                </div>
                            </Form>
                        </div>
                    )}
                />
            }
        </div>
    );
});
