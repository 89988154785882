import { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import cn from 'classnames';

import { PublicRoute } from 'models/Module';
import { MenuPosition } from 'models/Menu';
import { $params } from 'store/params';
import { $site } from 'store/site';
import { TopMenu, LeftMenu } from 'widgets/Menu/Menu';
import { useViewport } from 'shared/utils/hooks/useViewport';

import './Header.scss';

export const Header = memo(() => {
    const params = useStore($params);
    const site = useStore($site);

    const location = useLocation();

    const { isMobile } = useViewport(900);

    const scrollTop = useCallback(() => {
        if (location.pathname === PublicRoute.Main) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location.pathname]);

    const isMenuTop = site?.menuPosition === MenuPosition.Top && !isMobile;

    return (
        <header className='header'>
            <div className={cn('container', 'header__content', { header__content_top: isMenuTop })}>
                <div onClick={scrollTop}>
                    <Link to={`/${params.siteId}/${params.locale}/${PublicRoute.Main}`} >
                        <img className='logo' src='/images/logo.jpg' alt='logo' />
                    </Link>
                </div>
                {isMenuTop ? <TopMenu /> : <LeftMenu />}
            </div>
        </header>
    );
});
