import { createEffect, attach } from 'effector';

import { ISiteService } from 'services/site';
import { $siteService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { MenuPosition } from 'models/Menu';

export const fetchAllSites = attach({
    source: $siteService,
    mapParams: (_: void, service) => service,
    effect: createEffect(async (service: ISiteService) => {
        const result = await service.getAllSites();

        return result.match(
            data => data,
            error => {
                notify({
                    type: 'danger',
                    message: error,
                });

                return [];
            },
        );
    }),
});

export const fetchUserSites = createEffect(async (params: {
    service: ISiteService,
}) => {
    const { service } = params;

    const result = await service.getUserSites();

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return [];
        },
    );
});

export const wrappedFetchUserSites = attach({
    source: $siteService,
    mapParams: (params: void, service) => ({ service }),
    effect: fetchUserSites,
});

export const $areUserSitesLoading = wrappedFetchUserSites.pending;

const fetchSite = createEffect(async (params: {
    service: ISiteService,
    siteId: string,
}) => {
    const { service, siteId } = params;

    const result = await service.getSiteById(siteId);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedFetchSite = attach({
    source: $siteService,
    mapParams: (siteId: string, service) => ({ service, siteId }),
    effect: fetchSite,
});

export const $isSiteLoading = fetchSite.pending;

const updateMenuPosition = createEffect(async (params: {
    service: ISiteService,
    siteId: string,
    menuPosition: MenuPosition,
}) => {
    const { service, siteId, menuPosition } = params;

    const result = await service.updateMenuPosition(siteId, menuPosition);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Menu position updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedUpdateMenuPosition = attach({
    source: $siteService,
    mapParams: ({ siteId, menuPosition }, service) => ({ service, siteId, menuPosition }),
    effect: updateMenuPosition,
});

const cloneSite = createEffect(async (params: {
    service: ISiteService,
    siteId: number,
}) => {
    const { service, siteId } = params;

    const result = await service.cloneSite(siteId);

    return result.match(
        () => {
            notify({
                type: 'info',
                message: 'Site cloned successfully',
            });

            return true;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return false;
        },
    );
});

export const wrappedCloneSite = attach({
    source: $siteService,
    mapParams: (siteId: number, service) => ({ siteId, service }),
    effect: cloneSite,
});
