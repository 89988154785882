import { useState, useCallback, memo, useMemo } from 'react';
import cn from 'classnames';

import { ImageLazyLoading } from 'shared/utils/hoc/ImageLazyLoading';

export interface PictureProps {
    imageMobile?: string;
    className?: string;
    width?: number;
    height?: number;
    imgWidthMobile?: number;
    imgHeightMobile?: number;
    breakPoint?: number;
    image?: string;
    alt: string;
}

export const Picture = memo(({
    imageMobile,
    className,
    width,
    height,
    imgWidthMobile,
    imgHeightMobile,
    breakPoint = 0,
    image = '',
    alt,
}: PictureProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const onLoad = useCallback(() => {
        setIsLoaded(true);
    }, []);

    const style = useMemo(() => ({ opacity: isLoaded ? 1 : 0 }), [isLoaded]);

    const isPng = image?.includes('image_png');

    return (
        <ImageLazyLoading
            isLoaded={isLoaded}
            skeletonClassName={className}
            width={width}
            height={height}
            imgWidthMobile={imgWidthMobile}
            imgHeightMobile={imgHeightMobile}
            breakPoint={breakPoint}
        >
            <picture>
                {imageMobile &&
                    <source
                        media={`(max-width: ${breakPoint - 1}px)`}
                        srcSet={imageMobile}
                        className={cn('picture__image', className)}
                    />
                }
                <img
                    className={cn('picture__image', className, { png: isPng })}
                    src={image}
                    alt={alt}
                    onLoad={onLoad}
                    style={style}
                />
            </picture>
        </ImageLazyLoading>
    );
});
