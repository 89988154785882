export function insertElement <T>(array: T[], index: number, elementToInsert: T) {
    const newArray = [...array];

    newArray.splice(index + 1, 0, elementToInsert);

    return newArray;
}

export function removeAndInsertElement <T>(array: T[], removeIndex: number, insertIndex: number) {
    const newArray = [...array];

    newArray.splice(insertIndex, 0, ...newArray.splice(removeIndex, 1));

    return newArray;
}

export function arrayContainsAnotherArray (arrayContainer: string[], arrayValues: string[]) {
    return arrayValues.every(value => arrayContainer.some(element => element.includes(value)));
}
