import React, { useReducer, createContext, useContext } from 'react';

import { Actions, TourState, TourType } from 'models/Tour';

import { INITIAL_STATE } from './tourInitialState';

const reducer = (state = INITIAL_STATE, action: { type: string; payload?: any; tourType: TourType }) => {
    const tourType = action.tourType;

    switch (action.type) {
        case Actions.Start:
            return { ...state, [tourType]: { ...state[tourType], run: true, tourActive: true } };
        case Actions.Restart:
            return {
                ...state, [tourType]: {
                    ...state[tourType],
                    stepIndex: 0,
                    run: true,
                    key: new Date().toString(),
                    tourActive: true,
                    action: action.type,
                },
            };
        case Actions.Redirect:
            return { ...state, [tourType]: { ...state[tourType], run: false, action: action.type, tourActive: true } };
        case Actions.RunCode:
            return { ...state, [tourType]: { ...state[tourType], run: false, action: action.type, tourActive: true } };

        case Actions.Next:
            return { ...state, [tourType]: { ...state[tourType], run: true, tourActive: true, action: action.type, ...action.payload } };
        case Actions.Prev:
            return { ...state, [tourType]: { ...state[tourType], run: true, tourActive: true, action: action.type, ...action.payload } };
        case Actions.NextOrPrev:
            return { ...state, [tourType]: { ...state[tourType], run: true, tourActive: true, action: action.type, ...action.payload } };
        case Actions.Reset:
            return { ...state, [tourType]: { ...state[tourType], stepIndex: 0, action: action.type, tourActive: true } };
        case Actions.Stop:
            return { ...state, [tourType]: { ...state[tourType], action: action.type, run: false } };
        default:
            return state;
    }
};

interface ContextState {
    tourState: Record<TourType, TourState>;
    dispatch: React.Dispatch<{ type: string; payload?: any; tourType: TourType }>;
}

export const TourContext = createContext({} as ContextState);

export const AppTourProvider = ({ children }: { children: JSX.Element }) => {
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE); //Memo ?

    return (
        <TourContext.Provider value={{ tourState, dispatch }}>
            {children}
        </TourContext.Provider>
    );
};

export const useTourContext = () => {
    const context = useContext(TourContext);

    return context;
};
