import { IImageApi } from 'api/image';
import { FileResult } from 'models/File';
import { RequestResult } from 'shared/utils/requestResult';

export interface IImageService {
    uploadImage: (file: FormData) => Promise<RequestResult<FileResult>>;
}

export class ImageService implements IImageService {
    constructor (
        private readonly ImageApi: IImageApi,
        private readonly logger: Logger,
    ) { }

    public async uploadImage(file: FormData): Promise<RequestResult<FileResult>> {
        try {
            const response = await this.ImageApi.uploadImage(file);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to upload image');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to upload image');
        }
    }
}
