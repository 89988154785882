import { useState } from 'react';

import { Button } from 'shared/components';

import { ContactUsForm } from '../ContactUs/ContactUs';

import './ContactUsButton.scss';

export const ContactUsButton = () => {
    const [open, setOpen] = useState(false);

    return (
        <div className='contact-us'>
            <ContactUsForm open={open} onClose={() => setOpen(false)} />
            <Button 
                className='contact-us__button'
                onClick={() => setOpen(open => !open)}
            >
                Contact us
            </Button>
        </div>
    );
}
