import { createEffect, attach } from 'effector';

import { IOtherService } from 'services/other';
import { $otherService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { ValuesOther } from 'models/Values';

import { getOther } from './events';
import { emptyValuesOther } from './constants';

const fetchOtherPages = createEffect(async (params: {
    service: IOtherService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getOtherPages(siteId, locale);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return [];
        },
    );
});

export const wrappedFetchOtherPages = attach({
    source: $otherService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchOtherPages,
});

export const $areOtherPagesLoading = wrappedFetchOtherPages.pending;

const fetchOther = createEffect(async (params: {
    service: IOtherService,
    id: string,
}) => {
    const { service, id } = params;

    const result = await service.getOtherPageById(id);

    return result.match(
        data => data,
        error => {
            id !== '0' &&
            notify({
                type: 'danger',
                message: error,
            });

            return emptyValuesOther;
        },
    );
});

export const wrappedFetchOther = attach({
    source: $otherService,
    mapParams: ({ id }, service) => ({ service, id }),
    effect: fetchOther,
});

export const $isOtherLoading = wrappedFetchOther.pending;

const updateOther = createEffect(async (params: {
    service: IOtherService,
    siteId: string,
    locale: string,
    values: ValuesOther,
    id: string,
}) => {
    const { service, siteId, locale, values, id } = params;

    const result = await service.editOtherPageById(siteId, locale, id, values);

    return result.match(
        data => {
            notify({
                type: 'success',
                message: ' updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            getOther({ id });

            return emptyValuesOther;
        },
    );
});

export const wrappedUpdateOther = attach({
    source: $otherService,
    mapParams: ({ siteId, locale, values, id }, service) => ({ siteId, locale, values, id, service }),
    effect: updateOther,
});

export const $isOtherUpdating = wrappedUpdateOther.pending;

export const deleteOtherPage = attach({
    source: $otherService,
    mapParams: ({ id, siteId, locale }, service) => ({ id, siteId, locale, service }),
    effect: createEffect(async (params: {
        id: string,
        service: IOtherService,
        siteId: string,
        locale: string,
    }) => {
        const { service, id, siteId, locale } = params;

        const result = await service.deleteOtherPageById(siteId, locale, id);

        return result.match(
            () => {
                notify({
                    type: 'info',
                    message: 'Employee deleted successfully.',
                });

                return true;
            },
            error => {
                notify({
                    type: 'danger',
                    message: error,
                });

                return false;
            },
        );
    }),
});

export const $isDeletingOtherPage = deleteOtherPage.pending;
