export enum FlexibleKey {
    LabelText = 'labelText',
    Title = 'title',
    Heading = 'heading',
    Header = 'header',
    BodyText = 'bodyText',
    Text = 'text',
    Image = 'image',
    MobileImage = 'mobileImage',
    Button = 'button',
    Slides = 'slides',
    Link = 'link',
    DirectorId = 'directorId',
    Director1Id = 'director1Id',
    Director2Id = 'director2Id',
    SliderTitle = 'sliderTitle',
    MenuItem = 'menuItem',
    isHidden = 'hiddenFlag',
    Links = 'links',
}
