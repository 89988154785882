import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as GoBack } from 'shared/components/Icons/go-back.svg';

import './GoBackButton.scss';

interface GoBackButtonProps {
    onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

export const GoBackButton = memo(({ onClick }: GoBackButtonProps) => {
    const navigate = useNavigate();

    const onClickHandler = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
        if (onClick) {
            onClick(event);
        } else {
            navigate(-1);
        }
    }, [onClick, navigate]);

    return <GoBack className='go-back-button' onClick={onClickHandler} />;
});
