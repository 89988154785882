import { Fragment } from 'react';

import { LinkComponent } from 'features/EditPage/LinkComponent/LinkComponent';
import { ButtonType } from 'models/Button';
import { FlexibleKey } from 'models/Values';

import './FooterSection.scss';

interface ListNodesProps {
    listElement: ButtonType;
    isLast: boolean;
    index: number;
}

export const ListElement = ({
    listElement,
    isLast,
    index,
}: ListNodesProps) => {
    return (
        <Fragment key={listElement.id}>
            <LinkComponent
                initialValue={listElement}
                name={FlexibleKey.Links}
                className='footer__link'
                chain={[index]}
            />
            {!isLast && <span className='footer__link'>|</span>}
        </Fragment>
    );
};
