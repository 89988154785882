import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { ITextBlockService } from 'services/landing/textBlock';
import { notify } from 'shared/utils/notification';
import { ValuesTextBlock } from 'models/Values';

import { $textBlockService } from '../../services';
import { $params } from '../../params';
import { getTextBlockSection, setTextBlockError, setTextBlockSuccess } from './events';
import { emptyValuesTextBlock } from '../constants';

const hideTextBlockSection = createEffect(async (params: {
    service: ITextBlockService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideTextBlock(siteId, locale, isHidden);

    return result.match(
        data => {
            setTextBlockSuccess(true);
            setTextBlockError('');

            return data;
        },
        error => {
            setTextBlockError(error);

            return emptyValuesTextBlock;
        },
    );
});

export const wrappedHideTextBlock = attach({
    source: $textBlockService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideTextBlockSection,
});

export const $isTextBlockSectionHiding = wrappedHideTextBlock.pending;

const fetchTextBlockSection = createEffect(async (params: {
    service: ITextBlockService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getTextBlock(siteId, locale);

    return result.match(
        data => {
            setTextBlockSuccess(true);
            setTextBlockError('');

            return data;
        },
        error => {
            setTextBlockError(error);

            return emptyValuesTextBlock;
        },
    );
});

export const wrappedFetchTextBlockSection = attach({
    source: $textBlockService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchTextBlockSection,
});

export const $isTextBlockSectionLoading = wrappedFetchTextBlockSection.pending;

const updateTextBlock = createEffect(async (params: {
    service: ITextBlockService,
    siteId: string,
    locale: string,
    values: ValuesTextBlock,
}) => {
    const { service, siteId, locale, values } = params;
    const result = await service.editTextBlock(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getTextBlockSection(params);

            return emptyValuesTextBlock;
        },
    );
});

export const wrappedUpdateTextBlock = attach({
    source: $textBlockService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateTextBlock,
});

export const $isTextBlockSectionUpdating = wrappedUpdateTextBlock.pending;
