import {
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from 'shared/adminComponents';

import { useNestedField } from '../useNestedField';

import './TextField.scss';

export interface TextFieldProps<_, Field extends string>
    extends Omit<MuiTextFieldProps, 'name' | 'onChange'> {
        name: Field;
        chain?: (string | number)[];
        isOutlined?: boolean;
}

const inputLabelProps = { shrink: true };

export const TextField = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    chain = [],
    isOutlined = true,
    className,
    ...restProps
}: TextFieldProps<Fields, Field>) => {

    const { field, valueLocal, setFieldValueLocal, handleBlur, helperText } = useNestedField({ name, chain });

    return (
        <MuiTextField
            id={name}
            name={field}
            value={valueLocal ?? ''}
            onChange={setFieldValueLocal}
            onBlur={handleBlur}
            error={!!helperText}
            helperText={helperText}
            className={`custom-textfield-control ${className}`}
            size='small'
            isOutlined
            InputLabelProps={inputLabelProps}
            {...restProps}
        />
    );
};
