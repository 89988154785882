import { ElementType } from 'react';

import { PictureProps, ImageProps } from 'shared/components';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';
import { ImageQuiz } from './ImageQuiz';

import './ImageComponent.scss';

interface EditImageComponentProps <_, Field extends string>{
    name: Field;
    mobileName?: Field;
    chain?: (number | string)[];
    imgWidth: number;
    imgHeight: number;
    imgWidthMobile?: number;
    imgHeightMobile?: number;
    currentImage: ElementType;
    imgProps?: ImageProps | PictureProps;
    className?: string;
}

export const EditImageComponent = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    mobileName,
    chain = [],
    imgWidth,
    imgHeight,
    imgWidthMobile,
    imgHeightMobile,
    currentImage: CurrentImage,
    imgProps,
    className,
}: EditImageComponentProps<Fields, Field>) => {
    return (
        <EditQenericComponent
            layout={
                <CurrentImage
                    { ...imgProps }
                    className={className}
                    width={imgWidth}
                    height={imgHeight}
                    imgWidthMobile={imgWidthMobile}
                    imgHeightMobile={imgHeightMobile}
                />
            }
            quiz={
                <ImageQuiz
                    name={name}
                    mobileName={mobileName}
                    chain={chain}
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    imgWidthMobile={imgWidthMobile}
                    imgHeightMobile={imgHeightMobile}
                    currentImage={CurrentImage}
                    imgProps={imgProps}
                    className={className}
                />
            }
        />
    );
};
