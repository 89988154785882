import { useEffect, useMemo, memo } from 'react';
import { useStore } from 'effector-react';

import { Select } from 'shared/adminComponents';
import { $siteIdSwitcher, setSiteIdSwitcher } from 'store/params';
import { $userSites, getUserSites } from 'store/site';

import './SiteId.scss';

export const SiteId = memo(() => {
    const userSites = useStore($userSites);
    const siteIdSwitcher = useStore($siteIdSwitcher);

    useEffect(() => {
        getUserSites();
    }, []);

    const itemsSiteIds = useMemo(() => {
        return userSites.map(element => ({ value: element.id, displayName: element.hospitalName }));
    }, [userSites]);

    return (
        <>
            {!!itemsSiteIds.length &&
                <Select
                    native
                    items={itemsSiteIds}
                    value={siteIdSwitcher}
                    onChange={setSiteIdSwitcher}
                    className='site-id'
                />
            }
        </>
    );
});
