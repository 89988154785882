import { IOtherPageApi } from 'api/other/interfaces';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesOther } from 'models/Values';

export interface IOtherService {
    getOtherPages: (siteId: string, locale: string) => Promise<RequestResult<ValuesOther[]>>;
    getOtherPageById: (id: string) => Promise<RequestResult<ValuesOther>>;
    editOtherPageById: (siteId: string, locale: string, id: string, values: ValuesOther) => Promise<RequestResult<ValuesOther>>;
    deleteOtherPageById: (siteId: string, locale: string, id: string) => Promise<RequestResult<boolean>>;
}

export class OtherService implements IOtherService {
    constructor (
        private readonly otherApi: IOtherPageApi,
        private readonly logger: Logger,
    ) { }

    public async getOtherPages(siteId: string, locale: string): Promise<RequestResult<ValuesOther[]>> {
        try {
            const response = await this.otherApi.getOtherPages(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get page');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get page');
        }
    }

    public async getOtherPageById(id: string): Promise<RequestResult<ValuesOther>> {
        try {
            const response = await this.otherApi.getOtherPageById(id);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get page');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get page');
        }
    }

    public async editOtherPageById(siteId: string, locale: string, id: string, values: ValuesOther): Promise<RequestResult<ValuesOther>> {
        try {
            const response = await this.otherApi.editOtherPageById(siteId, locale, values, id);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit page');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit page');
        }
    }

    public async deleteOtherPageById(siteId: string, locale: string, id: string): Promise<RequestResult<boolean>> {
        try {
            const response = await this.otherApi.deleteOtherPageById(siteId, locale, id);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to delete page');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to delete page');
        }
    }
}
