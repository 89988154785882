import { createEvent } from 'effector';

import { ValuesSolution } from 'models/Values/Blocks/Landing/ValuesWithSlider';

export const getSolutionSection = createEvent<{ siteId: string; locale: string; }>();

export const updateSolutionSection = createEvent<ValuesSolution>();

export const hideSolutionSection = createEvent<boolean>();

export const setSolutionError = createEvent<string>();
