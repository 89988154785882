import { useState, useEffect, ChangeEvent, useCallback } from 'react';

import { Modal, UploadButton as Button } from 'shared/adminComponents';
import { externalLinkRegex } from 'shared/constants/constants';
import { ImageCropDialog } from 'features/ImageCropDialog';
import { useToggle } from 'shared/utils/hooks/useToggle';
import { getBase64FromFile } from 'shared/utils/file/getBase64FromFile';
import { getImageName } from 'shared/utils/image/getImageName';

import { useNestedField } from '../useNestedField';

import 'react-image-crop/dist/ReactCrop.css';
import './UploadButton.scss';

const paperProps = { className: 'image-gallery__modal' };

interface UploadButtonProps {
    name: string;
    chain: (string | number)[];
    imgWidth: number;
    imgHeight: number;
    setUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    mobile?: boolean;
    cropWindowMinWidth?: number;
    cropWindowMinHeight?: number;
}

export const UploadButton = ({ name, chain, imgWidth, imgHeight, setUrl, mobile = false, ...restProps }: UploadButtonProps) => {
    const { valueLocal, setFieldValueLocal } = useNestedField({ name, chain });

    const [base64, setBase64] = useState(valueLocal);
    const [imageType, setImageType] = useState('');
    const [open, toggleOpen] = useToggle(false);

    useEffect(() => {
        const isValueLink = externalLinkRegex.test(valueLocal);

        if (isValueLink) {
            const imageName = getImageName(valueLocal);

            if (imageName) {
                setUrl(valueLocal);
                setFieldValueLocal(imageName);
            }
        }
    }, [setFieldValueLocal, setUrl, valueLocal]);

    const onChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
        const imgFile = event.target.files?.[0];

        if (imgFile) {
            const base64 = await getBase64FromFile(imgFile);

            setImageType(imgFile.type);
            setBase64(base64);
            toggleOpen();
        }
    }, [toggleOpen]);

    return (
        <>
            <Button
                className='upload-button'
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={onChange}
                mobile={mobile}
            />
            <Modal
                isOpen={open && !!base64}
                fullWidth
                maxWidth='md'
                PaperProps={paperProps}
            >
                <ImageCropDialog
                    base64={base64}
                    onClose={toggleOpen}
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    name={name}
                    setFieldValue={setFieldValueLocal}
                    setUrl={setUrl}
                    imageType={imageType}
                    {...restProps}
                />
            </Modal>
        </>
    );
};
