import { TokenInfo, LogoutInfo } from 'models/Auth';
import { RequestResult } from 'shared/utils/requestResult';
import { IAuthApi } from 'api/auth';

export interface IAuthService {
    signIn: (email: string, password: string) => Promise<RequestResult<TokenInfo>>;
    refreshToken: (token: TokenInfo) => Promise<RequestResult<TokenInfo>>;
    signInWithOkta: (oktaAccessToken: string, oktaIdToken: string) => Promise<RequestResult<TokenInfo>>;
    refreshOktaToken: (token: TokenInfo) => Promise<RequestResult<TokenInfo>>;
    oktaLogout: (token: TokenInfo) => Promise<RequestResult<LogoutInfo>>;
    logout: (token: TokenInfo) => Promise<RequestResult<boolean>>;
}

export class AuthService implements IAuthService {
    constructor (
        private readonly authApi: IAuthApi,
        private readonly logger: Logger,
    ) { }

    public async signIn(email: string, password: string): Promise<RequestResult<TokenInfo>> {
        try {
            const response = await this.authApi.signIn(email, password);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to sign in');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to sign in');
        }
    }

    public async refreshToken(token: TokenInfo): Promise<RequestResult<TokenInfo>> {
        try {
            const response = await this.authApi.refreshToken(token);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to refresh token.');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to refresh token.');
        }
    }

    public async signInWithOkta(oktaAccessToken: string, oktaIdToken: string): Promise<RequestResult<TokenInfo>> {
        try {
            const response = await this.authApi.signInWithOkta(oktaAccessToken, oktaIdToken);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to sign in');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to sign in');
        }
    }

    public async refreshOktaToken(token: TokenInfo): Promise<RequestResult<TokenInfo>> {
        try {
            const response = await this.authApi.refreshOktaToken(token);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to refresh token.');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to refresh token.');
        }
    }

    public async oktaLogout(token: TokenInfo): Promise<RequestResult<LogoutInfo>> {
        try {
            const response = await this.authApi.oktaLogout(token);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to logout');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to logout');
        }
    }

    public async logout(token: TokenInfo): Promise<RequestResult<boolean>> {
        try {
            const response = await this.authApi.logout(token);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to logout');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to logout');
        }
    }
}
