import { createStore, combine } from 'effector';

import { EmployeeRoleType } from 'models/Employee';
import { ValuesDoctors } from 'models/Values';

import { emptyValuesDoctorsInfo } from './constants';

export const $physiciansInfo = createStore<ValuesDoctors>(emptyValuesDoctorsInfo);
export const $physiciansInfoSuccess = createStore(false);

export const $advancedProvidersInfo = createStore<ValuesDoctors>(emptyValuesDoctorsInfo);
export const $advancedProvidersInfoSuccess = createStore(false);

export const $allDoctorsInfo = createStore<ValuesDoctors>(emptyValuesDoctorsInfo);
export const $allDoctorsInfoSuccess = createStore(false);

export const $currentFetchDoctorsInfo = createStore<ValuesDoctors>(emptyValuesDoctorsInfo);

export const $currentDoctorsInfoRole = createStore<EmployeeRoleType | null>(null);

export const $currentDoctorsInfo = combine(
    $currentFetchDoctorsInfo,
    $currentDoctorsInfoRole,
    (currentFetchDoctorsInfo, currentDoctorsInfoRole) => {
        return { ...currentFetchDoctorsInfo, type: currentDoctorsInfoRole };
});
