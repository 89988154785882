import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';

import { AppRouter, ThemeProvider } from './providers';

import 'react-notifications-component/dist/theme.css';
import './App.scss';

export function App() {
    return (
        <>
            <ReactNotifications />
            <ThemeProvider>
                <Router>
                    <AppRouter />
                </Router>
            </ThemeProvider>
        </>
    );
}
