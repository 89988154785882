import cn from 'classnames';

import { Image } from 'shared/components';

export interface SlideProps {
    slide: string;
    index: number;
}

export const Slide = ({
    slide,
    index,
 }: SlideProps) => {
    return (
        <Image
            className={cn('doctor__photo', { first_slide: index === 0 })}
            image={slide}
            width={254}
            height={254}
            alt='employee photo'
        />
    );
};
