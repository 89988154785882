import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $footerSection, $errorFooter } from './store';
import { wrappedFetchFooterSection, wrappedUpdateFooter, wrappedHideFooter } from './effects';
import { getFooterSection, setFooterSectionError, updateFooterSection, hideFooterSection } from './events';

sample({
    clock: hideFooterSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideFooter,
});

forward({
    from: wrappedHideFooter.doneData,
    to: $footerSection,
});

sample({
    clock: getFooterSection,
    source: $params,
    filter: params => !!params.siteId && !!params.locale,
    target: wrappedFetchFooterSection,
});

forward({
    from: wrappedFetchFooterSection.doneData,
    to: $footerSection,
});

sample({
    clock: updateFooterSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateFooter,
});

forward({
    from: wrappedUpdateFooter.doneData,
    to: $footerSection,
});

forward({
    from: setFooterSectionError,
    to: $errorFooter,
});
