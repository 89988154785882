import { EditQenericComponent } from '../GenericComponent';

interface InfoComponentProps {
    className: string;
    layout: string;
}

export const InfoComponent = ({ className, layout }: InfoComponentProps) => {
    return (
        <EditQenericComponent
            layout={null}
            quiz={<div className={className} dangerouslySetInnerHTML={{ __html: layout }}></div>}
         />
    );
};
