import { lazy } from 'react';

import { Module, PrivateRoute, PublicRoute } from 'models/Module';

const Main = lazy(() => import('pages/public/Main'));

const Physicians = lazy(() => import('pages/public/DoctorsPages/Physicians'));
const AdvancedProviders = lazy(() => import('pages/public/DoctorsPages/AdvancedProviders'));
const AllDoctors = lazy(() => import('pages/public/DoctorsPages/AllDoctors'));

const Other = lazy(() => import('pages/public/Other'));


const Employees = lazy(() => import('pages/admin/Employees'));
const MainEdit = lazy(() => import('pages/admin/MainEdit'));

const PhysiciansEdit = lazy(() => import('pages/admin/DoctorsEdit/PhysiciansEdit'));
const AdvancedProvidersEdit = lazy(() => import('pages/admin/DoctorsEdit/AdvancedProvidersEdit'));
const AllDoctorsEdit = lazy(() => import('pages/admin/DoctorsEdit/AllDoctorsEdit'));

const AddOther = lazy(() => import('pages/admin/AddOther'));
const OtherEdit = lazy(() => import('pages/admin/OtherEdit'));

const MenuEdit = lazy(() => import('pages/admin/MenuEdit'));

const Settings = lazy(() => import('pages/admin/Settings'));

const LinksAdd = lazy(() => import('pages/admin/LinksAdd'));

const AddMedia = lazy(() => import('pages/admin/AddMedia'));

export const privateRoutesConfig: Module[] = [
    { path: PrivateRoute.Settings, component: Settings },
    { path: PrivateRoute.Landing, component: MainEdit },
    { path: PrivateRoute.Physicians, component: PhysiciansEdit },
    { path: PrivateRoute.AdvancedProviders, component: AdvancedProvidersEdit },
    { path: PrivateRoute.AllDoctors, component: AllDoctorsEdit },
    { path: PrivateRoute.AddOther, component: AddOther },
    { path: PrivateRoute.Other, component: OtherEdit },
    { path: PrivateRoute.Menu, component: MenuEdit },
    { path: PrivateRoute.ExternalLinks, component: LinksAdd },
    { path: PrivateRoute.Employees, component: Employees },
    { path: PrivateRoute.AddMedia, component: AddMedia },
];

export const publicRoutesConfig: Module[] = [
    { path: PublicRoute.Main, component: Main },
    { path: PublicRoute.Physicians, component: Physicians },
    { path: PublicRoute.AdvancedProviders, component: AdvancedProviders },
    { path: PublicRoute.AllDoctors, component: AllDoctors },
    { path: PublicRoute.Other, component: Other },
];
