// eslint-disable-next-line import/no-unresolved
import { ElementType, useCallback } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useFormikContext } from 'formik';

import { AddButton, DeleteButton } from 'features/Form';
import { Carousel, SlideWrapper } from 'shared/components';
import { SlideType } from 'models/Carousel';

import { EditQenericComponent } from '../GenericComponent/GenericComponent';
import { getInitialSwiperElement } from './utils';

import './SwiperComponent.scss';

interface EditSwiperComponentProps<_, Field extends string> {
    slides: SlideType[];
    name: Field;
    slide: ElementType;
    modalSlide?: ElementType;
    mobileClassName?: string;
}

export const EditSwiperComponent = <
    Fields extends { [key in Field]: SlideType[] },
    Field extends string,
>({
    slides,
    name,
    slide: Slide,
    modalSlide: ModalSlide,
    mobileClassName,
}: EditSwiperComponentProps<Fields, Field>) => {
    return (
        <EditQenericComponent
            layout={<Carousel slide={Slide} slides={slides} modalSlide={ModalSlide} mobileClassName={mobileClassName} />}
            quiz={<EditCarousel slide={Slide} name={name} />}
        />
    );
};

interface EditCarouselProps<_, Field extends string> {
    name: Field;
    slide: ElementType;
    noSwiping?: boolean;
}

const EditCarousel = <
    Fields extends { [key in Field]: SlideType[] },
    Field extends string,
>({
    name,
    slide: Slide,
}: EditCarouselProps<Fields, Field>) => {
    const { values: { [name]: currentValue }, setFieldValue } = useFormikContext<Fields>();

    const onAddButton = useCallback((index: number) => {
        const newValues = [ ...currentValue ];

        newValues[index].button = {
            id: 0,
            text: '',
            link: '',
        };

        setFieldValue(name, newValues);
    }, [currentValue, setFieldValue, name]);

    const onDeleteButton = useCallback((index: number) => {
        const newValues = [ ...currentValue ];
        delete newValues[index].button;

        setFieldValue(name, newValues);
    }, [currentValue, name, setFieldValue]);

    return (
        <SlideWrapper>
            {!currentValue.length
                ? <div className='slide__buttons'>
                    <AddButton
                        name={name}
                        getInitialElement={getInitialSwiperElement}
                    >
                        Add next item
                    </AddButton>
                </div>
                : currentValue.map((slide, index) => (
                    <SwiperSlide key={slide.id}>
                        <div className='slide__buttons'>
                            <DeleteButton
                                name={name}
                                elementKey={slide.id}
                                className='slide__delete'
                            />
                            <AddButton
                                name={name}
                                index={index}
                                getInitialElement={getInitialSwiperElement}
                            >
                                Add next item
                            </AddButton>
                        </div>
                        <Slide
                            slide={slide}
                            index={index}
                            onAdd={() => onAddButton(index)}
                            onDelete={() => onDeleteButton(index)}
                        />
                    </SwiperSlide>
                ))
            }
        </SlideWrapper>
    );
};
