import { ElementType } from 'react';

import { SlideType } from 'models/Carousel';
import { MobileContainer } from 'features/MobileContainer';

import { Carousel } from '../desktop/Carousel';

import './ModalSlider.scss';

export interface ModalSliderProps {
    slides: SlideType[];
    slide: ElementType;
    indexOpen: number | null;
    setIndexOpen: React.Dispatch<React.SetStateAction<number | null>>;
    className: string;
}

export const ModalSlider = ({
    slides,
    slide: Slide,
    indexOpen,
    setIndexOpen,
    className,
}: ModalSliderProps) => {
    return (
        <MobileContainer
            className={className}
            indexOpen={indexOpen}
            setIndexOpen={setIndexOpen}
        >
            <Carousel
                slide={Slide}
                slides={slides}
                initialSlide={indexOpen}
            />
        </MobileContainer>
    );
};
