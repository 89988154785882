export function fetchWithAuthorization(token: string, fetch: Fetch) {
    return (input: RequestInfo | URL, init?: RequestInit | undefined) => {
        const providedHeaders = init?.headers || {};
        const mergedHeaders = {
            ...providedHeaders,
            Authorization: `Bearer ${token}`,
        };

        const mergedParameters = {
            ...init,
            headers: new Headers(mergedHeaders),
        };

        return fetch(input, mergedParameters);
    };
}
