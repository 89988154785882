import { Styles } from 'react-joyride';

export const STYLES: Styles = {
    options: {
        primaryColor: '#0277BD',
    },
    tooltipContainer: {
        textAlign: 'left',
    },
    buttonBack: {
        marginRight: 10,
    },
};

export const LOCALE = {
    last: 'End tour',
};
