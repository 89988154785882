import { createStore, combine } from 'effector';

import { MenuItem } from 'models/Menu';

import { $isMenuLoading } from './effects';

export const $menu = createStore<MenuItem[]>([]);

export const $errorMenu = createStore<string>('');

export const $successMenu = createStore<boolean>(false);

export const $menuStatus = combine({
    menu: $menu,
    isMenuLoading: $isMenuLoading,
    errorMenu: $errorMenu,
    successMenu: $successMenu,
});
