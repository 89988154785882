import { HttpMethods } from 'api/constants';
import { ValuesOther } from 'models/Values';

import { IOtherPageApi } from './interfaces';
import { OTHER_PAGE_URL, OTHER_PAGES_URL } from './constants';
import { getFilteredQuery } from '../utils';

export class OtherPageApi implements IOtherPageApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getOtherPages(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${OTHER_PAGES_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async getOtherPageById(id: string) {
        const response = await this.fetch(`${OTHER_PAGE_URL}?id=${id}`);

        return response;
    }

    public async editOtherPageById(siteId: string, locale: string, otherPageValues: ValuesOther) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${OTHER_PAGES_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(otherPageValues),
            },
        );

        return response;
    }

    public async deleteOtherPageById(siteId: string, locale: string, id: string) {
        const queryList = {
            siteId,
            locale,
            id,
        };

        const response = await this.fetch(`${OTHER_PAGES_URL}${getFilteredQuery(queryList)}`, { //check locale
            method: HttpMethods.Delete,
        });

        return response;
    }
}
