import { ChangeEvent, memo } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/PhotoCamera';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './UploadButton.scss';

interface UploadButtonProps extends Omit<MuiButtonProps, 'className' | 'onChange'> {
    className?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    mobile?: boolean;
}

export const UploadButton = memo(({
    className,
    onChange,
    mobile = false,
    ...restProps
}: UploadButtonProps) => {

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const element = event.target as HTMLButtonElement;
        element.value = '';
    };

    return (
        <IconButton
            {...restProps}
            aria-label='upload picture'
            size={ButtonSize.Small}
            className={cn('upload-button', className)}
            onClick={onClick}
        >
            <input
                className='upload-button__input'
                accept='image/*,.png,.jpg,.gif,.web'
                type='file'
                onChange={onChange}
            />
            {mobile ? <SmartphoneOutlinedIcon /> : <UploadIcon  />}
        </IconButton>
    );
});
