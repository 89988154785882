import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $imageBlockSection, $imageBlockSectionSuccess, $errorImageBlockSection } from './store';
import { wrappedFetchImageBlockSection, wrappedHideImageBlock, wrappedUpdateImageBlock } from './effects';
import { getImageBlockSection, hideImageBlockSection, setImageBlockError, setImageBlockSuccess, updateImageBlockSection } from './events';

sample({
    clock: hideImageBlockSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideImageBlock,
});

forward({
    from: wrappedHideImageBlock.doneData,
    to: $imageBlockSection,
});

sample({
    clock: getImageBlockSection,
    source: $params,
    target: wrappedFetchImageBlockSection,
});

forward({
    from: wrappedFetchImageBlockSection.doneData,
    to: $imageBlockSection,
});

sample({
    clock: updateImageBlockSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateImageBlock,
});

forward({
    from: wrappedUpdateImageBlock.doneData,
    to: $imageBlockSection,
});

forward({
    from: setImageBlockError,
    to: $errorImageBlockSection,
});

forward({
    from: setImageBlockSuccess,
    to: $imageBlockSectionSuccess,
});
