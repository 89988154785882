import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { IPublicationService } from 'services/landing/publicaions';
import { notify } from 'shared/utils/notification';
import { ValuesPublication } from 'models/Values';

import { $publicationsService } from '../../services';
import { $params } from '../../params';
import { getPublicationsSection, setPublicationsError } from './events';
import { emptyValuesPublications } from '../constants';

const hidePublicationsSection = createEffect(async (params: {
    service: IPublicationService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hidePublication(siteId, locale, isHidden);

    return result.match(
        data => {
            setPublicationsError('');

            return data;
        },
        error => {
            setPublicationsError(error);

            return emptyValuesPublications;
        },
    );
});

export const wrappedHidePublications = attach({
    source: $publicationsService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hidePublicationsSection,
});

export const $isPublicationsSectionHiding = wrappedHidePublications.pending;

const fetchPublicationsSection = createEffect(async (params: {
    service: IPublicationService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getPublication(siteId, locale);

    return result.match(
        data => {
            setPublicationsError('');

            return data;
        },
        error => {
            setPublicationsError(error);

            return emptyValuesPublications;
        },
    );
});

export const wrappedFetchPublicationsSection = attach({
    source: $publicationsService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchPublicationsSection,
});

export const $isPublicationsSectionLoading = wrappedFetchPublicationsSection.pending;

const updatePublications = createEffect(async (params: {
    service: IPublicationService,
    siteId: string,
    locale: string,
    values: ValuesPublication,
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editPublication(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getPublicationsSection(params);

            return emptyValuesPublications;
        },
    );
});

export const wrappedUpdatePublications = attach({
    source: $publicationsService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updatePublications,
});

export const $isPublicationsSectionUpdating = wrappedUpdatePublications.pending;
