import { HttpMethods } from 'api/constants';

import { FILE_URL } from './constants';
import { IImageApi } from './interfaces';

export class ImageApi implements IImageApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async uploadImage(formData: FormData) {
        const response = await this.fetch(FILE_URL, {
            method: HttpMethods.Post,
            body: formData,
        });

        return response;
    }
}
