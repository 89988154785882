import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { ITeamService } from 'services/landing/team';
import { $teamService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { ValuesTeam } from 'models/Values';
import { $params } from 'store/params';

import { getTeamSection, setTeamError } from './events';
import { emptyValuesTeam } from '../constants';

const hideTeamSection = createEffect(async (params: {
    service: ITeamService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideTeam(siteId, locale, isHidden);

    return result.match(
        data => data,
        error => {
            setTeamError(error);

            return emptyValuesTeam;
        },
    );
});

export const wrappedHideTeam = attach({
    source: $teamService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideTeamSection,
});

export const $isTeamSectionHiding = wrappedHideTeam.pending;

const fetchTeamSection = createEffect(async (params: {
    service: ITeamService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getTeam(siteId, locale);

    return result.match(
        data => data,
        error => {
            setTeamError(error);

            return emptyValuesTeam;
        },
    );
});

export const wrappedFetchTeamSection = attach({
    source: $teamService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchTeamSection,
});

export const $isTeamSectionLoading = wrappedFetchTeamSection.pending;

const updateTeam = createEffect(async (params: {
    service: ITeamService,
    siteId: string,
    locale: string,
    values: ValuesTeam,
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editTeam(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getTeamSection(params);

            return emptyValuesTeam;
        },
    );
});

export const wrappedUpdateTeam = attach({
    source: $teamService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateTeam,
});

export const $isTeamSectionUpdating = wrappedUpdateTeam.pending;
