import { memo, forwardRef } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './CopyButton.scss';

interface CopyButtonProps extends Omit<MuiButtonProps, 'className'> {
    className?: string;
}

export const CopyButton = memo(forwardRef<HTMLButtonElement, CopyButtonProps>(({
    className,
    ...restProps
}, ref) => {
    return (
        <IconButton
            {...restProps}
            ref={ref}
            aria-label='copy'
            size={ButtonSize.Small}
            className={cn('copy-button', className)}
        >
            <ContentCopyIcon />
        </IconButton>
    );
}));
