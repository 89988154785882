import { createEffect, attach } from 'effector';

import { IImageService } from 'services/image';
import { $imageService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { FileResult } from 'models/File';

const uploadImage = createEffect(async (params: {
    service: IImageService,
    file: File,
}) => {
    const { service, file } = params;

    const formData = new FormData();
    formData.append('file', file);

    const result = await service.uploadImage(formData);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return {} as FileResult;
        },
    );
});

export const wrappedUploadImage = attach({
    source: $imageService,
    mapParams: ({ file }, service) => ({ service, file }),
    effect: uploadImage,
});

export const $isImageLoading = wrappedUploadImage.pending;
