import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { IImageBlockService } from 'services/landing/imageBlock';
import { notify } from 'shared/utils/notification';
import { ValuesImageBlock } from 'models/Values';

import { $imageBlockService } from '../../services';
import { $params } from '../../params';
import { getImageBlockSection, setImageBlockError, setImageBlockSuccess } from './events';
import { emptyValuesImageBlock } from '../constants';

const hideImageBlockSection = createEffect(async (params: {
    service: IImageBlockService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideImageBlock(siteId, locale, isHidden);

    return result.match(
        data => {
            setImageBlockSuccess(true);
            setImageBlockError('');

            return data;
        },
        error => {
            setImageBlockError(error);

            return emptyValuesImageBlock;
        },
    );
});

export const wrappedHideImageBlock = attach({
    source: $imageBlockService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideImageBlockSection,
});

export const $isImageBlockSectionHiding = wrappedHideImageBlock.pending;

const fetchImageBlockSection = createEffect(async (params: {
    service: IImageBlockService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getImageBlock(siteId, locale);

    return result.match(
        data => {
            setImageBlockSuccess(true);
            setImageBlockError('');

            return data;
        },
        error => {
            setImageBlockError(error);

            return emptyValuesImageBlock;
        },
    );
});

export const wrappedFetchImageBlockSection = attach({
    source: $imageBlockService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchImageBlockSection,
});

export const $isImageBlockSectionLoading = wrappedFetchImageBlockSection.pending;

const updateImageBlock = createEffect(async (params: {
    service: IImageBlockService,
    siteId: string,
    locale: string,
    values: ValuesImageBlock,
}) => {
    const { service, siteId, locale, values } = params;
    const result = await service.editImageBlock(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getImageBlockSection(params);

            return emptyValuesImageBlock;
        },
    );
});

export const wrappedUpdateImageBlock = attach({
    source: $imageBlockService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateImageBlock,
});

export const $isImageBlockSectionUpdating = wrappedUpdateImageBlock.pending;
