import { DraggableElement } from 'models/DragAndDrop';

export enum LanguageShortName {
    English = 'en',
    Spanish = 'es',
    Chinese = 'ch',
}

export type LanguageShortNameType = LanguageShortName.English | LanguageShortName.Spanish | LanguageShortName.Chinese;

export enum LanguageLongName {
    English = 'english',
    Spanish = 'español',
    Chinese = 'chinese',
}

export interface Language {
    id: number;
    name: LanguageShortName;
    fullName: LanguageLongName;
    order: number | null;
    isMaintenance: boolean;
}

export type DraggableLanguage = Language & DraggableElement;

export interface LanguageValue {
    language: string;
}
