import { createEvent } from 'effector';

import { EmployeeRoleType } from 'models/Employee';
import { ValuesDoctors } from 'models/Values';

export const getDoctorsInfo = createEvent<{ siteId: string, locale: string, type: EmployeeRoleType | null }>();

export const updateDoctorsInfo = createEvent<{
    siteId: string,
    locale: string,
    values: ValuesDoctors,
    type: EmployeeRoleType | null,
}>();

export const setPhysiciansInfoSuccess = createEvent<boolean>();

export const setAdvancedProvidersInfoSuccess = createEvent<boolean>();

export const setAllDoctorsInfoSuccess = createEvent<boolean>();
