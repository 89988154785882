export const throttle = <T extends unknown[]>(callback: (...args: T) => void, delay: number): (...args: T) => void => {
    let lastFunc: ReturnType<typeof setTimeout>;
    let lastRan: number;

    return (...args) => {
        const runCallback = () => {
            callback.apply(this, args);
            lastRan = Date.now();
        };

        if (!lastRan) {
            runCallback();
        } else {
            clearTimeout(lastFunc);

            lastFunc = setTimeout(() => {
                if ((Date.now() - lastRan) >= delay) {
                    runCallback();
                }
            }, delay - (Date.now() - lastRan));
        }
    };
};
