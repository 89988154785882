import { createEffect, attach } from 'effector';
import { useStore } from 'effector-react';

import { IVideoService } from 'services/landing/video';
import { notify } from 'shared/utils/notification';
import { ValuesVideo } from 'models/Values';

import { $videoService } from '../../services';
import { $params } from '../../params';
import { getVideoSection, setVideoError } from './events';
import { emptyValuesVideo } from '../constants';

const hideVideoSection = createEffect(async (params: {
    service: IVideoService,
    siteId: string,
    locale: string,
    isHidden: boolean,
}) => {
    const { service, siteId, locale, isHidden } = params;
    const result = await service.hideVideo(siteId, locale, isHidden);

    return result.match(
        data => data,
        error => {
            setVideoError(error);

            return emptyValuesVideo;
        },
    );
});

export const wrappedHideVideo = attach({
    source: $videoService,
    mapParams: ({ siteId, locale, isHidden }, service) => ({ siteId, locale, isHidden, service }),
    effect: hideVideoSection,
});

export const $isVideoSectionHiding = wrappedHideVideo.pending;

const fetchVideoSection = createEffect(async (params: {
    service: IVideoService,
    siteId: string,
    locale: string,
}) => {
    const { service, siteId, locale } = params;

    const result = await service.getVideo(siteId, locale);

    return result.match(
        data => data,
        error => {
            setVideoError(error);

            return emptyValuesVideo;
        },
    );
});

export const wrappedFetchVideoSection = attach({
    source: $videoService,
    mapParams: ({ siteId, locale }, service) => ({ service, siteId, locale }),
    effect: fetchVideoSection,
});

export const $isVideoSectionLoading = wrappedFetchVideoSection.pending;

const updateVideo = createEffect(async (params: {
    service: IVideoService,
    siteId: string,
    locale: string,
    values: ValuesVideo,
}) => {
    const { service, siteId, locale, values } = params;

    const result = await service.editVideo(siteId, locale, values);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Section updated successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            const params = useStore($params);
            getVideoSection(params);

            return emptyValuesVideo;
        },
    );
});

export const wrappedUpdateVideo = attach({
    source: $videoService,
    mapParams: ({ siteId, locale, values }, service) => ({ siteId, locale, values, service }),
    effect: updateVideo,
});

export const $isVideoSectionUpdating = wrappedUpdateVideo.pending;
