import { createEvent } from 'effector';

import { ValuesImageBlock } from 'models/Values';

export const getImageBlockSection = createEvent<{ siteId: string; locale: string; }>();

export const updateImageBlockSection = createEvent<ValuesImageBlock>();

export const hideImageBlockSection = createEvent<boolean>();

export const setImageBlockError = createEvent<string>();

export const setImageBlockSuccess = createEvent<boolean>();
