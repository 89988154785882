import { IEmployeeApi } from 'api/employee';
import { RequestResult } from 'shared/utils/requestResult';
import { EmployeeListSettings, PagedEmployees, Employee } from 'models/Employee';

export interface IAdminEmployeeService {
    getEmployeesByRoleType: (roleType: string) => Promise<RequestResult<Employee[]>>;
    getPagedEmployees: (siteId: string, locale: string, listSettings: EmployeeListSettings) => Promise<RequestResult<PagedEmployees>>;
    getAllEmployees: (siteId: string, locale: string) => Promise<RequestResult<Employee[]>>;
    getEmployeeById: (id: number) => Promise<RequestResult<Employee>>;
    createEmployee: (siteId: string, locale: string, employee: Employee) => Promise<RequestResult<Employee>>;
    editEmployeeById: (id: number, employee: Employee, siteId: string, locale: string) => Promise<RequestResult<Employee>>;
    deleteEmployeeById: (id: number, siteId: string, locale: string) => Promise<RequestResult<boolean>>;
}

export class AdminEmployeeService implements IAdminEmployeeService {
    constructor (
        private readonly employeeApi: IEmployeeApi,
        private readonly logger: Logger,
    ) { }

    public async getEmployeesByRoleType(roleType: string): Promise<RequestResult<Employee[]>>  {
        try {
            const response = await this.employeeApi.getEmployeesByRoleType(roleType);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch employees');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch employees');
        }
    }

    public async getPagedEmployees(
        siteId: string,
        locale: string,
        listSettings: EmployeeListSettings,
    ): Promise<RequestResult<PagedEmployees>> {
        try {
            const response = await this.employeeApi.getPagedEmployees(siteId, locale, listSettings);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch employees');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch employees');
        }
    }

    public async getAllEmployees(
        siteId: string,
        locale: string,
    ): Promise<RequestResult<Employee[]>> {
        try {
            const response = await this.employeeApi.getAllEmployees(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch employees');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch employees');
        }
    }

    public async getEmployeeById(id: number): Promise<RequestResult<Employee>> {
        try {
            const response = await this.employeeApi.getEmployeeById(id);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch employee');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch employee');
        }
    }

    public async createEmployee(siteId: string, locale: string, employee: Employee): Promise<RequestResult<Employee>> {
        try {
            const response = await this.employeeApi.createEmployee(siteId, locale, employee);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to add employee');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to add employee');
        }
    }

    public async editEmployeeById(id: number, employee: Employee, siteId: string, locale: string): Promise<RequestResult<Employee>> {
        try {
            const response = await this.employeeApi.editEmployeeById(id, employee, siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit employee');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit employee');
        }
    }

    public async deleteEmployeeById(id: number, siteId: string, locale: string): Promise<RequestResult<boolean>> {
        try {
            const response = await this.employeeApi.deleteEmployeeById(id, siteId, locale);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to delete employee');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to delete employee');
        }
    }
}
