import { useState, useEffect, memo, useCallback } from 'react';
import { useStore } from 'effector-react';

import { Loader } from 'shared/components';
import { Modal } from 'shared/adminComponents';
import { useViewport } from 'shared/utils/hooks/useViewport';
import { $employeeStatus, $employeeId, getEmployee, resetEmployee, setEmployeeId } from 'store/employees';
import { GoBackButton } from 'features/GoBackButton';
import { MobileContainer } from 'features/MobileContainer';

import { Doctor } from '../../../entities/Doctor/Doctor';

const paperProps = { className: 'doctor-container' };

export const DoctorModal = memo(() => {
    const { employee, isEmployeeLoading } = useStore($employeeStatus);
    const employeeId = useStore($employeeId);

    const { isMobile } = useViewport(700);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (employeeId) {
            getEmployee(employeeId);
            setOpen(true);
        } else {
            resetEmployee();
            setOpen(false);
        }
    }, [employeeId]);

    const onClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }, []);

    const goBack = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
        event.stopPropagation();
        setEmployeeId(null);
        resetEmployee();
    }, []);

    if (isMobile) {
        return (
            <MobileContainer
                isLoading={isEmployeeLoading}
                indexOpen={employeeId}
                setIndexOpen={setEmployeeId}
            >
                <>
                    {employee &&
                        <div
                            onClick={onClick}
                            className='doctor'
                        >
                            <Doctor doctor={employee} />
                        </div>
                    }
                </>
            </MobileContainer>
        );
    }

    return (
        <Modal
            isOpen={open}
            PaperProps={paperProps}
            closeModal={goBack}
        >
            {employee ?
                <div className='doctor'>
                    <GoBackButton onClick={goBack} />
                    <div onClick={onClick}>
                        <Doctor doctor={employee} />
                    </div>
                </div>
                : isEmployeeLoading
                    ? <Loader />
                    : null
            }
        </Modal>
    );
});
