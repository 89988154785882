import { ReactElement, useContext } from 'react';

import { TextFieldProps as MuiTextFieldProps } from 'features/Form';

import { Context } from '../context';

interface EditGenericComponentProps<Fields, Field extends string>
    extends Omit<MuiTextFieldProps<Fields, Field>, 'name' | 'onChange'> {
        layout: ReactElement | null,
        quiz: ReactElement,
}

export const EditQenericComponent = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    quiz,
    layout,
}: EditGenericComponentProps<Fields, Field>) => {
    const { isEditing } = useContext(Context);

    return isEditing ? quiz : layout;
};
