import { forward, sample } from 'effector';

import { LanguageShortName } from 'models/Language';
import { $isAuthorized } from 'store/auth';
import { $languagesLookup } from 'store/language/store';

import { setLocaleIdSwitcher, setLocaleLink } from './events';
import { $localeSwitcher, $localeIdSwitcher, $locale, $localeLink } from './store';

forward({
    from: setLocaleLink,
    to: $localeLink,
});

forward({
    from: setLocaleIdSwitcher,
    to: $localeIdSwitcher,
});

sample({
    clock: $localeIdSwitcher,
    source: $languagesLookup,
    fn: (allLocales, localeId) => {
        return allLocales.find(element => element.id === +localeId)?.name ?? LanguageShortName.English;
    },
    target: $localeSwitcher,
});

forward({
    from: [$localeLink, $localeSwitcher],
    to: $locale,
});

sample({
    clock: $localeSwitcher,
    source: $isAuthorized,
    fn: (isAuthorized, localeSwitcher) => {
        isAuthorized && localStorage.setItem('locale', localeSwitcher);

        return null;
    },
});
