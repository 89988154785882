import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $textBlockSection, $textBlockSectionSuccess, $errorTextBlockSection } from './store';
import { wrappedFetchTextBlockSection, wrappedHideTextBlock, wrappedUpdateTextBlock } from './effects';
import { getTextBlockSection, hideTextBlockSection, setTextBlockError, setTextBlockSuccess, updateTextBlockSection } from './events';

sample({
    clock: hideTextBlockSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideTextBlock,
});

forward({
    from: wrappedHideTextBlock.doneData,
    to: $textBlockSection,
});

sample({
    clock: getTextBlockSection,
    source: $params,
    target: wrappedFetchTextBlockSection,
});

forward({
    from: wrappedFetchTextBlockSection.doneData,
    to: $textBlockSection,
});

sample({
    clock: updateTextBlockSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateTextBlock,
});

forward({
    from: wrappedUpdateTextBlock.doneData,
    to: $textBlockSection,
});

forward({
    from: setTextBlockError,
    to: $errorTextBlockSection,
});

forward({
    from: setTextBlockSuccess,
    to: $textBlockSectionSuccess,
});
