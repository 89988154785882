import { memo, useCallback } from 'react';
import { useFormikContext } from 'formik';

import { ButtonProps as MuiButtonProps } from 'shared/adminComponents/Button';
import { DeleteButton as IconDeleteButton } from 'shared/adminComponents/IconButtons';

export interface DeleteButtonProps<_, Field extends string> extends Omit<MuiButtonProps, 'name'> {
    name: Field;
    elementKey: number;
}

export const DeleteButton = memo(<
    Fields extends { [key in Field]: ElementType[] },
    Field extends string,
    ElementType extends { id: number },
>({
    name,
    elementKey,
    ...restProps
}: DeleteButtonProps<Fields, Field>) => {
    const { values, setFieldValue } = useFormikContext<Fields>();

    const onClick = useCallback(() => {
        setFieldValue(name, values[name].filter(element => element.id !== elementKey));
    }, [setFieldValue, values, name, elementKey]);

    return (
        <IconDeleteButton
            {...restProps}
            onClick={onClick}
        />
    );
});
