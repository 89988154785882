import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { ButtonProps as MuiButtonProps } from 'shared/adminComponents/Button';
import { insertElement } from 'shared/utils/array';
import { AddButton } from 'shared/adminComponents';

export interface AddButtonIconProps<_, Field extends string, AddElement>
    extends Omit<MuiButtonProps, 'name' | 'onChange'> {
        name: Field;
        index?: number;
        getInitialElement: () => AddElement;
}

export function AddButtonIcon<
    Fields extends { [key in Field]: AddElement[] },
    Field extends string,
    AddElement,
>({
    name,
    index,
    getInitialElement,
    ...restProps
}: AddButtonIconProps<Fields, Field, AddElement>) {
    const { values, setFieldValue } = useFormikContext<Fields>();

    const onClick = useCallback(() => {
        const addElement = getInitialElement();

        setFieldValue(
            name,
            index !== undefined
                ? insertElement(values[name], index, addElement)
                : [...values[name], addElement],
        );
    }, [setFieldValue, values, name, index, getInitialElement]);

    return (
        <AddButton
            {...restProps}
            onClick={onClick}
        />
    );
}
