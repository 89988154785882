import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    ButtonVariant,
} from 'shared/adminComponents/Button';
import { insertElement } from 'shared/utils/array';

export interface AddButtonProps<_, Field extends string, AddElement>
    extends Omit<MuiButtonProps, 'name' | 'onChange'> {
        name: Field;
        index?: number;
        getInitialElement: () => AddElement;
}

export function AddButton<
    Fields extends { [key in Field]: AddElement[] },
    Field extends string,
    AddElement,
>({
    name,
    index,
    getInitialElement,
    ...restProps
}: AddButtonProps<Fields, Field, AddElement>) {
    const { values, setFieldValue } = useFormikContext<Fields>();

    const onClick = useCallback(() => {
        const addElement = getInitialElement();

        setFieldValue(
            name,
            index !== undefined
                ? insertElement(values[name], index, addElement)
                : [...values[name], addElement],
        );
    }, [setFieldValue, values, name, index, getInitialElement]);

    return (
        <MuiButton
            id={name}
            name={name}
            variant={ButtonVariant.Outlined}
            onClick={onClick}
            {...restProps}
        />
    );
}
