import { ButtonType } from 'models/Button';

export type SlideType = {
    id: number;
    bodyText: string;
    imageFooterText?: string;
    bodyHeader?: string;
    image?: string;
    sign?: string;
    button?: ButtonType;
}

export enum SlideKey {
    ImageFooterText = 'imageFooterText',
    BodyHeader = 'bodyHeader',
    BodyText = 'bodyText',
    Image = 'image',
    Sign = 'sign',
}
