import {
    Pagination as MuiPagination,
    PaginationProps as MuiPaginationProps,
} from '@mui/material';

import './Pagination.scss';

export interface PaginationProps extends Omit<MuiPaginationProps, 'open'> {
}

export function Pagination({
    ...restProps
}: PaginationProps) {
    return (
        <MuiPagination
            {...restProps}
            classes={{
                root: 'mmc-pagination',
                ul: 'mmc-pagination-ul',
            }}
        />
    );
}
