import { useState, useRef, useCallback, useMemo, memo } from 'react';
import { useStore } from 'effector-react';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    PercentCrop,
} from 'react-image-crop';

import { Button, ButtonVariant, LoadingButton } from 'shared/adminComponents';
import { notify } from 'shared/utils/notification';
import { resizeFile, dataUrlToBlob } from 'shared/utils/file';
import { canvasPreview } from 'features/Form/UploadButton/canvasPreview';
import { $isImageLoading, uploadImage } from 'store/image';
import { FileResult } from 'models/File';

import './ImageCropDialog.scss';

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            { unit: '%', width: 90 },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

const getImageTypeAbbreviation = (imageType: string) => {
    switch (imageType) {
        case 'image/jpeg':
            return 'jpeg';
        case 'image/png':
            return 'png';
        case 'image/svg-xml':
            return 'svg';
    }
};

interface ImageCropDialogProps<_, Field> {
    base64: string;
    onClose: () => void;
    imgWidth: number;
    imgHeight: number;
    name: Field;
    setFieldValue: (value: string) => void;
    setUrl: (url: string) => void;
    imageType: string;
    cropWindowMinWidth?: number;
    cropWindowMinHeight?: number;
}

export const ImageCropDialog = memo(<
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    base64,
    onClose,
    imgWidth,
    imgHeight,
    name,
    setUrl,
    setFieldValue,
    imageType,
    cropWindowMinWidth,
    cropWindowMinHeight,
}: ImageCropDialogProps<Fields, Field>) => {

    const isImageLoading = useStore($isImageLoading);

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const [aspect] = useState<number>(imgWidth / imgHeight);
    const [rotate, setRotate] = useState(0);

    const imgRef = useRef<HTMLImageElement>(null);

    const style = useMemo(() => ({ transform: `rotate(${rotate}deg)` }), [rotate]);

    const onImageLoad = useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = event.currentTarget;

            setCrop(centerAspectCrop(width, height, aspect));
        }
    }, [aspect]);

    const handleModalOk = useCallback(async () => {
        const uploadFileToStorage = async (blob: File | Blob) => {
            const type = getImageTypeAbbreviation(imageType);
            let file = new File([blob], `image_${type}`, { type: imageType });

            if (type === 'jpeg') {
                file = await resizeFile(file, imgWidth, imgHeight, type);
            }

            const result: FileResult = await uploadImage({ file });

            if (result) {
                setUrl(result.absoluteUri);
                setFieldValue(result.fileName);
            }
            onClose();
        };

        if (imageType === 'image/jpeg') {
            const canvas = document.createElement('canvas');

            if (imgRef.current && completedCrop) {
                canvasPreview(imgRef.current, canvas, completedCrop, rotate);
            }

            const minWidth = cropWindowMinWidth ?? imgWidth;
            const minHeight = cropWindowMinHeight ?? imgHeight;

            if (canvas.width < minWidth || canvas.height < minHeight) {
                notify({
                    type: 'danger',
                    message: 'Poor quality, increase the crop window of the picture or choose picture of higher quality',
                });

                return;
            }

            canvas.toBlob((blob: Blob | null) => blob && void uploadFileToStorage(blob), 'image/jpeg', 1);
        } else if (imageType === 'image/png' || imageType === 'image/svg+xml') {
            const blob = await dataUrlToBlob(base64);

            await uploadFileToStorage(blob);
        }
    }, [
        base64, completedCrop, cropWindowMinHeight, cropWindowMinWidth,
        imageType, imgHeight, imgWidth, onClose, rotate, setFieldValue, setUrl,
    ]);

    const cropOnChange = useCallback((_: PixelCrop, percentCrop: PercentCrop) => {
        setCrop(percentCrop);
    }, []);

    const rotateOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRotate(Math.min(360, Math.max(-180, +event.target.value)));
    }, []);

    return (
        <div className='image-dialog'>
            {imageType === 'image/jpeg'
                ? <>
                    <div className='image-dialog__heading'>Crop and rotate image</div>
                    {!!base64 && (
                        <ReactCrop
                            crop={crop}
                            onChange={cropOnChange}
                            onComplete={setCompletedCrop}
                            aspect={aspect}
                            className='image-dialog__image'
                        >
                            <img
                                ref={imgRef}
                                alt='Crop me'
                                src={base64}
                                style={style}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    <div className='Crop-Controls image-dialog__controls'>
                        <div>
                            <label htmlFor='rotate-input'>Rotate:</label>
                            <input
                                id='rotate-input'
                                type='range'
                                min={0}
                                max={360}
                                value={rotate}
                                disabled={!base64}
                                onChange={rotateOnChange}
                            />
                        </div>
                    </div>
                </>
                : <img
                    alt='for upload'
                    src={base64}
                />
            }
            <div className='image-dialog__buttons'>
                <Button
                    variant={ButtonVariant.Outlined}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={isImageLoading}
                    variant={ButtonVariant.Contained}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleModalOk}
                >
                    Ok
                </LoadingButton>
            </div>
        </div>
    );
});
