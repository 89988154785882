import { HttpMethods } from 'api/constants';
import { getFilteredQuery } from 'api/utils';
import { Employee, EmployeeListSettings } from 'models/Employee';

import { IEmployeeApi } from './interfaces';
import { EMPLOYEES_URL } from './constants';

export class EmployeeApi implements IEmployeeApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getPagedEmployees(siteId: string, locale: string, listSettings: EmployeeListSettings) {
        const queryList = {
            ...listSettings,
            siteId,
            locale,
        };

        const response = await this.fetch(`${EMPLOYEES_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async getAllEmployees(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EMPLOYEES_URL}/all${getFilteredQuery(queryList)}`);

        return response;
    }

    public async getEmployeesByRoleType(roleType: string) { //?
        const response = await this.fetch(`${EMPLOYEES_URL}/type?siteId=1&roleType=${roleType}`);

        return response;
    }

    public async getEmployeeById(id: number) {
        const response = await this.fetch(`${EMPLOYEES_URL}/${id}`);

        return response;
    }

    public async createEmployee(siteId: string, locale: string, employee: Employee) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EMPLOYEES_URL}${getFilteredQuery(queryList)}`, {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(employee),
            },
        );

        return response;
    }

    public async editEmployeeById(id: number, employee: Employee, siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EMPLOYEES_URL}/${id}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(employee),
            },
        );

        return response;
    }

    public async deleteEmployeeById(id: number, siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${EMPLOYEES_URL}/${id}${getFilteredQuery(queryList)}`, {
            method: HttpMethods.Delete,
        });

        return response;
    }
}
