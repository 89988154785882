import { createStore, combine } from 'effector';

import { ExternalLink } from 'models/Module';

import { $areExternalLinksLoading } from './effects';

export const $externalLinks = createStore<ExternalLink[]>([]);

export const $externalLinksStatus = combine({
    externalLinks: $externalLinks,
    areExternalLinksLoading: $areExternalLinksLoading,
});
