import { HttpMethods } from 'api/constants';
import { getFilteredQuery } from 'api/utils';
import { Contact } from 'models/Contact/Contact';


import { IContactApi } from './interfaces';
import { CONTACT_URL } from './constants';

export class ContactApi implements IContactApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async contactUs(siteId: string, locale: string, contact: Contact) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${CONTACT_URL}${getFilteredQuery(queryList)}`, {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contact),
            },
        );

        return response;
    }
}
