import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import { FormHelperText } from '@mui/material';
import { Field } from 'formik';
import cn from 'classnames';

import { useNestedField } from '../useNestedField';
import { toolbarOptions } from './constants';

import 'react-quill/dist/quill.snow.css';
import './Layout.scss';

export interface LayoutProps<_, Field extends string>
    extends Omit<ReactQuillProps, 'name'> {
    name: Field;
    chain?: (string | number)[];
}

const modules = { toolbar: toolbarOptions };

export const Layout = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    chain = [],
    className,
    placeholder,
    ...restProps
}: LayoutProps<Fields, Field>) => {

    const { field, valueLocal, setFieldValueLocal, helperText, handleBlur } = useNestedField({ name, chain });

    return (
        <Field name={field}>
            {() => (
                <div className={cn('custom-editor', className)}>
                    <ReactQuill
                        {...restProps}
                        id={field}
                        className='custom-editor__content'
                        theme='snow'
                        value={valueLocal}
                        onChange={setFieldValueLocal}
                        onBlur={() => {
                            handleBlur({ target: { name: field } });
                        }}
                        placeholder={placeholder ?? 'Write something'}
                        modules={modules}
                    />
                    <FormHelperText className='custom-editor__error' id={field}>{helperText}</FormHelperText>
                </div>
            )}
        </Field>
    );
};

const Link = Quill.import('formats/link');

class MyLink extends Link {
    static create(value: string) {
        const node = super.create(value);

        const host = localStorage.getItem('host');

        value = this.sanitize(value);
        node.setAttribute('href', value);

        if (host && value.includes(host)) {
            node.removeAttribute('target');
            node.removeAttribute('rel');
        }

        return node;
    }
}

Quill.register(MyLink, true);
