import { HttpMethods } from 'api/constants';
import { ValuesTeam } from 'models/Values';

import { ITeamApi } from './interfaces';
import { TEAM_URL } from './constants';
import { getFilteredQuery, getNotFilteredQuery } from '../../utils';

export class TeamApi implements ITeamApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async hideTeam(siteId: string, locale: string, isHidden: boolean) {
        const queryList = {
            siteId,
            locale,
            isHidden,
        };

        const response = await this.fetch(`${TEAM_URL}/Hide${getNotFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response;
    }

    public async getTeam(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${TEAM_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async editTeam(siteId: string, locale: string, teamValues: ValuesTeam) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${TEAM_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(teamValues),
            },
        );

        return response;
    }
}
