import { HttpMethods } from 'api/constants';
import { getFilteredQuery } from 'api/utils';
import { ValuesMedia } from 'models/Values/Blocks';

import { IMediaApi } from './interfaces';
import { MEDIA_URL } from './constants';


export class MediaApi implements IMediaApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getMedia(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${MEDIA_URL}/files${getFilteredQuery(queryList)}`);

        return response;
    }

    public async getSharedMedia(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${MEDIA_URL}/shared${getFilteredQuery(queryList)}`);

        return response;
    }

    public async createMedia(siteId: string, locale: string, media: ValuesMedia) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${MEDIA_URL}/file${getFilteredQuery(queryList)}`, {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(media),
            },
        );

        return response;
    }

    public async editMedia(siteId: string, locale: string, media: ValuesMedia[]) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${MEDIA_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(media),
            },
        );

        return response;
    }

    public async addSharedMediaToSite(siteId: string, locale: string, mediaId: number) {
        const queryList = {
            mediaId,
            siteId,
            locale,
        };

        const response = await this.fetch(`${MEDIA_URL}/shared${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response;
    }

    public async deleteMedia(id: number, siteId: string) {
        const queryList = {
            siteId,
            id,
        };

        const response = await this.fetch(`${MEDIA_URL}${getFilteredQuery(queryList)}`, {
            method: HttpMethods.Delete,
        });

        return response;
    }

    public async deleteMediaFromSite(mediaId: number, siteId: string, locale: string) {
        const queryList = {
            mediaId,
            siteId,
            locale,
        };

        const response = await this.fetch(`${MEDIA_URL}/file-for-site${getFilteredQuery(queryList)}`, {
            method: HttpMethods.Delete,
        });

        return response;
    }
}
