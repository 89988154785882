import { EditQenericComponent } from 'features/EditPage';
import { Button, ButtonVariant } from 'shared/adminComponents/Button';

import './ActionButtons.scss';

interface ActionButtonsProps {
    onSave: () => void;
    onEdit: () => void;
    onCancel: () => void;
}

export const ActionButtons = ({ onSave, onEdit, onCancel }: ActionButtonsProps) => {
    return (
        <div className='action-buttons'>
            <div className='action-buttons__container container'>
                <EditQenericComponent
                    layout={
                        <Button
                            key='edit'
                            variant={ButtonVariant.Outlined}
                            onClick={onEdit}
                            className='action-buttons__edit-button'
                        >
                            Edit
                        </Button>
                    }
                    quiz={
                        <>
                            <Button
                                key='cancel'
                                variant={ButtonVariant.Outlined}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                buttonClassName='action-buttons__save-button'
                                variant={ButtonVariant.Contained}
                                onClick={onSave}
                            >
                                Save
                            </Button>
                        </>
                    }
                />
            </div>
        </div>
    );
};
