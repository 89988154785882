import { memo, ReactElement } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import { ButtonProps as MuiButtonProps } from '../../Button';

import './InfoButton.scss';

interface InfoButtonProps extends MuiButtonProps {
    render: ReactElement;
    className?: string;
}

export const InfoButton = memo(({
    render,
    className,
    ...restProps
}: InfoButtonProps) => {
    return (
        <div className='info'>
            {render}
            <IconButton
                {...restProps}
                aria-label='info'
                className={cn('info__button', className)}
            >
                <InfoIcon />
            </IconButton>
        </div>
    );
});
