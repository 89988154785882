import { IFooterApi } from 'api/footer';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesFooter } from 'models/Values/Blocks/Landing';

export interface IFooterService {
    hideFooter: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesFooter>>;
    getFooter: (siteId: string, locale: string) => Promise<RequestResult<ValuesFooter>>;
    editFooter: (siteId: string, locale: string, footerValues: ValuesFooter) => Promise<RequestResult<ValuesFooter>>;
}

export class FooterService implements IFooterService {
    constructor (
        private readonly footerApi: IFooterApi,
        private readonly logger: Logger,
    ) { }

    public async hideFooter(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesFooter>> {
        try {
            const response = await this.footerApi.hideFooter(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide Footer Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide Footer');
        }
    }

    public async getFooter(siteId: string, locale: string): Promise<RequestResult<ValuesFooter>> {
        try {
            const response = await this.footerApi.getFooter(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Footer');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Footer');
        }
    }

    public async editFooter(siteId: string, locale: string, footerValues: ValuesFooter): Promise<RequestResult<ValuesFooter>> {
        try {
            const response = await this.footerApi.editFooter(siteId, locale, footerValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Footer');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Footer');
        }
    }
}
