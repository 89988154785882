import { ISolutionApi } from 'api/landing/solution';
import { ValuesSolution } from 'models/Values/Blocks/Landing/ValuesWithSlider';
import { RequestResult } from 'shared/utils/requestResult';

export interface ISolutionService {
    hideSolution: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesSolution>>;
    getSolution: (siteId: string, locale: string) => Promise<RequestResult<ValuesSolution>>;
    editSolution: (siteId: string, locale: string, solutionValues: ValuesSolution) => Promise<RequestResult<ValuesSolution>>;
}

export class SolutionService implements ISolutionService {
    constructor (
        private readonly solutionApi: ISolutionApi,
        private readonly logger: Logger,
    ) { }

    public async hideSolution(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesSolution>> {
        try {
            const response = await this.solutionApi.hideSolution(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide Solution Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide Solution');
        }
    }

    public async getSolution(siteId: string, locale: string): Promise<RequestResult<ValuesSolution>> {
        try {
            const response = await this.solutionApi.getSolution(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get Solution Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get Solution Section');
        }
    }

    public async editSolution(siteId: string, locale: string, solutionValues: ValuesSolution): Promise<RequestResult<ValuesSolution>> {
        try {
            const response = await this.solutionApi.editSolution(siteId, locale, solutionValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit Solution Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit Solution Section');
        }
    }
}
