import { iNotification, Store } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';

const defaultOptions: iNotification = {
    container: 'bottom-right',
    dismiss: {
        click: true,
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
        showIcon: true,
        touch: true,
    },
};

export type NotificationOptions = Omit<iNotification, 'container'>;

export const notify = (options: NotificationOptions) => {
    Store.addNotification({
        ...defaultOptions,
        ...options,
    });
};
