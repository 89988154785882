import { createStore, combine } from 'effector';

import { $isLandingLoading } from './effects';
import { $headerSection } from './header';
import { $teamSection } from './team';
import { $videoSection } from './video';
import { $solutionSection } from './solution';
import { $textBlockSection } from './textBlock';
import { $imageBlockSection } from './imageBlock';
import { $publicationsSection } from './publications';
import { $footerSection } from '../footer';

export const $landing = combine({
    header: $headerSection,
    textBlock: $textBlockSection,
    imageBlock: $imageBlockSection,
    videoBlock: $videoSection,
    team: $teamSection,
    publicationsSlider: $publicationsSection, 
    solutionSlider: $solutionSection,
    footer: $footerSection,
});

export const $errorLanding = createStore('');

export const $landingSuccess = createStore(false);

export const $landingScrollPosition = createStore(0);

export const $landingGetStatus = combine({
    landing: $landing,
    isLandingLoading: $isLandingLoading,
    landingSuccess: $landingSuccess,
    errorLanding: $errorLanding,
});
