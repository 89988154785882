import { HttpMethods } from 'api/constants';
import { MenuItem } from 'models/Menu';

import { IMenuApi } from './interfaces';
import { MENU_URL } from './constants';
import { getFilteredQuery } from '../utils';

export class MenuApi implements IMenuApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getMenu(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${MENU_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async editMenu(siteId: string, locale: string, menuValues: MenuItem[]) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${MENU_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(menuValues),
            },
        );

        return response;
    }
}
