export type ButtonType = {
    id: number;
    text: string,
    link: string,
};

export enum ButtonKey {
    Text = 'text',
    Link = 'link',
}
