import { useStore } from 'effector-react';

import { $isAuthorized, $storageProvider } from 'store/auth';
import { Locale } from 'entities/Locale';
import { SiteId } from 'entities/SiteId';

import { Button, ButtonSize, ButtonVariant } from '../Button';
import { AdminMenu } from './AdminMenu';

import './Header.scss';

export const AdminHeader = () => {
    const isAuthorized = useStore($isAuthorized);
    const storageProvider = useStore($storageProvider);

    return (
        <div className='admin-header'>
            <div className='admin-header__container'>
                {isAuthorized && (
                    <>
                        <div className='admin-header__menu-wrapper'>
                            <AdminMenu />
                            <Button
                                className='admin-header__login-button'
                                variant={ButtonVariant.Outlined}
                                size={ButtonSize.Small}
                                onClick={() => void storageProvider.onLogOut()}
                            >
                                Logout
                            </Button>
                        </div>
                        <div className='admin-header__actions-wrapper'>
                            <SiteId />
                            <Locale />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
