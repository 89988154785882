import { TourType } from 'models/Tour';

import { PrivateRoute } from './Routes';

export interface NavigationLink {
    link: string;
    displayName: string;
    nestedElements?: NavigationLink[];
}

export const enum Link {
    CareTeam = '/#careteam',
    About = '/#about',
    LocalCare = '/#localcare',
    AfterCare = '/#aftercare',
    PatientSurvey = '/#patientsurvey',
    Reviews = '/#reviews',
    Faqs = '/other/2',
    Map = '/#map',
}

export interface TourLink {
    displayName: string;
    tourType: TourType;
}

export const requiredPagesNavigationLinks: NavigationLink[] = [
    { link: PrivateRoute.Landing, displayName: 'Landing Page' },
    // { link: PrivateRoute.Physicians, displayName: 'Physicians Page'  },
    // { link: PrivateRoute.AdvancedProviders, displayName: 'Advanced Providers Page' },
    { link: PrivateRoute.AllDoctors, displayName: 'All Providers Page' },
];

export const tourNavigationLinks: TourLink[] = [
    { displayName: 'Add Language', tourType: TourType.Language },
    { displayName: 'Change Texts and Images', tourType: TourType.Edit },
    { displayName: 'Support Pages and Links', tourType: TourType.Links },
    { displayName: 'Add Nav Bar', tourType: TourType.Menu },
];

export const adminNavigationLinks: NavigationLink[] = [
    { link: PrivateRoute.Settings, displayName: 'Settings' },
    { link: 'admin/required', displayName: 'Required Pages', nestedElements: requiredPagesNavigationLinks },
    { link: PrivateRoute.Employees, displayName: 'Provider Profiles' },
    { link: PrivateRoute.AddOther, displayName: 'Support Pages' },
    { link: PrivateRoute.ExternalLinks, displayName: 'Support Links' },
    { link: PrivateRoute.AddMedia, displayName: 'Media Library' },
    { link: PrivateRoute.Menu, displayName: 'Nav Bar' },
];
