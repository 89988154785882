import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    children: ReactNode;
    id?: string;
}

export const Portal = ({
    children,
    id = 'root',
}: PortalProps) => {
    const element = document.getElementById(id) || document.body;

    return createPortal(children, element);
};
