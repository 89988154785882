import { useState, useCallback, useEffect, ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Event } from 'effector';
import cn from 'classnames';

import { CloseButton } from 'shared/adminComponents/IconButtons/CloseButton';
import { Loader, Portal } from 'shared/components';
import { PublicRoute } from 'models/Module';
import { $params } from 'store/params';

import './MobileContainer.scss';

export interface MobileContainerProps {
    className?: string;
    children: ReactNode;
    indexOpen: string | number | null;
    setIndexOpen?: React.Dispatch<React.SetStateAction<number | null>> | Event<number | null>;
    isLoading?: boolean;
    goBackHandle?: boolean;
}

export const MobileContainer = ({
    className,
    children,
    indexOpen,
    setIndexOpen,
    isLoading,
    goBackHandle,
}: MobileContainerProps) => {
    const params = useStore($params);

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const goToMainPage = useCallback((event?: React.MouseEvent) => {
        event?.stopPropagation();

        if (!goBackHandle && setIndexOpen) {
            setIndexOpen(null);
        }
    }, [goBackHandle, setIndexOpen]);

    const onClose = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();

        !goBackHandle ? goToMainPage(event) : navigate(-1);
    }, [goBackHandle, goToMainPage, navigate]);

    useEffect(() => {
        setOpen(typeof indexOpen === 'number' || typeof indexOpen === 'string');
    }, [indexOpen]);

    useEffect(() => {
        document.body.style.overflow = (open && typeof indexOpen === 'number' || typeof indexOpen === 'string')
            ? 'hidden'
            : '';

            return () => {
                document.body.style.overflow = '';
            };
    }, [open, indexOpen]);

    useEffect(() => { //check work of esc
        const onKeyDown = (event: KeyboardEvent) => {
            event.stopPropagation();

            if (event.key === 'Escape') {
                !goBackHandle ? goToMainPage() : navigate(-1);
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [goBackHandle, goToMainPage, navigate]);

    if (!open) {
        return null;
    }

    if (isLoading) {
        return (
            <Portal>
                <div className={cn('mobile-container', { isOpen: open })}>
                    <Loader />
                </div>
            </Portal>
        );
    }

    return (
        <Portal>
            <div className={cn('mobile-container', { isOpen: open })} id='mobile-container'>
                <div className='mobile-container__header mobile-container__wrapper'>
                    <Link to={`/${params.siteId}/${params.locale}/${PublicRoute.Main}`} onClick={goToMainPage} >
                        <img
                            className='mobile-container__logo'
                            src='/images/logo.jpg'
                        />
                    </Link>
                    <CloseButton
                        onClick={onClose}
                        className='mobile-container__go-back'
                    />
                </div>
                <div className={className}>
                    {children}
                </div>
            </div>
        </Portal>
    );
};
