import { forward } from 'effector';

import {
    setSignInErrorMessage,
    setIsAuthorized,
    signIn,
    updateToken,
    signInWithOkta,
} from './events';
import { wrappedSignIn, wrappedSaveTokenInfo, wrappedSignInWithOkta } from './effects';
import {
    $signInErrorMessage,
    $isAuthorized,
    $token,
} from './store';

forward({
    from: setIsAuthorized,
    to: $isAuthorized,
});

forward({
    from: setSignInErrorMessage,
    to: $signInErrorMessage,
});

forward({
    from: signIn,
    to: wrappedSignIn,
});

forward({
    from: wrappedSignIn.doneData,
    to: updateToken,
});

forward({
    from: signInWithOkta,
    to: wrappedSignInWithOkta,
});

forward({
    from: wrappedSignInWithOkta.doneData,
    to: updateToken,
});

forward({
    from: updateToken,
    to: wrappedSaveTokenInfo,
});

forward({
    from: updateToken.map(tokenInfo => tokenInfo?.Token ?? ''),
    to: $token,
});
