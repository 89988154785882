import { createStore } from 'effector';

import { ValuesImageBlock } from 'models/Values';

import { emptyValuesImageBlock } from '../constants';

export const $imageBlockSection = createStore<ValuesImageBlock>(emptyValuesImageBlock);

export const $errorImageBlockSection = createStore<string>('');

export const $imageBlockSectionSuccess = createStore(false);
