import { HttpMethods } from 'api/constants';
import { ValuesVideo } from 'models/Values';

import { IVideoApi } from './interfaces';
import { VIDEO_URL } from './constants';
import { getFilteredQuery, getNotFilteredQuery } from '../../utils';

export class VideoApi implements IVideoApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async hideVideo(siteId: string, locale: string, isHidden: boolean) {
        const queryList = {
            siteId,
            locale,
            isHidden,
        };

        const response = await this.fetch(`${VIDEO_URL}/Hide${getNotFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Put,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response;
    }

    public async getVideo(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${VIDEO_URL}${getFilteredQuery(queryList)}`);

        return response;
    }

    public async editVideo(siteId: string, locale: string, videoValues: ValuesVideo) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${VIDEO_URL}${getFilteredQuery(queryList)}`,
            {
                method: HttpMethods.Post,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(videoValues),
            },
        );

        return response;
    }
}
