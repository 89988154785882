import { MenuItemType } from 'models/Menu';
import {
    ValuesStart, ValuesImageBlock,
    ValuesPublication, ValuesTeam ,
    LandingValues, ValuesVideo,
    ValuesTextBlock,
} from 'models/Values';
import { ValuesFooter } from 'models/Values/Blocks/Landing';
import { ValuesSolution } from 'models/Values/Blocks/Landing/ValuesWithSlider';

export const emptyValuesStart: ValuesStart = {
    id: 0,
    labelText: '',
    image: '',
    mobileImage: '',
    isHidden: false,
    menuItem: {
        id: 0,
        title: '',
        link: '#home',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesTextBlock: ValuesTextBlock = {
    id: 0,
    title: '',
    text: '',
    isHidden: false,
    menuItem: {
        id: 0,
        title: '',
        link: '#about',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesImageBlock: ValuesImageBlock = {
    id: 0,
    title: '',
    image: '',
    isHidden: false,
    menuItem: {
        id: 0,
        title: 'The Big Picture',
        link: '#map',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesVideo: ValuesVideo = {
    id: 0,
    title: '',
    isHidden: false,
    link: '',
    image: '',
    menuItem: {
        id: 0,
        title: '',
        link: '#video',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesTeam: ValuesTeam = {
    id: 0,
    title: '',
    isHidden: false,
    directorId: 0,
    director: {
        id: 1,
        firstName: '',
        lastName: '',
        role: '',
        photo: [''],
    },
    director1Id: 0,
    director1: {
        id: 1,
        firstName: '',
        lastName: '',
        role: '',
        photo: [''],
    },
    director2Id: 0,
    director2: {
        id: 1,
        firstName: '',
        lastName: '',
        role: '',
        photo: [''],
    },
    menuItem: {
        id: 0,
        title: '',
        link: '#team',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesPublications: ValuesPublication = {
    id: 0,
    title: '',
    isHidden: false,
    slides: [
        {
            id: 0,
            imageFooterText: '',
            bodyText: '',
            image: '',
        },
    ],
    menuItem: {
        id: 0,
        title: 'Publications',
        link: '#publications',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesSolution: ValuesSolution = {
    id: 0,
    isHidden: false,
    title: '',
    slides: [
        {
            id: 0,
            imageFooterText: '',
            bodyText: '',
            image: '',
        },
    ],
    menuItem: {
        id: 0,
        title: '',
        link: '#solution',
        type: MenuItemType.Block,
        order: null,
    },
};

export const emptyValuesFooter: ValuesFooter = {
    id: 0,
    isHidden: false,
    menuItem: {
        id: 0,
        title: '',
        link: '#footer',
        type: MenuItemType.Block,
        order: null,
    },
    links: [
        {
            id: 0,
            link: '',
            text: '',
        },
    ],
};

export const initialValues: LandingValues = {
    header: emptyValuesStart,
    textBlock: emptyValuesTextBlock,
    imageBlock: emptyValuesImageBlock,
    videoBlock: emptyValuesVideo,
    team: emptyValuesTeam,
    publicationsSlider: emptyValuesPublications,
    solutionSlider: emptyValuesSolution,
    footer: emptyValuesFooter,
};
