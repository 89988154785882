import { memo } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './CloseButton.scss';

interface CloseButtonProps extends Omit<MuiButtonProps, 'className'> {
    className?: string;
}

export const CloseButton = memo(({
    className,
    ...restProps
}: CloseButtonProps) => {
    return (
        <IconButton
            {...restProps}
            aria-label='close'
            size={ButtonSize.Small}
            className={cn('close-button', className)}
        >
            <CloseIcon />
        </IconButton>
    );
});
