import { IProjectApi } from './interfaces';
import { PROJECT_URL } from './constants';
import { getFilteredQuery } from '../utils';

export class ProjectApi implements IProjectApi {
    constructor(
        private readonly fetch: Fetch,
    ) { }

    public async getProjects(startDate: string, endDate: string) {
        const queryList = {
            startDate,
            endDate,
        };

        const response = await this.fetch(`${PROJECT_URL}/Chart${getFilteredQuery(queryList)}`);

        return response;
    }

    public async getHealthStatuses(startDate: string, endDate: string) {
        const queryList = {
            startDate,
            endDate,
        };

        const response = await this.fetch(`${PROJECT_URL}/HealthChecks${getFilteredQuery(queryList)}`);

        return response;
    }

    public async getWavesHealthCheck(startDate: string, endDate: string, showAllFeatures: boolean) {
        const queryList = {
            startDate,
            endDate,
            showAllFeatures,
        };

        const response = await this.fetch(`${PROJECT_URL}/WaveHealthCheck${getFilteredQuery(queryList)}`);

        return response;
    }
}
