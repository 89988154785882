import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import JoyRide, { CallBackProps, EVENTS, STATUS } from 'react-joyride';
import cn from 'classnames';

import { PrivateRoute } from 'models/Module';
import { tourNavigationLinks } from 'models/Module/NavigationLink';
import { Actions, TourType } from 'models/Tour';
import { $languagesLookup, $siteLanguages } from 'store/language/store';

import { useTourContext } from './context/TourContext';
import { LOCALE, STYLES } from './constants';

export const Tour = () => {
    const siteLanguages = useStore($siteLanguages);
    const languagesLookup = useStore($languagesLookup);

    const { tourState, dispatch } = useTourContext();

    const [tourType, setTourType] = useState<TourType>(TourType.Language);

    const navigate = useNavigate();

    const handleTours = (data: CallBackProps) => {
        const { action, index, type, status } = data;

        if (
            action === Actions.Close ||
            (status === STATUS.SKIPPED && tourState[tourType].run) ||
            status === STATUS.FINISHED
        ) {
            dispatch({ type: Actions.Stop, tourType });

            return;
        }

        if (type === EVENTS.STEP_AFTER) {
            if (tourType === TourType.Language) {
                switch (index) {
                    case 0:
                        dispatch({ type: Actions.Redirect, tourType });
                        navigate(`/${PrivateRoute.Settings}`);

                        return;
                }
            } else if (tourType === TourType.Edit) {
                switch (index) {
                    case 0:
                        dispatch({ type: Actions.Redirect, tourType });
                        navigate(`/${PrivateRoute.Landing}`);

                        return;
                    case 1:
                        if (action === Actions.Next) {
                            dispatch({ type: Actions.RunCode, tourType });

                            return;
                        }

                        break;
                    case 2:
                        if (action === Actions.Prev) {
                            dispatch({ type: Actions.RunCode, tourType });

                            return;
                        }
                }
            } else if (tourType === TourType.Links) {
                switch (index) {
                    case 0:
                        dispatch({ type: Actions.Redirect, tourType });
                        navigate(`/${PrivateRoute.AddOther}`);

                        return;
                    case 4:
                        dispatch({ type: Actions.Redirect, tourType });
                        navigate(action === Actions.Prev ? `/${PrivateRoute.AddOther}` : `/${PrivateRoute.ExternalLinks}`);

                        return;
                }
            } else if (tourType === TourType.Menu) {
                switch (index) {
                    case 0:
                        dispatch({ type: Actions.Redirect, tourType });
                        navigate(`/${PrivateRoute.Menu}`);

                        return;
                }
            }
        }

        if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            dispatch({
                type: Actions.NextOrPrev,
                payload: { stepIndex: +index + (action === Actions.Prev ? -1 : 1) },
                tourType,
            });
        }
    };

    const startTour = (tourType: TourType) => {
        setTourType(tourType);
        dispatch({ type: Actions.Restart, tourType });
    };

    return (
        <>
            <li className='admin-menu__item'>
                <a>Tutorial</a>
                <ul className='admin-menu__submenu submenu'>
                    {siteLanguages.length
                        ? tourNavigationLinks.map(element => (
                            <li
                                className={cn('submenu__item',
                                    {
                                        'hide-element':
                                            element.displayName === 'Add Language' &&
                                            siteLanguages.length === languagesLookup.length,
                                    },
                                )}
                                key={element.displayName}
                                onClick={() => startTour(element.tourType)}
                            >
                                <a>{element.displayName}</a>
                            </li>
                        ))
                        : <li
                            className='submenu__item'
                            onClick={() => startTour(TourType.Language)}
                        >
                            <a>Add Language</a>
                        </li>
                    }
                </ul>
            </li>
            <JoyRide
                {...tourState[tourType]}
                callback={handleTours}
                showSkipButton={true}
                showProgress={true}
                styles={STYLES}
                locale={LOCALE}
                disableScrollParentFix={true}
                disableScrolling={true}
            />
        </>
    );
};
