import { memo } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { ButtonProps as MuiButtonProps, ButtonSize } from '../../Button';

import './DeleteButton.scss';

interface DeleteButtonProps extends Omit<MuiButtonProps, 'className'> {
    className?: string;
}

export const DeleteButton = memo(({
    className,
    ...restProps
}: DeleteButtonProps) => {
    return (
        <IconButton
            {...restProps}
            aria-label='delete'
            size={ButtonSize.Small}
            className={cn('delete-button', className)}
        >
            <DeleteIcon />
        </IconButton>
    );
});
