import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $errorVideoSection, $videoSection } from './store';
import { wrappedFetchVideoSection, wrappedUpdateVideo, wrappedHideVideo } from './effects';
import { getVideoSection, setVideoError, updateVideoSection, hideVideoSection } from './events';

sample({
    clock: hideVideoSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHideVideo,
});

forward({
    from: wrappedHideVideo.doneData,
    to: $videoSection,
});

forward({
    from: getVideoSection,
    to: wrappedFetchVideoSection,
});

forward({
    from: wrappedFetchVideoSection.doneData,
    to: $videoSection,
});

sample({
    clock: updateVideoSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdateVideo,
});

forward({
    from: wrappedUpdateVideo.doneData,
    to: $videoSection,
});

forward({
    from: setVideoError,
    to: $errorVideoSection,
});
