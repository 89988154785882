import { createStore } from 'effector';

import { ValuesTextBlock } from 'models/Values';

import { emptyValuesTextBlock } from '../constants';

export const $textBlockSection = createStore<ValuesTextBlock>(emptyValuesTextBlock);

export const $errorTextBlockSection = createStore<string>('');

export const $textBlockSectionSuccess = createStore(false);
