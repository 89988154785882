import { createStore, forward, sample } from 'effector';

import { $params } from 'store/params';
import { $landing } from 'store/landing/store';
import { $otherPages } from 'store/other/store';
import { $externalLinks } from 'store/externalLink/store';
import { $advancedProvidersInfo, $allDoctorsInfo, $physiciansInfo } from 'store/doctorsInfo';
import { $isAuthorized } from 'store/auth';

import { $menu, $errorMenu, $successMenu } from './store';
import { wrappedFetchMenu, wrappedUpdateMenu } from './effects';
import { getMenu, setMenuError, setMenuSuccess, updateMenu } from './events';

sample({
    clock: getMenu,
    source: $params,
    filter: ({ siteId, locale }) => !!siteId && !!locale,
    target: wrappedFetchMenu,
});

forward({
    from: wrappedFetchMenu.doneData,
    to: $menu,
});

forward({
    from: updateMenu,
    to: wrappedUpdateMenu,
});

forward({
    from: wrappedUpdateMenu.doneData,
    to: $menu,
});

forward({
    from: wrappedUpdateMenu.doneData,
    to: $menu,
});

forward({
    from: setMenuError,
    to: $errorMenu,
});

forward({
    from: setMenuSuccess,
    to: $successMenu,
});

sample({
    clock: $params,
    source: createStore(false),
    target: $successMenu,
});

sample({
    clock: [$landing, $otherPages, $externalLinks, $allDoctorsInfo, $physiciansInfo, $advancedProvidersInfo],
    source: $isAuthorized,
    filter: isAuthorized => isAuthorized,
    fn: () => false,
    target: $successMenu,
});
