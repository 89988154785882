import { ISiteApi } from 'api/site';
import { RequestResult } from 'shared/utils/requestResult';
import { Site } from 'models/Site';
import { MenuPosition } from 'models/Menu';

export interface ISiteService {
    getAllSites: () => Promise<RequestResult<Site[]>>;
    getUserSites: () => Promise<RequestResult<Site[]>>;
    getSiteById: (id: string) => Promise<RequestResult<Site>>;
    updateMenuPosition: (siteId: string, menuPosition: MenuPosition) => Promise<RequestResult<Site>>;
    cloneSite: (siteId: number) => Promise<RequestResult<boolean>>;
}

export class SiteService implements ISiteService {
    constructor(
        private readonly siteApi: ISiteApi,
        private readonly logger: Logger,
    ) { }

    public async getAllSites(): Promise<RequestResult<Site[]>> {
        try {
            const response = await this.siteApi.getAllSites();
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch sites');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch sites');
        }
    }

    public async getUserSites(): Promise<RequestResult<Site[]>> {
        try {
            const response = await this.siteApi.getUserSites();
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch sites');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch sites');
        }
    }

    public async getSiteById(id: string): Promise<RequestResult<Site>> {
        try {
            const response = await this.siteApi.getSiteById(id);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch site');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to fetch site');
        }
    }

    public async updateMenuPosition(siteId: string, menuPosition: MenuPosition): Promise<RequestResult<Site>> {
        try {
            const response = await this.siteApi.updateMenuPosition(siteId, menuPosition);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to update menu position');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to update menu position');
        }
    }

    public async cloneSite(siteId: number): Promise<RequestResult<boolean>> {
        try {
            const response = await this.siteApi.cloneSite(siteId);

            return response.ok
                ? RequestResult.createSuccessful(true)
                : RequestResult.createUnsuccessful('Failed to clone site');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to clone site');
        }
    }
}
