import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { parse } from 'query-string';
import { useStore } from 'effector-react';

import { fetchUserSites } from 'store/site';
import { $signInErrorMessage, signInWithOkta } from 'store/auth';
import { PrivateRoute } from 'models/Module';
import { authLinks } from 'shared/utils/links';

export const SignIn = () => {
    const location = useLocation();
    const errorMessage = useStore($signInErrorMessage);
    const oktaIdToken = useMemo(() => parse(location.hash)?.id_token as string, [location]);
    const oktaAccessToken = useMemo(() => parse(location.hash)?.access_token as string, [location]);
    const navigate = useNavigate();

    useEffect(() => {
        const signInHandler = async () => {
            const result = await signInWithOkta({ oktaAccessToken, oktaIdToken });

            if (result) {
                const availableSites = await fetchUserSites();
                navigate(`/${PrivateRoute.Settings}`);
            }
            else {
                navigate(`${authLinks.signIn}`);
            }
        };

        void signInHandler();
    }, [navigate, oktaAccessToken, oktaIdToken]);

    return (
        <div className='okta-sign-in'>
            {errorMessage ? <Typography>{errorMessage}</Typography> : <CircularProgress />}
        </div>
    );
};
