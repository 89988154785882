import { createEvent } from 'effector';

import { ValuesFooter } from 'models/Values/Blocks/Landing';

export const getFooterSection = createEvent();

export const updateFooterSection = createEvent<ValuesFooter>();

export const hideFooterSection = createEvent<boolean>();

export const setFooterSectionError = createEvent<string>();
