import { createEvent } from 'effector';

import { ValuesTextBlock } from 'models/Values';

export const getTextBlockSection = createEvent<{ siteId: string; locale: string; }>();

export const updateTextBlockSection = createEvent<ValuesTextBlock>();

export const hideTextBlockSection = createEvent<boolean>();

export const setTextBlockError = createEvent<string>();

export const setTextBlockSuccess = createEvent<boolean>();
