import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from '@mui/material';

interface useNestedFieldProps <Field extends string>{
    name: Field;
    chain: (string | number)[];
}

export const useNestedField = <
    Fields extends { [key in Field]: unknown },
    Field extends string,
>({
    name,
    chain,
}: useNestedFieldProps<Field>) => {

    const field = chain.length
        ? `${name}.${chain.join('.')}`
        : `${name}`;

    const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext<Fields>();

    const [valueLocal, setFieldValueLocal] = useState(
        chain.reduce((previousValue, currentValue) => previousValue[currentValue as keyof object], values[name]) as string,
    );

    const hasError = touched[name] && errors[name];

    const helperText = hasError
        ? chain.reduce((previousValue, currentValue) => previousValue?.[currentValue as keyof object], hasError) as string
        : '';

    useEffect(() => {
        const setFieldValueDebounced = debounce(setFieldValue, 100);

        setFieldValueDebounced(field, valueLocal);
    }, [field, valueLocal, setFieldValue]);

     return {
        field,
        touched,
        helperText,
        valueLocal,
        setFieldValueLocal,
        handleBlur,
    };
};
