import { IHeaderApi } from 'api/landing/header';
import { RequestResult } from 'shared/utils/requestResult';
import { ValuesStart } from 'models/Values';

export interface IHeaderService {
    hideHeader: (siteId: string, locale: string, isHidden: boolean) => Promise<RequestResult<ValuesStart>>;
    getHeader: (siteId: string, locale: string) => Promise<RequestResult<ValuesStart>>;
    editHeader: (siteId: string, locale: string, headerValues: ValuesStart) => Promise<RequestResult<ValuesStart>>;
}

export class HeaderService implements IHeaderService {
    constructor (
        private readonly headerApi: IHeaderApi,
        private readonly logger: Logger,
    ) { }

    public async hideHeader(siteId: string, locale: string, isHidden: boolean): Promise<RequestResult<ValuesStart>> {
        try {
            const response = await this.headerApi.hideHeader(siteId, locale, isHidden);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to hide header Section');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to hide header');
        }
    }

    public async getHeader(siteId: string, locale: string): Promise<RequestResult<ValuesStart>> {
        try {
            const response = await this.headerApi.getHeader(siteId, locale);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get header');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get header');
        }
    }

    public async editHeader(siteId: string, locale: string, headerValues: ValuesStart): Promise<RequestResult<ValuesStart>> {
        try {
            const response = await this.headerApi.editHeader(siteId, locale, headerValues);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to edit header');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to edit header');
        }
    }
}
