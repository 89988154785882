import { useState, useEffect, useRef, useMemo, MutableRefObject } from 'react';
import cn from 'classnames';

import { useViewport } from '../../hooks/useViewport';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

import './ImageLazyLoading.scss';

export interface ImageLazyLoadingProps {
    skeletonClassName?: string;
    containerClassName?: string;
    width?: number;
    height?: number;
    imgWidthMobile?: number;
    imgHeightMobile?: number;
    breakPoint?: number;
    isLoaded: boolean;
    children: JSX.Element;
}

export const ImageLazyLoading = ({
    skeletonClassName,
    containerClassName,
    width,
    height,
    imgWidthMobile,
    imgHeightMobile,
    breakPoint = 0,
    isLoaded,
    children,
}: ImageLazyLoadingProps) => {
    const { isMobile } = useViewport(breakPoint);

    const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;
    const wrapperRef = useRef(null);

    const [isVisible, setIsVisible] = useState(false);

    useIntersectionObserver({
        triggerRef: triggerRef,
        wrapperRef: wrapperRef,
        callback: (() => setIsVisible(true)),
        threshold: 0.01,
    });

    const aspectRatio = useMemo(() => {
        return isMobile && imgWidthMobile && imgHeightMobile
            ? (imgHeightMobile / imgWidthMobile) * 100
            : width && height
                ? (height / width) * 100
                : 0;
    }, [height, imgHeightMobile, imgWidthMobile, isMobile, width]);

    const styleContainer: React.CSSProperties = useMemo(() => (
        { paddingBottom: `${aspectRatio}%` }
    ), [aspectRatio]);

    const styleSkeleton: React.CSSProperties = useMemo(() => (
        { paddingBottom: `${aspectRatio}%`, visibility: isLoaded ? 'hidden' : 'visible' }
    ), [aspectRatio, isLoaded]);

    useEffect(() => {
        setTimeout(() => {
            if (isLoaded === false) {
                setIsVisible(true);
            }
        }, 2000);
    }, [isLoaded]);

    return (
        <div ref={triggerRef} className='picture'>
            <div
                className={cn('picture-container', containerClassName)}
                style={styleContainer}
            >
                {isVisible &&
                    <>{children}</>
                }
                <div
                    className={cn('picture__skeleton', { picture__skeleton_loading: !isLoaded }, skeletonClassName)}
                    style={styleSkeleton}
                />
            </div>
        </div>
    );
};
