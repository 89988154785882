import { createEffect, attach } from 'effector';

import { ILanguageService } from 'services/language';
import { $languageService } from 'store/services';
import { notify } from 'shared/utils/notification';
import { Language } from 'models/Language';

export const fetchAllLanguages = createEffect(async (params: {
    service: ILanguageService,
}) => {
    const { service } = params;

    const result = await service.getAllLanguages();

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return [];
        },
    );
});

export const wrappedFetchAllLanguages = attach({
    source: $languageService,
    mapParams: (_: void, service) => ({ service }),
    effect: fetchAllLanguages,
});

export const $areAllLanguagesLoading = wrappedFetchAllLanguages.pending;

export const fetchSiteLanguages = createEffect(async (params: {
    service: ILanguageService,
    siteId: string,
}) => {
    const { service, siteId } = params;

    const result = await service.getSiteLanguages(siteId);

    return result.match(
        data => data,
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return [];
        },
    );
});

export const wrappedFetchSiteLanguages = attach({
    source: $languageService,
    mapParams: (siteId: string, service) => ({ service, siteId }),
    effect: fetchSiteLanguages,
});

export const $areSiteLanguagesLoading = wrappedFetchSiteLanguages.pending;

const createLanguage = createEffect(async (params: {
    service: ILanguageService,
    siteId: string,
    localeId: string,
}) => {
    const { service, siteId, localeId } = params;

    const result = await service.createLanguage(siteId, localeId);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Language saved successfully.',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedCreateLanguage = attach({
    source: $languageService,
    mapParams: (
        { siteId, localeId }: { siteId: string, localeId: string },
        service,
    ) => ({ siteId, localeId, service }),
    effect: createLanguage,
});

const updateLanguages = createEffect(async (params: {
    service: ILanguageService,
    languages: Language[],
    siteId: number;
}) => {
    const { service, siteId, languages } = params;

    const result = await service.editLanguages(siteId, languages);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Language updated successfully.',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedUpdateLanguage = attach({
    source: $languageService,
    mapParams: ({ languages, siteId }, service) => ({ languages, siteId, service }),
    effect: updateLanguages,
});
