import { forward, sample } from 'effector';

import { $params } from 'store/params';

import { $errorPublicationsSection, $publicationsSection } from './store';
import { wrappedFetchPublicationsSection, wrappedUpdatePublications, wrappedHidePublications } from './effects';
import { getPublicationsSection, setPublicationsError, updatePublicationsSection, hidePublicationsSection } from './events';

sample({
    clock: hidePublicationsSection,
    source: $params,
    fn: (params, isHidden) => ({ ...params, isHidden }),
    target: wrappedHidePublications,
});

forward({
    from: wrappedHidePublications.doneData,
    to: $publicationsSection,
});

forward({
    from: getPublicationsSection,
    to: wrappedFetchPublicationsSection,
});

forward({
    from: wrappedFetchPublicationsSection.doneData,
    to: $publicationsSection,
});

sample({
    clock: updatePublicationsSection,
    source: $params,
    fn: (params, values) => ({ ...params, values }),
    target: wrappedUpdatePublications,
});

forward({
    from: wrappedUpdatePublications.doneData,
    to: $publicationsSection,
});

forward({
    from: setPublicationsError,
    to: $errorPublicationsSection,
});
