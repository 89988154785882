import { RequestResult } from 'shared/utils/requestResult';
import { IProjectApi } from 'api/project';
import { Project, ProjectHealthStatuses, ProjectWaves } from 'models/Project';

export interface IProjectService {
    getProjects: (startDate: string, endDate: string) => Promise<RequestResult<Project[]>>;
    getHealthStatuses: (startDate: string, endDate: string) => Promise<RequestResult<ProjectHealthStatuses[]>>;
    getWavesHealthCheck: (startDate: string, endDate: string, showAllFeatures: boolean) => Promise<RequestResult<ProjectWaves[]>>;
}

export class ProjectService implements IProjectService {
    constructor(
        private readonly projectApi: IProjectApi,
        private readonly logger: Logger,
    ) { }

    public async getProjects(startDate: string, endDate: string): Promise<RequestResult<Project[]>> {
        try {
            const response = await this.projectApi.getProjects(startDate, endDate);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get projects');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get projects');
        }
    }

    public async getHealthStatuses(startDate: string, endDate: string): Promise<RequestResult<ProjectHealthStatuses[]>> {
        try {
            const response = await this.projectApi.getHealthStatuses(startDate, endDate);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get health statuses');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to health statuses');
        }
    }

    public async getWavesHealthCheck(startDate: string, endDate: string, showAllFeatures: boolean): Promise<RequestResult<ProjectWaves[]>> {
        try {
            const response = await this.projectApi.getWavesHealthCheck(startDate, endDate, showAllFeatures);
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to get waves');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to get waves');
        }
    }
}
