import { PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

export function canvasPreview(
    sourceImage: HTMLImageElement,
    canvas: HTMLCanvasElement,
    cropConfig: PixelCrop,
    //scale = 1,
    rotate = 0,
) {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2d context');
    }

    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    // devicePixelRatio slightly increases sharpness on retina devices at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be true to the images natural size.
    const pixelRatio = window.devicePixelRatio;

    const cropFrameSize = 1.6;

    canvas.width = Math.floor(cropConfig.width * scaleX * pixelRatio + cropFrameSize);
    canvas.height = Math.floor(cropConfig.height * scaleY * pixelRatio + cropFrameSize);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = cropConfig.x * scaleX;
    const cropY = cropConfig.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = sourceImage.naturalWidth / 2;
    const centerY = sourceImage.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);     // move the crop origin to the canvas origin (0,0)
    ctx.translate(centerX, centerY);   // move the origin to the center of the original position
    ctx.rotate(rotateRads);            // rotate around the origin
    //ctx.scale(scale, scale);           // scale the image
    ctx.translate(-centerX, -centerY); // move the center of the image to the origin (0,0)
    ctx.drawImage(
        sourceImage,
        0,
        0,
        sourceImage.naturalWidth,
        sourceImage.naturalHeight,
        0,
        0,
        sourceImage.naturalWidth,
        sourceImage.naturalHeight,
    );

    ctx.restore();
}
