import { forward, sample } from 'effector';

import { $areParamsValid, $siteId } from 'store/params';
import { $localeSwitcher, setLocaleIdSwitcher } from 'store/locale';
import { LanguageShortName } from 'models/Language';

import {
    $siteLanguages,
    $languagesLookup,
    $siteLanguagesSuccess,
} from './store';
import {
    wrappedCreateLanguage,
    wrappedFetchAllLanguages,
    wrappedFetchSiteLanguages,
    wrappedUpdateLanguage,
} from './effects';
import {
    addLanguage,
    getAllLanguages,
    getSiteLanguages,
    saveLanguages,
} from './events';

forward({
    from: getAllLanguages,
    to: wrappedFetchAllLanguages,
});

forward({
    from: wrappedFetchAllLanguages.doneData,
    to: $languagesLookup,
});

forward({
    from: getSiteLanguages,
    to: wrappedFetchSiteLanguages,
});

forward({
    from: wrappedFetchSiteLanguages.doneData,
    to: $siteLanguages,
});

sample({
    clock: $siteLanguages,
    source: {
        localeSwitcher: $localeSwitcher,
        allLanguages: $languagesLookup,
    },
    filter: ({ localeSwitcher }, siteLanguages) => {
        return !!localeSwitcher && !siteLanguages
            .filter(language => language.order)
            .find(language => language.name === localeSwitcher);
    },
    fn: ({ allLanguages }, siteLanguages) => {
        const turnedOnLanguages = siteLanguages.filter(language => language.order);

        return turnedOnLanguages.length
            ? turnedOnLanguages[0].id.toString()
            : allLanguages.find(language => language.name === LanguageShortName.English)!.id.toString();
    },
    target: setLocaleIdSwitcher,
});

sample({
    clock: addLanguage,
    source: $siteId,
    fn: (siteId, { localeId }) => ({ siteId, localeId }),
    target: wrappedCreateLanguage,
});

sample({
    clock: $siteId,
    fn: () => false,
    target: $areParamsValid,
});

sample({
    clock: $siteId,
    target: getSiteLanguages,
});

sample({
    clock: $siteLanguages,
    filter: siteLanguages => !!siteLanguages.length,
    fn: () => true,
    target: $areParamsValid,
});

sample({
    clock: [wrappedCreateLanguage.doneData, wrappedUpdateLanguage.doneData],
    source: $siteId,
    target: getSiteLanguages,
});

sample({
    clock: saveLanguages,
    source: $siteId,
    fn: (siteId, { languages }) => ({ siteId, languages }),
    target: wrappedUpdateLanguage,
});

sample({
    clock: wrappedFetchSiteLanguages.doneData,
    fn: () => true,
    to: $siteLanguagesSuccess,
});

sample({
    clock: getSiteLanguages,
    fn: () => false,
    to: $siteLanguagesSuccess,
});
