import { TokenInfo, LogoutInfo } from 'models/Auth';
import { oktaLogout, logout } from 'store/auth';
import { AuthType } from 'models/Auth/TokenInfo';
import { OKTA_LOGOUT_URL } from 'api/constants';

import { INACTIVITY_THRESHOLD_MS } from './constants';
import { authLinks } from '../links';
import { throttle } from '../throttle/throttle';
import { isRoutePrivate } from '../links/isRoutePrivate';

const BASE_URL = window.location.origin + '/login';

export class StorageProvider {
    tokenKey = 'token';
    timerKey = 'timer';
    prevTime = '0';

    private timer?: ReturnType<typeof setTimeout>;

    constructor () {
        document.onload = this.resetTimer;
        document.onmousemove = this.throttledResetTimer;
        document.onmousedown = this.throttledResetTimer;
        document.ontouchstart = this.throttledResetTimer;
        document.onclick = this.throttledResetTimer;
        document.onkeydown = this.throttledResetTimer;
        document.addEventListener('scroll', this.throttledResetTimer, true);
        window.addEventListener('storage', this.throttledOnStorageChange);

        this.resetTimer();
    }

    public onStorageChange = (event: Event) => {
        const timerState = localStorage.getItem(this.timerKey) ?? '0';
        const tokenState = this.getToken();

        if (this.prevTime !== timerState) {
            this.resetTimer(event, true);
            this.prevTime = timerState;
        }

        if (!tokenState && isRoutePrivate()) {
            void this.onLogOut();
        }
    };

    private throttledOnStorageChange = throttle(this.onStorageChange, 2000);

    public saveAuthData(tokenInfo: TokenInfo) {
        this.setToken(tokenInfo);
    }

    public getToken(): TokenInfo | null {
        const tokenString = localStorage.getItem(this.tokenKey);

        if (tokenString) {
            const tokenInfo: TokenInfo = JSON.parse(tokenString);

            if (tokenInfo) {
                const msUTC = Date.parse(tokenInfo.ExpirationUtc);
                const msNow = Date.now();

                const isExpired = msUTC < msNow;

                return { ...tokenInfo, IsExpired: isExpired };
            }
        }

        return null;
    }

    private setToken(tokenInfo: TokenInfo | null): void {
        localStorage.setItem(this.tokenKey, JSON.stringify(tokenInfo));
    }

    private removeToken(): void {
        this.setToken(null);
    }

    private resetTimer = (_?: Event, reseted = false) => {
        if (isRoutePrivate()) {
            clearTimeout(this.timer);

            !reseted && localStorage.setItem(this.timerKey, `${Date.now()}`);

            this.timer = setTimeout(() => void this.onLogOut(), INACTIVITY_THRESHOLD_MS);
        }
    };

    private throttledResetTimer = throttle(this.resetTimer, 2000);

    onLogOut = async () => {
        const authInfo = this.getToken();

        if (authInfo?.SignInType === AuthType.Okta) {
            const logoutInfo: LogoutInfo | null = await oktaLogout(authInfo);
            const oktaIdToken = logoutInfo?.IdPIdToken;

            const FULL_OKTA_LOGOUT_URL = `${OKTA_LOGOUT_URL}?id_token_hint=${oktaIdToken}&post_logout_redirect_uri=${BASE_URL}`;

            window.location.href = FULL_OKTA_LOGOUT_URL;
        } else {
            void logout(authInfo);

            window.location.href = authLinks.signIn;
        }

        this.removeToken();
    };
}
