import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import cn from 'classnames';

import { adminNavigationLinks, PrivateRoute } from 'models/Module';
import { $siteLanguagesStatus } from 'store/language';
import { Tour } from 'features/Tour';

import './AdminMenu.scss';

export const AdminMenu = () => {
    const { siteLanguagesSuccess } = useStore($siteLanguagesStatus);

    const location = useLocation();
    const path = useMemo(() => location.pathname.split('/').slice(0, 3).join('/'), [location.pathname]);

    return (
        <ul className='admin-menu'>
            {siteLanguagesSuccess
                ? <li className='admin-menu__item'>
                    <Link
                        to={`/${PrivateRoute.Settings}`}
                    >
                        Start
                    </Link>
                </li>
                :  adminNavigationLinks.map((item, index) => (
                    <li key={index} className='admin-menu__item'>
                        <Link
                            to={`/${item.link}`}
                            className={cn({
                                'disabled-link' : item.nestedElements,
                                'focused-link': `/${item.link}`.includes(path),
                            })}
                        >
                            {item.displayName}
                        </Link>
                        {item.nestedElements &&
                            <ul className='admin-menu__submenu submenu'>
                                {item.nestedElements.map(element => (
                                    <li
                                        className='submenu__item'
                                        key={element.displayName}
                                    >
                                        <Link to={`/${element.link}`}>{element.displayName}</Link>
                                    </li>
                                ))}
                            </ul>
                        }
                    </li>
                ))
            }
            <Tour />
        </ul>
    );
};
