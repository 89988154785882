import { ILandingApi } from './interfaces';
import { LANDING_URL } from './constants';
import { getFilteredQuery } from '../utils';

export class LandingApi implements ILandingApi {
    constructor (
        private readonly fetch: Fetch,
    ) { }

    public async getLanding(siteId: string, locale: string) {
        const queryList = {
            siteId,
            locale,
        };

        const response = await this.fetch(`${LANDING_URL}${getFilteredQuery(queryList)}`);

        return response;
    }
}
