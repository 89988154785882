import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';

import { Loader } from 'shared/components';
import { $employeeStatus, getEmployee, resetEmployee } from 'store/employees';
import { getAllLanguages } from 'store/language';

import { Doctor } from '../../../../entities/Doctor/Doctor';

import './SingleProvider.scss';

export const SingleProvider = () => {
    const { employee, isEmployeeLoading } = useStore($employeeStatus);
    const { id } = useParams();

    useEffect(() => {
        getAllLanguages();
    }, []);

    useEffect(() => {
        if (id) {
            getEmployee(parseInt(id));
        } else {
            resetEmployee();
        }
    }, [id]);

    return (
        <div className='provider-container'>
            {employee
            ? <div className='doctor'>
                <Doctor doctor={employee} />
            </div>
            : isEmployeeLoading
                ? <Loader />
                : null
            }
        </div>
    );
};
