import * as Yup from 'yup';

import { ContactUsFormFields } from './ContactUsFormFields';

export const formValidationSchema = Yup.object().shape({
    [ContactUsFormFields.firstNameField]: Yup.string().trim().required('First name is required').max(180, 'First Name is too long'),
    [ContactUsFormFields.lastNameField]: Yup.string().trim().required('Last name is required').max(180, 'Last Name is too long'),
    [ContactUsFormFields.email]: Yup.string().trim().required('Email is required').max(180, 'Email is too long'),
    [ContactUsFormFields.description]: Yup.string().trim().max(5000, 'Text is too long'),
});
