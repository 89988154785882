import { Role } from 'models/Employee/Role';
import { ILookUpsApi } from 'api/lookUps';
import { RequestResult } from 'shared/utils/requestResult';

export interface IAdminLookupsService {
    getRoles: () => Promise<RequestResult<Role[]>>;
}

export class LookUpsService implements IAdminLookupsService {
    constructor (
        private readonly lookUpsApi: ILookUpsApi,
        private readonly logger: Logger,
    ) { }

    public async getRoles(): Promise<RequestResult<Role[]>> {
        try {
            const response = await this.lookUpsApi.getRoles();
            const parsedResponse = await response.json();

            return response.ok
                ? RequestResult.createSuccessful(parsedResponse)
                : RequestResult.createUnsuccessful('Failed to fetch roles');
        } catch (e) {
            this.logger.log((e as Error).message);

            return RequestResult.createUnsuccessful('Failed to load roles');
        }
    }
}
