export enum PrivateRoute {
    Employees = 'admin/employees',
    Landing = 'admin/required/landing',
    Physicians = 'admin/required/physicians',
    AdvancedProviders = 'admin/required/advanced-providers',
    AllDoctors = 'admin/required/all',
    AddOther = 'admin/other',
    AddMedia = 'admin/media',
    Other = 'admin/other/:id',
    Menu = 'admin/menu',
    Settings = 'admin/settings',
    ExternalLinks = 'admin/links',
}

export enum PublicRoute {
    Main = '',
    Physicians = 'physicians',
    AdvancedProviders = 'advanced-providers',
    AllDoctors = 'all-doctors',
    Other = 'other/:id',
}
