export const toolbarOptions = [
    ['bold', 'underline'],
    ['link'],
    //['blockquote'],  //TODO check this properties

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    //[{ indent: '-1' }, { indent: '+1' }],
    //[{ direction: 'rtl' }],

    [{ color: ['#000000', '#6B6A6A', '#F39100', '#FCC000'] }],
];
