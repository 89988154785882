import cn from 'classnames';

import { Image } from '../Image';
import { ReactComponent as EmptyPhoto } from '../Icons/empty-photo.svg';

import './EmployeePhoto.scss';

interface EmployeePhotoProps {
    photo?: string;
    className?: string;
    width?: number;
    height?: number;
}

export const EmployeePhoto = ({
    photo,
    className = '',
    width,
    height,
}: EmployeePhotoProps) => {
    return (
        <>
            {photo
                ? <div className='employee__photo-container'>
                    <Image
                        className={cn('employee__photo', className)}
                        image={photo}
                        width={width}
                        height={height}
                        alt='employee photo'
                    />
                </div>
                : <EmptyPhoto className='employee__photo-container' />
            }

        </>
    );
};
