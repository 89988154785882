import { createEffect, attach } from 'effector';

import { $params } from 'store/params';
import { notify } from 'shared/utils/notification';

import { Contact } from 'models/Contact/Contact';
import { IAdminContactService } from 'services/contact';
import { $contactService } from 'store/services';

const contactUs = createEffect(async (params: {
    service: IAdminContactService,
    contact: Contact,
    siteId: string,
    locale: string,
}) => {
    const { service, contact, siteId, locale } = params;

    const result = await service.contactUs(siteId, locale, contact);

    return result.match(
        data => {
            notify({
                type: 'info',
                message: 'Email sent successfully',
            });

            return data;
        },
        error => {
            notify({
                type: 'danger',
                message: error,
            });

            return null;
        },
    );
});

export const wrappedContactUs = attach({
    source: {
        service: $contactService,
        parameters: $params,
    },
    mapParams: (params: { contact: Contact }, states) => ({
        ...states.parameters,
        ...params,
        service: states.service,
    }),
    effect: contactUs,
});


export const $isDeletingEmployee = wrappedContactUs.pending;
